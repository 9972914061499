import React from "react";
import { GENERAL_ORANGE_HEX } from '../constants/texts';

function CustomDateFilterTabs({ tabs = [], selectedTab, setSelectedTab }) {

  const tabItems = ["All", "Today", "This Week", "This Month", "This Year"];

  const getActive = (tab) => {
    return selectedTab == tab;
  }



  return (
    <div className="flex p-1 items-start bg-white px-3 shadow-sm rounded-md gap-2">
      {tabs.map((tab) => {
        return <div onClick={() => setSelectedTab(tab)} className="cursor-pointer p-2 rounded-md" style={{ backgroundColor: getActive(tab) ? GENERAL_ORANGE_HEX : "", color: getActive(tab) ? "white" : "" }}>{tab}</div>;
      })}
    </div>
  );
}

export default CustomDateFilterTabs;
