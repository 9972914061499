import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { USER_QUERY_KEY } from '../constants/queryKeys';
import { update } from "../services/auth-service";

function useUpdateUser() {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: updateUser, isLoading, error, data } = useMutation({
        mutationFn: (updateData) => update(updateData),
        onSuccess: (data) => {
            queryClient.invalidateQueries([USER_QUERY_KEY])
            return data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        updateUser, isLoading, error, data
    }
}

export default useUpdateUser