import React from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

function CustomSortingArrows() {
  return (
    <span className="flex flex-col text-[18px]">
      {/* <span className="cursor-pointer -mb-[0.5em]">
        <RiArrowDropUpLine onClick={() => console.log("Increment")} />
      </span>
      <span className="cursor-pointer">
        <RiArrowDropDownLine onClick={() => console.log("decrement")} />
      </span> */}
    </span>
  );
}

export default CustomSortingArrows;
