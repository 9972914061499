import React from "react";
import InstallerTableHeaderList from "./InstallerTableHeaderList";
import InstallerRowItem from "./InstallerRowItem";
import CustomTable from "../admin_commons/CustomTable";

function InstallersListing({
  installers,
  onRowClick,
  isLoading = false,
  totalRecords = 0,
}) {
  return (
    <CustomTable
      onRowClick={onRowClick}
      totalRecords={totalRecords}
      isLoading={isLoading}
      HeaderComponent={InstallerTableHeaderList}
      data={installers}
      RowItemComponent={InstallerRowItem}
    />
  );
}

export default InstallersListing;
