import {
  QueryClient,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { addAdmin } from "../services/admin-users.services";
import { USERS_QUERY_KEY } from "../constants/queryKeys";

function useAddAdminUser() {
  const queryClient = useQueryClient();
  const {
    mutateAsync: addAdminUser,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (createData) => addAdmin(createData),
    onSuccess: () => {
      queryClient.invalidateQueries([USERS_QUERY_KEY]);
    },
    onError: () => {
      // return error
    },
  });
  return {
    addAdminUser,
    isLoading,
    error,
    data,
  };
}

export default useAddAdminUser;
