import React from 'react'
import BottomHeader from './BottomHeader'
import TopHeader from './TopHeader'

function Header() {
  return (
    <div className=''>
        <TopHeader />
    </div>
  )
}

export default Header