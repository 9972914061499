export const handleDownload = (response) => {
    console.log("response", response)
    // Get the file name from the 'content-disposition' header
    const contentDisposition = response.headers['content-disposition'];
    console.log("contentDisposition", contentDisposition)
    const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '').trim() // Remove quotes and trim any extra spaces
        : 'download.xlsx'; // Fallback if filename isn't found

    // Create a Blob from the response data
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create an object URL for the Blob
    const fileURL = URL.createObjectURL(blob);

    // Create an anchor element and trigger the download
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = fileName; // Use the filename from the header
    link.click(); // Trigger the download

    // Clean up the object URL after download
    URL.revokeObjectURL(fileURL);
}