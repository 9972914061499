import React from "react";
import { Link, useLocation } from "react-router-dom";
import { GENERAL_GRAY_HEX } from "../constants/texts";

function CustomSidebarLinkItem({ children, label, className = "", url, icon }) {
  const location = useLocation();
  const selected = location?.pathname?.includes(url);

  return (
    <Link to={url}>
      <div
        className={`${
          selected
            ? "bg-orange-400 text-white"
            : `text-[${GENERAL_GRAY_HEX}] hover:text-[#FF9D21]`
        } flex gap-3 mb-4 font-[400] text-[14px] items-center cursor-pointer ${className} p-2 rounded-lg`}
      >
        <div className="text-[1.2em]">{children}</div>
        <div className="flex items-center gap-1">
          <span>{label}</span>
          {icon && (
            <span className="flex items-center justify-center">{icon}</span>
          )}
        </div>
      </div>
    </Link>
  );
}

export default CustomSidebarLinkItem;
