import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../../commons/CustomDatePicker";
import CustomNavTabs from "../../commons/CustomNavTabs";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import SearchInput from "../../commons/SearchInput";
import useVendors from "./../../hooks/useVendors";
import VendorsListing from "./VendorsListing";
import useDebounce from "../../hooks/useDebounce";
import useUser from "../../hooks/useUser";

const tabItems = ["All", "Active", "Inactive"];

function Vendors() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);

  const [searchTerm, setSearchTerm] = useState("")
  const { user } = useUser();
  const debouncedSearchTerm = useDebounce(searchTerm)
  const { vendors, isLoading } = useVendors({ accessToken: user?.token, searchTerm: debouncedSearchTerm, status: selectedTab?.toLowerCase() });

  const handleRowClick = (id) => {
    navigate(`/vendors/${id}`);
  };

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        <PageTitle title={"Vendors"} />
        <PageBreadCrumb
          mainText="Dashboard"
          subText="Vendors List"
          otherText=""
          isActiveIndex={1}
        />
        <div className={`flex justify-between mt-6 text-[${GENERAL_GRAY_HEX}]`}>
          <div>
            <CustomNavTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabItems}
            />
          </div>
          <div className={`flex gap-4`}>
            <SearchInput
              className="bg-white shadow-sm py-3"
              onChange={setSearchTerm}
              value={searchTerm} 
              placeholder={"Search vendors..."}
            />
            {/* <CustomDatePicker /> */}
          </div>
        </div>

        <VendorsListing
          vendors={vendors}
          isLoading={isLoading}
          totalRecords={vendors?.totalRecords}
          onRowClick={handleRowClick}
        />
      </div>
    </Layout>
  );
}

export default Vendors;
