import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { INSTALLERS_QUERY_KEY } from "../constants/queryKeys";
import { getInstallers } from "../services/installers-service";

function useInstallers(data) {
  const navigate = useNavigate();
  const {
    data: installers,
    isLoading,
    error,
  } = useQuery({
    queryKey: [INSTALLERS_QUERY_KEY, data],
    queryFn: async () => {
      const result = await getInstallers(data);
      return result?.data?.data;
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    installers,
    isLoading,
    error,
  };
}

export default useInstallers;
