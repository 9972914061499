import { useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { CUSTOMER_QUERY_KEY } from '../constants/queryKeys'
import { decryptObject, decryptUser } from '../helpers/encryptor'
import { getSingleCustomer } from '../services/customers-service'

function useCustomer(details) {
    const { data: customer } = useQuery({
        queryKey: [CUSTOMER_QUERY_KEY],
        queryFn: async () => {
            const result = await getSingleCustomer(details);
            return result?.data?.data;
        },
        onError: (error) => {
            console.log("error when fetching single customer", error)
        },

    })


    return {
        customer: decryptObject(customer) ?? null
    }
}

export default useCustomer