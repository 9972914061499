import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { USER_QUERY_KEY } from '../constants/queryKeys';
import { updatePassword } from "../services/auth-service";

function useUpdateUserPassword() {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: updateUserPassword, isLoading, error, data } = useMutation({
        mutationFn: (updateData) => updatePassword(updateData),
        onSuccess: (data) => {
            queryClient.setQueryData([USER_QUERY_KEY], data?.data?.data)
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        updateUserPassword, isLoading, error, data
    }
}

export default useUpdateUserPassword