import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reviewVendor } from "../services/vendors-services";
import { MAKERS_QUERY_KEY } from "../constants/queryKeys";
import { reviewMaker } from "../services/makers-service";

function useReviewMaker() {
  const queryClient = useQueryClient();
  const {
    mutateAsync: makerReview,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (details) => reviewMaker(details),
    onSuccess: (data) => {
      queryClient.invalidateQueries([MAKERS_QUERY_KEY]);
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    makerReview,
    isLoading,
    error,
    data,
  };
}

export default useReviewMaker;
