import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { createPaymentSplit } from "../services/vendors-services";

function useAddPaymentSplit() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    mutateAsync: addPaymentSplit,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (createData) => createPaymentSplit(createData),
    onSuccess: (data) => {},
    onError: (error) => {
      // return error
    },
  });
  return {
    addPaymentSplit,
    isLoading,
    error,
    data,
  };
}

export default useAddPaymentSplit;
