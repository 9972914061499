import React from "react";
import { getRoleNameByKey } from "../../constants/roles";
import StyledSpan from "../../commons/StyledSpan";

function UserRowItem({ item, onClick = () => {} }) {
  return (
    <>
      <tr class="hover:bg-gray-100 cursor-pointer text-[11px]" onClick={onClick}>
        <td class="px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
          #{item?.id}000000
        </td>
        <td class="px-6 py-4 text-[12px] font-[400]">{item?.firstName}</td>
        <td class="px-6 py-4 text-[12px] font-[400]">{item?.lastName}</td>
        <td class="px-6 py-4 text-[12px] font-[400]">{item?.email}</td>
        <td class="px-6 py-4 text-[12px] font-[400]">{item?.phoneNumber}</td>
        <td class="px-6 py-4">{getRoleNameByKey(item?.role)}</td>
        <td class="px-6 py-4">
          <StyledSpan text={item?.status}></StyledSpan>
        </td>
      </tr>
    </>
  );
}

export default UserRowItem;
