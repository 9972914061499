import React from "react";
import { CiVoicemail } from "react-icons/ci";

function CustomIconWithRoundedBg({
  size = "38px",
  bg = "#EAECF0",
  className = "",
  children = <CiVoicemail className="text-[18px]" />,
}) {
  return (
    <div
      className={` rounded-full flex items-center justify-center ${className}`}
      style={{ backgroundColor: bg, minWidth: size, height: size }}
    >
      <span className="text-[16px]">{children}</span>
    </div>
  );
}

export default CustomIconWithRoundedBg;
