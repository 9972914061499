import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../../commons/CustomDatePicker";
import CustomNavTabs from "../../commons/CustomNavTabs";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import SearchInput from "../../commons/SearchInput";
import LogisticsPartnerRowItem from "./LogisticsPartnerRowItem";
import { logistics_partners_mock_data } from '../../mock_data/logistics_partners';
import GeneralModal from "../../commons/GeneralModal";
import NewLogisticsPartner from "./NewLogisticsPartner";
import CustomButton from "../../commons/CustomButton";
import PlusIcon from "../../commons/PlusIcon";
import LogisticsPartnersListing from "./LogisticsPartnersListing";
import useUser from "../../hooks/useUser";
import useLogisticsPartners from "../../hooks/useLogisticsPartners";
import useDebounce from "../../hooks/useDebounce";

const tabItems = ["All", "Active", "Inactive"];

function LogisticsPartners() {

  const navigate = useNavigate();
  const { user } = useUser()
  const [selectedPartner, setSelectedPartner] = useState(null)
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);
  const [searchTerm, setSearchTerm] = useState("")

  const debouncedSearchTerm = useDebounce(searchTerm)
  const { logisticsPartnersDetails } = useLogisticsPartners({ accessToken: user?.token, searchTerm: debouncedSearchTerm, status: selectedTab?.toLowerCase(), })

  console.log("logistics partners", logisticsPartnersDetails)

  const handleRowClick = (id) => {
    navigate("/logistics-partners/" + id)
  }

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        <PageTitle title={"Logistics Partners"} />
        <PageBreadCrumb
          mainText="Dashboard"
          subText="Logistics Partners"
          otherText=""
          isActiveIndex={1}
        />
        <div className={`flex justify-between mt-6 text-[${GENERAL_GRAY_HEX}]`}>
          <div>
            <CustomNavTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabItems} />
          </div>
          <div className={`flex gap-4`}>
            <SearchInput
              className="bg-white shadow-sm py-3"
              onChange={setSearchTerm}
              value={searchTerm}
              placeholder={"Search partners..."}
            />
            <CustomButton onClick={() => setSelectedPartner({})} label="Logistics Partner" className="bg-orange-400 text-white">
              <PlusIcon />
            </CustomButton>
          </div>
        </div>

        <LogisticsPartnersListing partners={logisticsPartnersDetails?.logisticsPartners} onRowClick={handleRowClick} totalRecords={logisticsPartnersDetails?.total} />

      </div>


      {/* Modal */}
      <GeneralModal widthClass="w-[55%]" showCloseButton isOpen={selectedPartner} onClose={() => setSelectedPartner(null)}>
        <div className="h-[80vh] text-left pr-3" style={{ overflowY: "auto" }}>
          <NewLogisticsPartner onClose={() => setSelectedPartner(null)} user={user} />
        </div>
      </GeneralModal>
    </Layout>
  );
}

export default LogisticsPartners;
