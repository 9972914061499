export const DECOR_TIPS_QUERY_KEY = "decor-tips";
export const USER_QUERY_KEY = "user";
export const ADMIN_USER_QUERY_KEY = "admin-user";
export const PAYMENT_SPLIT_QUERY_KEY = "payment-split";
export const ORDER_QUERY_KEY = "order";
export const RETURNED_ORDER_QUERY_KEY = "returned-order";
export const TICKET_QUERY_KEY = "ticket";
export const USERS_QUERY_KEY = "users";
export const PAYOUTS_QUERY_KEY = "payouts";
export const VENDOR_PAYOUTS_QUERY_KEY = "vendor-payouts";
export const PAYOUT_QUERY_KEY = "single-payout";
export const CUSTOMERS_QUERY_KEY = "customers";
export const CUSTOMER_QUERY_KEY = "customer";
export const PRODUCT_CATEGORIES_QUERY_KEY = "product-categories";
export const PRODUCT_CATEGORY_QUERY_KEY = "product-category";
export const PRODUCTS_QUERY_KEY = "products";
export const COLORS_QUERY_KEY = "colors";
export const BANKS_QUERY_KEY = "banks";
export const DASHBOARD_QUERY_KEY = "dashboard";
export const STATES_QUERY_KEY = "states";
export const COUNTRIES_QUERY_KEY = "countries";
export const SUPPORT_TICKETS_QUERY_KEY = "support-tickets";
export const TOWNS_QUERY_KEY = "towns";
export const MY_WUDIFY_QUERY_KEY = "mywudifys";
export const VENDORS_QUERY_KEY = "vendors";
export const TYPES_QUERY_KEY = "types";
export const SEARCHED_PRODUCTS_QUERY_KEY = "searched-products";
export const FILTERED_PRODUCTS_QUERY_KEY = "filtered-products";
export const PRODUCT_QUERY_KEY = "product";
export const TYPES_BY_SUBCAT_SLUG_QUERY_KEY = "types-subcat-slug";
export const PRODUCT_SUB_CATEGORIES_QUERY_KEY = "product-subcategories";
export const PRODUCT_SUB_CATEGORY_QUERY_KEY = "product-subcategory";
export const PRODUCT_TYPE_QUERY_KEY = "product-type";
export const ADDRESS_QUERY_KEY = "address";
export const CART_ITEM_QUERY_KEY = "cart-items";
export const ORDER_ITEMS_QUERY_KEY = "order-items";
export const CUSTOMER_ORDER_ITEMS_QUERY_KEY = "customer-order-items";
export const RETURNED_ORDER_ITEMS_QUERY_KEY = "returned-order-items";
export const VENDOR_ITEMS_QUERY_KEY = "vendor-order-items";
export const PENDING_REVIEWS_QUERY_KEY = "pending-reviews";
export const SUBSCRIPTION_ITEM_QUERY_KEY = "subscription";
export const BUY_NOW_ITEM_QUERY_KEY = "buy-now";
export const WISH_LIST_QUERY_KEY = "wish-list";
export const SELECTED_ADDRESS_QUERY_KEY = "selected-address";
export const SINGLE_TICKET = "single-ticket";
export const SINGLE_PAYOUT = "single-payout";
export const SINGLE_VENDOR = "single-vendor";
export const FURNITURE_GUIDES_QUERY_KEY = "furniture-guides";
export const FURNITURE_GUIDE_QUERY_KEY = "furniture-guide";
export const MAKERS_QUERY_KEY = "makers";
export const INSTALLERS_QUERY_KEY = "installers";
export const LOGISTICS_PARTNERS_QUERY_KEY = "logistics-partners";
export const SINGLE_LOGISTICS_PARTNER_QUERY_KEY = "single-logistics-partner";
