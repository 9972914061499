import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import SearchInput from "../../commons/SearchInput";
import ReturnedOrdersListing from "./ReturnedOrdersListing";
import useDatePicker from "../../hooks/useDatePicker";
import { GENERAL_BLUE_HEX } from "../../constants/texts";
import useUser from "../../hooks/useUser";
import useReturnedOrders from "../../hooks/useReturnedOrders";
import useDebounce from "../../hooks/useDebounce";

const tabItems = ["All", "Pending", "Picked", "Shipped", "Delivered"];

function ReturnedOrders() {
  const navigate = useNavigate();

  const { user } = useUser();
  const [searchTerm, setSearchTerm] = useState("")

  const debouncedSearchTerm = useDebounce(searchTerm)

  const [selectedTab, setSelectedTab] = useState(tabItems[0]);
  const { DatePickerComponent, startDate, endDate, DateDisplay } =
    useDatePicker(null, "YYYY-MM-DD");
  const { returnedOrders, isLoading } = useReturnedOrders(
    user,
    10,
    1,
    selectedTab,
    [startDate, endDate],
    debouncedSearchTerm
  );

  const extractAllOrderDetails = (data) => {
    if (!data) {
      return [];
    }
    return data?.map((d) => {
      return { ...d, ...d?.order, status: d?.status, Product: d?.order?.Product, User: d?.order?.User, order: d?.order, id: d?.id };
    });
  };

  const handleRowClick = (id) => {
    navigate(`/returned-orders/${id}`);
  };

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        <PageTitle title={"Returned Orders"} />
        <PageBreadCrumb
          mainText="Dashboard"
          subText="Returned Order List"
          otherText=""
          isActiveIndex={1}
        />
        <div className={`flex justify-between mt-6 text-[${GENERAL_GRAY_HEX}]`}>
          <div>
            {/* <CustomNavTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={tabItems} /> */}
          </div>
          <div className={`flex gap-4`}>
            <SearchInput
              className="bg-white shadow-sm"
              onChange={setSearchTerm}
              value={searchTerm}
              placeholder={"Search returned orders..."}
            />
            {DatePickerComponent}
          </div>
        </div>

        {DateDisplay}

        <ReturnedOrdersListing
          onRowClick={handleRowClick}
          totalRecords={returnedOrders?.totalRecords || 10}
          isLoading={isLoading}
          orders={extractAllOrderDetails(returnedOrders?.data)}
        />
      </div>
    </Layout>
  );
}

export default ReturnedOrders;
