import React from "react";
import CustomPriceDisplay from "../../commons/CustomPriceDisplay";
import StyledSpan from "../../commons/StyledSpan";
import { getDateTime } from "../../helpers/dateTime";

function PayoutRowItem({ item, user, onClick = () => {} }) {
  return (
    <>
      <tr class="hover:bg-gray-100 cursor-pointer" onClick={onClick}>
        <td class="px-6 py-4 text-[14px] font-[400]">#{item?.id}</td>
        <td class="px-6 py-4 text-[14px] font-[400]">
          {getDateTime(item?.createdAt, "DD-MM-YYYY HH:mm A")}
        </td>
        <td class="px-6 py-4 text-[14px] font-[400]">
          {item?.vendor?.businessName}
        </td>
        <td class="px-6 py-4 text-[14px] font-[400]">
          <CustomPriceDisplay price={item?.amount} />
        </td>
        <td class="px-6 py-4 flex gap-3">
          <StyledSpan text={item?.status}></StyledSpan>
        </td>
      </tr>
    </>
  );
}

export default PayoutRowItem;
