import { useMutation, useQueryClient } from "@tanstack/react-query";
import { USER_QUERY_KEY } from "../constants/queryKeys";
import { login } from "../services/auth-service";
import { encryptData } from "./../helpers/encryptor";
import * as userLocalStore from "../local_store/userLocalStore";
import { useNavigate } from "react-router-dom";

function useLogin() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    mutateAsync: logAdminIn,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (loginData) => login(loginData),
    onSuccess: (data) => {
      const dataToken = data?.data?.token;
      const others = data?.data?.details;
      const userData = { token: dataToken, ...others };
      userLocalStore.saveUser(encryptData(userData));
      queryClient.setQueryData([USER_QUERY_KEY], encryptData(userData));
      navigate("/dashboard");
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    logAdminIn,
    isLoading,
    error,
    data,
  };
}

export default useLogin;
