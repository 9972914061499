import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { PAYMENT_SPLIT_QUERY_KEY } from "../constants/queryKeys";
import { getVendorPaymentSplit } from "../services/vendors-services";

function useVendorPaymentSplit({ vendorId, token }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    data: paymentSplit,
    isLoading,
    error,
  } = useQuery({
    queryKey: [PAYMENT_SPLIT_QUERY_KEY, vendorId],
    queryFn: async () => {
      const result = await getVendorPaymentSplit({
        id: vendorId,
        accessToken: token,
      });
      return result?.data;
    },
    onError: (error) => {
      // return error
    },
    enabled: vendorId != null,
  });
  return {
    paymentSplit,
    isLoading,
    error,
  };
}

export default useVendorPaymentSplit;
