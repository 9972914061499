export const USER_LOCAL_STORAGE_KEY = "user";

export const getUser = () => {
  const user = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
  return user ? JSON.parse(user) : undefined;
};

export const saveUser = (user) => {
  if (user) {
    localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
  }
};

export const removeUser = () => {
  localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
};
