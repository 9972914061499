import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { RETURNED_ORDER_QUERY_KEY } from "../constants/queryKeys";
import { fetchReturnedOrderById } from "../services/returned-order-services";

function useReturnedOrder(user, id) {
  const navigate = useNavigate();
  const {
    data: returnedOrder,
    isLoading,
    error,
  } = useQuery({
    queryKey: [RETURNED_ORDER_QUERY_KEY, id],
    queryFn: async () => {
      const result = await fetchReturnedOrderById(user, id);
      return result?.data?.data;
    },
    enabled: id != null,
    onError: (error) => {
      // return error
    },
  });
  return {
    returnedOrder,
    isLoading,
    error,
  };
}

export default useReturnedOrder;
