import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import CustomButton from "../../commons/CustomButton";
import CardTitle from "../admin_commons/CardTitle";
import { GENERAL_ORANGE_HEX } from "./../../constants/texts";
import PlusIcon from "../../commons/PlusIcon";
import AccountInfo from './../admin_commons/AccountInfo';
import GeneralModal from './../../commons/GeneralModal';
import NewServiceLocation from "./NewServiceLocation";
import useSingleLogisticsPartner from "../../hooks/useSingleLogisticsPartner";
import useUser from "../../hooks/useUser";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";
import RoundedMdButton from "../../commons/RoundedMdButton";
import { Form, Formik } from "formik";
import ConfirmationModal from "../../commons/modals/ConfirmationModal";
import CustomTextField from "../../commons/CustomTextField";
import useReviewLogisticsPartner from "../../hooks/useReviewLogisticsPartner";
import { delayAction } from "../../helpers/events";
import CustomBackdrop from "../../commons/CustomBackdrop";

function LogisticsPartnerDetails() {
  const navigate = useNavigate();
  const { user } = useUser()
  const params = useParams();
  const id = params.slug;

  const { logisticsPartner } = useSingleLogisticsPartner(user, id)
  const { logisticsPartnerReview } = useReviewLogisticsPartner()

  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();


  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showReasonInput, setShowReasonInput] = useState(false);
  const [comment, setComment] = useState("");

  console.log("single logisitics partner", logisticsPartner)
  const [showServiceLocationModal, setShowServiceLocationModal] = useState(null)


  const handleReview = (approveOrReject, data) => {
    let action = approveOrReject;

    if (action == false && !data?.comment) {
      setInfoDetails({
        message: "Please enter reason for deactivation",
        isError: true,
      });
      setIsSubmitting(false);
      return;
    }

    //continue with submission
    setIsSubmitting(true);

    logisticsPartnerReview({ ...data, action, accessToken: user?.token })
      .then((res) => {
        setInfoDetails({
          message: res?.data?.message,
          isError: false,
        });
        // delayAction(callback, 2000)
      })
      .catch((err) => {
        console.log("error from reviewing logistics partner", err);
        setInfoDetails({ message: err?.message, isError: true });
      })
      .finally(() => {
        setIsSubmitting(false);
        setOpenReviewModal(false)
        setShowReasonInput(false)
        setComment("")
      });
  };

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        {/* First section */}
        <PageTitle title={"Logistics Partner Details"} />
        <div className="flex justify-between">
          <PageBreadCrumb
            mainText="Dashboard"
            subText="Logistics Partners List"
            otherText="Logistics Partner Details"
            isActiveIndex={2}
          />

          <div className="flex gap-4">
            <CustomButton
              label="Back"
              onClick={() => navigate(-1)}
              className={`border border-[${GENERAL_GRAY_HEX}] text-[${GENERAL_GRAY_HEX}]`}
            />
            <CustomButton
              label="Review"
              onClick={() => setOpenReviewModal(true)}
              className={`border border-orange-400 text-[${GENERAL_ORANGE_HEX}]`}
            />
            {/* <CustomButton onClick={() => setShowServiceLocationModal({})} label="Service Location" className="bg-orange-400 text-white">
              <PlusIcon />
            </CustomButton> */}
          </div>
        </div>

        {/* Second section */}
        <div className="flex mt-6 gap-5">
          {/* First Card */}

          <div className="shadow-sm rounded-md bg-white w-[35%] p-5">
            <CardTitle title={"Contact Details"} />
            <div className="my-4"></div>
            <AccountInfo status={logisticsPartner?.status} name={logisticsPartner?.accountManager} phoneNumber={logisticsPartner?.phoneNumber} topText={logisticsPartner?.name} email={logisticsPartner?.email} ownerLabel="Account Manager" />
          </div>

          {/* Second Card */}
          <div className="shadow-sm rounded-md bg-white w-[65%]">

            <div className="">
              <div class="relative overflow-x-auto mb-8">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="font-[400] text-[13px] ">
                    <tr className="bg-gray-100">
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Service Locations</span>
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Service Rate</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="">
                      <td scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>#1234</span>
                        </div>
                      </td>
                      <td scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Royal Chair</span>
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>#1234</span>
                        </div>
                      </td>
                      <td scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Royal Chair</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>


      {InfoDisplayComponent}

      {isSubmitting && <CustomBackdrop open={isSubmitting} text="Please wait..." />}

      {/* Modal */}
      <GeneralModal widthClass="w-[55%]" showCloseButton isOpen={showServiceLocationModal} onClose={() => setShowServiceLocationModal(null)}>
        <div className="text-left pr-3" style={{ overflowY: "auto" }}>
          <NewServiceLocation onClose={() => setShowServiceLocationModal(null)} />
        </div>
      </GeneralModal>



      {/* For Review */}
      <ConfirmationModal
        title={"Review Logistics Partner"}
        description={"You can activate or deactivate a logistics partner"}
        subText={
          "Activate a logistics partner if all details are correct. Otherwise, deactivate the logistics partner and provide some reason."
        }
        isOpen={openReviewModal}
        onClose={() => {
          setOpenReviewModal(false);
        }}
      >
        {showReasonInput && (
          <>
            <Formik
              initialValues={{
                comment: "",
              }}
              validate={(values) => {
                const errors = {};
                setComment(values.comment);
                if (!values.comment) {
                  errors.comment = "Please enter reason(s) for rejection";
                }
                return errors;
              }}
            >
              <Form className="mt-6">
                <CustomTextField
                  name={"comment"}
                  label={"Deactivation reason"}
                  placeholder="Please enter reason for deactivating"
                  required
                />
              </Form>
            </Formik>
          </>
        )}
        {!showReasonInput && (
          <div className="my-5 flex justify-between gap-6">
            <RoundedMdButton
              label={"Deactivate"}
              onClick={() => {
                setShowReasonInput(true);
              }}
              className="bg-gray-200"
            />
            <RoundedMdButton
              label={"Activate"}
              onClick={() => {
                handleReview(true, { id: logisticsPartner?.id });
              }}
              className="bg-orange-400 text-white"
            />
          </div>
        )}
        {showReasonInput && (
          <div className="my-5 flex justify-between gap-6">
            <RoundedMdButton
              label={"Cancel"}
              onClick={() => {
                setShowReasonInput(false);
              }}
              className="bg-gray-200"
            />
            <RoundedMdButton
              label={"Deactivate"}
              onClick={() => {
                handleReview(false, { id: logisticsPartner?.id, comment });
              }}
              className="bg-orange-400 text-white"
            />
          </div>
        )}
      </ConfirmationModal>

    </Layout>
  );
}

export default LogisticsPartnerDetails;
