import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { SINGLE_VENDOR } from "../constants/queryKeys";
import { getVendor } from "../services/vendors-services";
import { decryptObject, encryptData } from "../helpers/encryptor";

function useSingleVendor({ vendorId, token, otherDetails }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    data: singleVendor,
    isLoading,
    error,
  } = useQuery({
    queryKey: [SINGLE_VENDOR, vendorId, otherDetails],
    queryFn: async () => {
      const result = await getVendor({
        id: encryptData(vendorId),
        accessToken: token,
        otherDetails
      });
      return result?.data?.data;
    },
    onError: (error) => {
      // return error
    },
    enabled: vendorId != null,
  });
  return {
    singleVendor: decryptObject(singleVendor),
    isLoading,
    error,
  };
}

export default useSingleVendor;
