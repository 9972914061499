import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { USER_QUERY_KEY } from "../constants/queryKeys";
import { getUser } from "../services/auth-service";
import * as userLocalStore from "../local_store/userLocalStore";
import {
  decryptData,
  decryptUser,
  encryptData,
  isValid,
} from "../helpers/encryptor";

function useUser() {
  let initialUser = userLocalStore.getUser();
  const { data: user } = useQuery({
    queryKey: [USER_QUERY_KEY],
    queryFn: async () => {
      if (initialUser) {
        initialUser = decryptData(initialUser);
      } else {
        return null
      }
      const result = await getUser(initialUser);
      const dataToken = result?.data?.token;
      const others = result?.data?.details;
      const userData = { token: dataToken, ...others };
      return encryptData(userData);
    },
    onError: (error) => {
      console.log("error when fetching user", error);
    },
    initialData: userLocalStore.getUser(),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  useEffect(() => {
    if (!user) {
      userLocalStore.removeUser();
    } else {
      userLocalStore.saveUser(user);
    }
  }, [user]);

  const decryptedUser = decryptData(user);

  return {
    user: isValid(decryptedUser) ? decryptedUser : null,
  };
}

export default useUser;
