import { isValid } from "../helpers/encryptor";

export const ADMIN_ROLES = [
  {
    id: 1,
    name: "Super Admin",
    key: "SuperAdmin",
  },
  {
    id: 2,
    name: "Admin",
    key: "Admin",
  },
  {
    id: 3,
    name: "Customer Support Representative",
    key: "CSR",
  },
];

export const getRoleNameByKey = (key) => {
  try {
    return ADMIN_ROLES.find((role) => role.key == key)?.name;
  } catch (err) {
    return "N/A";
  }
};

export const isSuperAdmin = (user) => {
  if (isValid(user)) {
    if (user?.role == ADMIN_ROLES[0].key) {
      return true;
    }
  }

  return false;
};

export const isAdmin = (user) => {
  if (isValid(user)) {
    if (user?.role == ADMIN_ROLES[1].key) {
      return true;
    }
  }

  return false;
};

export const isCSR = (user) => {
  if (isValid(user)) {
    if (user?.role == ADMIN_ROLES[2].key) {
      return true;
    }
  }

  return false;
};
