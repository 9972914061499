import React from "react";
import { appliedClasses, getColorStatus } from "../helpers/extractors";
import { capitalizeFirstLetter } from "../helpers/strings";

function StyledSpan({ text, className }) {

  const classIndex = getColorStatus(text)

  return (
    <span
      className={`${className} ${appliedClasses(classIndex)} flex items-center justify-between gap-1 py-1.5 px-2.5 rounded-lg`}
    >
      <div className="flex items-center gap-2">
        <span>{capitalizeFirstLetter(text)}</span>
      </div>

    </span>
  );
}

export default StyledSpan;
