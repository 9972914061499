import * as userLocalStore from "../local_store/userLocalStore";
import { decryptData } from "./encryptor";

export const delayAction = (action, delay = 3000) => {
  setTimeout(() => {
    action();
  }, delay);
};

export const getLoggedinUser = () => {
  let initialUser = userLocalStore.getUser();
  if (initialUser) {
    initialUser = decryptData(initialUser);
  }

  return initialUser;
};
