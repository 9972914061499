import React from "react";
import CustomProgressArc from "./CustomProgressArc";
import { VscArrowSmallUp } from "react-icons/vsc";
import { GENERAL_GRAY_HEX } from "../constants/texts";

function CustomCardWithProgressArc({
  title,
  description,
  infoText1,
  infoText2,
  infoValue1,
  infoValue2,
  arcValue,
  arcColor,
}) {
  return (
    <div className="bg-white shadow-sm p-4 w-full text-left rounded-md">
      <div className="font-[500] text-[14px]">{title}</div>
      <div className="text-center h-[10em]">
        <CustomProgressArc value={arcValue} color={arcColor} />
      </div>
      <div className={`text-center text-[${GENERAL_GRAY_HEX}] my-4`}>{description}</div>
      <div className="flex text-center justify-center gap-4">
        <div className="flex flex-col">
          <span>{infoText1}</span>
          <span className="font-[500] text-[14px] flex items-center gap justify-center">
            {infoValue1}
            <span>
              <VscArrowSmallUp />
            </span>
          </span>
        </div>
        <div className="flex flex-col">
          <span>{infoText2}</span>
          <span className="font-[500] text-[14px] flex items-center gap justify-center">
            {infoValue2}
            <span>
              <VscArrowSmallUp />
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default CustomCardWithProgressArc;
