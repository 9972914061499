import React from "react";
import StyledSpan from "../../commons/StyledSpan";

function MakerRowItem({ item, user, onClick = () => {} }) {
  return (
    <>
      <tr class="hover:bg-gray-100 cursor-pointer" onClick={onClick}>
        <td class="px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
          #{item?.id}
        </td>
        <td class="px-6 py-4 text-[12px] font-[400]">{item?.firstName}</td>
        <td class="px-6 py-4 text-[12px] font-[400]">{item?.lastName}</td>
        <td class="px-6 py-4 text-[12px] font-[400]">{item?.email}</td>
        <td class="px-6 py-4 text-[12px] font-[400]">{item?.phoneNumber}</td>
        <td class="px-6 py-4 text-[12px] font-[400]">{item?.address}</td>
        <td class="px-6 py-4 text-[12px] font-[400]">{item?.comment}</td>
        <td class="px-6 py-4 flex gap-3">
          <StyledSpan text={item?.status}></StyledSpan>
        </td>
        {/* <td class="px-6 py-4">
          <div className="flex gap-3">
            <div className="text-[#318EC9] cursor-pointer">View</div>
            <div className="text-[#008000] cursor-pointer">Edit</div>
          </div>
        </td> */}
      </tr>
    </>
  );
}

export default MakerRowItem;
