import { addPaginationUrlQuery } from "../helpers/extractors";
import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const fetchAllReturnedOrders = async (data, pageSize, pageNumber, filter, dateRange, searchTerm) => {
  let url = "/return-requests";

  url = addPaginationUrlQuery(url, pageSize, pageNumber);
  return axiosInstance(`${url}&searchTerm=${searchTerm}&status=${filter}&startDate=${dateRange[0]}&endDate=${dateRange[1]}`, "GET", data, data.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const updateReturnedOrderStatus = async (data) => {
  let url = `/return-requests/${data.id}/status`;

  return axiosInstance(url, "PATCH", data, data.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const fetchReturnedOrderById = async (data, id) => {
  return axiosInstance(`/return-requests/${id}`, "GET", null, data?.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};
