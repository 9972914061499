import { Form, Formik } from "formik";
import React from "react";
import CardTitle from "../commons/CardTitle";
import CustomInputField from "../commons/CustomInputField";
import CustomSelectField from "../commons/CustomSelectField";
import CustomTextField from "../commons/CustomTextField";
import FullRoundedButton from "../commons/FullRoundedButton";
import GeneralModal from "../commons/GeneralModal";

function useWithdrawalForm({ showForm, setShowForm }) {
  return (
    <GeneralModal
      isOpen={showForm}
      widthClass={"w-[70%]"}
      showCloseButton={true}
      onClose={() => setShowForm(false)}
    >
      <CardTitle title="Withdrawal Form" className="text-left mb-4" />

      <p className="text-[#98A2B3] text-[16px] font-[500] text-left">
        Kindly complete the form below
      </p>
      {/* Personal info */}
      <div className="">
        <div className="text-left">
          <p className="text-[18px] font-[500] mt-3">Personal Info</p>
        </div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            amount: "",
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {}}
          validate={(values) => {
            const errors = {};
            if (!values.firstName) {
              errors.firstName = "Required";
            }

            return errors;
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="flex justify-between gap-4 mt-2">
                <div className="w-full">
                  <CustomInputField
                    label="First Name"
                    type="text"
                    name="firstName"
                    placeholder="Please enter your first name"
                  />
                </div>
                <div className="w-full">
                  <CustomInputField
                    label="Last Name"
                    type="text"
                    name="lastName"
                    placeholder="Please enter your last name"
                  />
                </div>
                <div className="w-full">
                  <CustomInputField
                    label="Amount"
                    type="number"
                    name="amount"
                    placeholder="Please enter amount to withdraw"
                  />
                </div>
              </div>

              <div className="text-left">
                <p className="text-[18px] font-[500] mt-3">Bank Details</p>
              </div>

              <div className="flex justify-between gap-4 mt-2">
                <div className="w-full">
                  <CustomInputField
                    label="Account Name"
                    type="text"
                    name="accountName"
                    placeholder="Please enter your last name"
                  />
                </div>
                <div className="w-full">
                  <CustomInputField
                    label="Account Number"
                    type="text"
                    name="accountNumber"
                    placeholder="Please enter account number"
                  />
                </div>
                <div className="w-full">
                  <CustomSelectField
                    list={[]}
                    label="Bank"
                    type="text"
                    name="bankName"
                    placeholder="Please select your bank"
                  />
                </div>
              </div>
              <div className="text-right flex justify-end gap-3">
                <FullRoundedButton
                  label="Submit"
                  type="submit"
                  className="bg-[#FF9D21] text-white"
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </GeneralModal>
  );
}

export default useWithdrawalForm;
