import React from "react";
import CardTitle from "../admin_commons/CardTitle";
import { Form, Formik } from "formik";
import CustomInputField from "../../commons/CustomInputField";
import RoundedMdButton from "../../commons/RoundedMdButton";

function VendorPaymentStructure({ vendor, onAdd, paymentSplit, onUpdate }) {
  return (
    <div>
      <CardTitle title="Payment Structure" />
      <div className="mb-6"></div>
      <Formik
        enableReinitialize
        initialValues={{
          guid: paymentSplit?.guid,
          commission: paymentSplit?.commission ?? "",
          initial: paymentSplit?.initial ?? "",
          outstanding: paymentSplit?.outstanding ?? "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          onAdd({
            vendorId: vendor?.id,
            ...values,
            initial: parseInt(values.initial),
            commission: parseInt(values.commission),
            outstanding: parseInt(values.outstanding),
          });
        }}
        validate={(values) => {
          const errors = {};
          let remaining = 100 - values.commission;
          if (!values.commission) {
            errors.commission = "Commission is required";
          } else if (values.commission < 0) {
            errors.commission = "Please enter a number between 0 and 100";
          }
          if (!values.initial) {
            errors.initial = "Initial payment is required";
          } else if (values.initial < 0 || values.initial > remaining) {
            errors.initial = "Please enter a number between 0 and " + remaining;
          } else {
            values.outstanding = remaining - values.initial;
          }

          return errors;
        }}
      >
        <Form>
          <CustomInputField
            type="number"
            name="commission"
            placeholder="Type here"
            label="Commission (%)"
            min={0}
            max={100}
          />
          <CustomInputField
            type="number"
            name="initial"
            placeholder="Type here"
            label="Upfront Payment (%)"
          />
          <CustomInputField
            type="number"
            name="outstanding"
            placeholder="Type here"
            label="Outstanding Payment (%)"
            readOnly
          />
          <RoundedMdButton
            label={paymentSplit?.guid ? "Update" : "Save"}
            type="submit"
            className="border-0 bg-orange-400 text-white"
          />
        </Form>
      </Formik>
    </div>
  );
}

export default VendorPaymentStructure;
