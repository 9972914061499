import React from "react";
import { GENERAL_GRAY_HEX } from "../constants/texts";

function CustomProgressLine({ label, type, value, max }) {
  const getPercentage = () => {
    return (parseInt(value) / max) * 80;
  };
  
  return (
    <div>
      <div className="flex justify-between items-center text-[14px]">
        <p className={`text-[${GENERAL_GRAY_HEX}] font-[400]`}>{label}</p>
        <p>
          {value} {type}(s)
        </p>
      </div>
      <div
        className="w-full bg-white mt-1 rounded-md"
        style={{ backgroundColor: "whitesmoke" }}
      >
        <div
          className={`bg-orange-400 h-[1em] rounded-md`}
          style={{ width: getPercentage() + "%" }}
        ></div>
      </div>
    </div>
  );
}

export default CustomProgressLine;
