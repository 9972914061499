import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { USERS_QUERY_KEY } from "../constants/queryKeys";
import { getAdminUsersList } from "../services/admin-users.services";

function useUsers(data, pageSize, pageNumber, filter, searchTerm) {
  const navigate = useNavigate();
  const {
    data: users,
    isLoading,
    error,
  } = useQuery({
    queryKey: [USERS_QUERY_KEY, pageSize, pageNumber, filter, searchTerm],
    queryFn: async () => {
      const result = await getAdminUsersList(
        data,
        pageSize,
        pageNumber,
        filter,
        searchTerm
      );
      return result?.data;
    },
    onError: (error) => {
      // return error
    },
  });

  return {
    users,
    isLoading,
    error,
  };
}

export default useUsers;
