import PayoutTableHeaderList from "./PayoutTableHeaderList";
import PayoutRowItem from "./PayoutRowItem";
import CustomTable from "../admin_commons/CustomTable";

function PayoutsListing({
  payouts,
  user = null,
  onRowClick,
  isLoading,
  totalRecords,
  paginationData,
}) {
  return (
    <CustomTable
      user={user}
      paginationData={paginationData}
      onRowClick={onRowClick}
      totalRecords={totalRecords}
      isLoading={isLoading}
      HeaderComponent={PayoutTableHeaderList}
      data={payouts}
      RowItemComponent={PayoutRowItem}
    />
  );
}

export default PayoutsListing;
