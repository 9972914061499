export const getSubTotal = (order) => {
  try {
    const subtotal = order?.quantity * order?.Product?.priceAfterDiscount;
    return subtotal
  } catch (err) {
    return "N/A";
  }
};

export const getShippingAddress = (order) => {
  try {
    return JSON.parse(order?.shippingAddress)?.address;
  } catch (err) {
    return "N/A";
  }
};
