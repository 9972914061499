import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reviewVendor } from "../services/vendors-services";
import { LOGISTICS_PARTNERS_QUERY_KEY } from "../constants/queryKeys";
import { reviewLogisticsPartner } from "../services/logistics-partners-service";

function useReviewLogisticsPartner() {
  const queryClient = useQueryClient();
  const {
    mutateAsync: logisticsPartnerReview,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (details) => reviewLogisticsPartner(details),
    onSuccess: (data) => {
      queryClient.invalidateQueries([LOGISTICS_PARTNERS_QUERY_KEY]);
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    logisticsPartnerReview,
    isLoading,
    error,
    data,
  };
}

export default useReviewLogisticsPartner;
