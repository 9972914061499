import React from "react";

function PageTitleBig({
  title,
  marginBottomClass = "mb-8",
  className = "",
}) {
  return (
    <div>
      <div className={`${marginBottomClass}`}>
        <div className={`font-[600] text-[28px] ${className}`}>{title}</div>
      </div>
    </div>
  );
}

export default PageTitleBig;
