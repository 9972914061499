import React from "react";

function CustomLabelValue({ label, value }) {
  return (
    <div className="flex border rounded-md">
      <div className="w-[30%] bg-gray-200 text-left px-2 font-[500] py-2 rounded-l-md">
        {label}
      </div>
      <div className="w-[70%] py-2 text-left px-2">{value}</div>
    </div>
  );
}

export default CustomLabelValue;
