import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ADMIN_USER_QUERY_KEY } from "../constants/queryKeys";
import { reviewAdminUser } from "../services/admin-users.services";

function useReviewAdminUser() {
  const queryClient = useQueryClient();
  const {
    mutateAsync: adminUserReview,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (details) => reviewAdminUser(details),
    onSuccess: (data) => {
      queryClient.invalidateQueries([ADMIN_USER_QUERY_KEY]);
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    adminUserReview,
    isLoading,
    error,
    data,
  };
}

export default useReviewAdminUser;
