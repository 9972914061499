import { PieChart } from "@mui/x-charts";
import React from "react";
import { GENERAL_GRAY_HEX } from "../constants/texts";

function CustomCardWithPieChart({ title }) {
  const pieParams = { height: 200, margin: { right: 5 } };
  const palette = ["#B00074", "#F3BE00", "#318EC9"];
  const data = [
    { id: 0, value: 60, color: "#B00074", textLabel: "New Customers" },
    { id: 1, value: 40, color: "#F3BE00", textLabel: "Returning Customers" },
  ];
  return (
    <div className="bg-white shadow-sm p-4 w-full text-left rounded-md">
      <div className="font-[500] text-[14px]">{title}</div>
      <div className="text-center">
        <PieChart
          colors={palette}
          series={[
            {
              data,
            },
          ]}
          {...pieParams}
        />
      </div>

      {/* Legend */}
      {data?.map((d) => {
        return (
          <div className="flex gap-2 mt-[1em] items-center">
            <div
              className={`w-[1em] h-[1em] rounded-full bg-[${d.color}]`}
              style={{ backgroundColor: d.color }}
            ></div>
            <div className={`font-[400] text-[14px] text-[${GENERAL_GRAY_HEX}]`}>
              {d.textLabel}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CustomCardWithPieChart;
