import { useMutation, useQueryClient } from "@tanstack/react-query";
import { USERS_QUERY_KEY } from "../constants/queryKeys";
import { modifyAdminUser } from "../services/admin-users.services";

function useUpdateAdminUser() {
  const queryClient = useQueryClient();
  const {
    mutateAsync: updateAdminUser,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (userDetails) => modifyAdminUser(userDetails),
    onSuccess: (data) => {
      queryClient.invalidateQueries([USERS_QUERY_KEY]);
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    updateAdminUser,
    isLoading,
    error,
    data,
  };
}

export default useUpdateAdminUser;
