import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import CustomButton from "../../commons/CustomButton";
import CardTitle from "../admin_commons/CardTitle";
import CustomIconLabelValue from "../../commons/CustomIconLabelValue";
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineEmail } from "react-icons/md";
import { GiSmartphone } from "react-icons/gi";
import LocationIcon from "../../commons/LocationIcon";
import RadioItem from "./../../commons/RadioItem";
import CustomLabelValue from "./../../commons/CustomLabelValue";
import { Form, Formik } from "formik";
import CustomSelectField from "./../../commons/CustomSelectField";
import StyledSpan from "../../commons/StyledSpan";
import CustomPriceDisplay from "../../commons/CustomPriceDisplay";
import CustomIconWithRoundedBg from "../../commons/CustomIconWithRoundedBg";
import { GENERAL_ORANGE_HEX } from "./../../constants/texts";
import PackageIcon from "../../commons/PackageIcon";
import TruckIcon from "./../../commons/TruckIcon";
import ClockIcon from "../../commons/ClockIcon";
import CartIcon from "./../../commons/CartIcon";
import useOrder from "../../hooks/useOrder";
import useUser from "../../hooks/useUser";
import { getFullName } from "../../helpers/extractors";
import useUpdateOrderStatus from "../../hooks/useUpdateOrderStatus";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";
import CustomBackdrop from "../../commons/CustomBackdrop";
import { getShippingAddress, getSubTotal } from "../../helpers/orders";
import TimelineItem from "../../commons/TimelineItem";
import CustomExportButton from "../../commons/CustomExportButton";

function OrderDetails() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const params = useParams();
  const id = params.slug;
  const { user } = useUser();
  const { order } = useOrder(user, id);
  const { updateStatusOfOrder } = useUpdateOrderStatus();
  const [status, setStatus] = useState("");

  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();

  const handleUpdateOrderStatus = (values) => {
    if (!values?.status) {
      setInfoDetails({ message: "Status should not be empty", isError: true });
      return;
    }
    setIsSubmitting(true);
    updateStatusOfOrder({ ...values, token: user?.token })
      .then((res) => {
        setInfoDetails({ message: res?.data?.message, isError: false });
      })
      .catch((err) => {
        console.error("err updating status", err);
        setInfoDetails({ message: err?.message, isError: true });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const customerDetails = order?.User;
  const product = order?.Product;

  const orderDetails = order;

  const { shippingAddress, paymentData, Product } = orderDetails ?? {};

  // Generate order details for dataObject
  const getOrderDetails = (order) => {
    return {
      reference: ["Reference", order?.reference],
      status: ["Status", order?.status],
      total: ["Total", order?.total],
    };
  };
  const tableData = [
    {
      reference: orderDetails?.reference,
      quantity: orderDetails?.quantity,
      status: orderDetails?.status,
      deliveryPhase: orderDetails?.deliveryPhase,
      color: orderDetails?.color,
    },
    {
      orderNumber: orderDetails?.orderNumber,
      total: orderDetails?.total,
      shipping: orderDetails?.shipping,
      paymentMethod: orderDetails?.paymentMethod,
      shipmentId: orderDetails?.shipmentId,
    },
    { ...shippingAddress, paymentData: paymentData, ...Product },
  ];

  // Page Titles and Table Headers
  const pageTitles = [
    "Order Details",
    "Payment & Shipping",
    "Product Information",
  ];
  const tableHeaders = [
    [
      { key: "reference", label: "Reference" },
      { key: "quantity", label: "Quantity" },
      { key: "status", label: "Status" },
      { key: "deliveryPhase", label: "Delivery Phase" },
      { key: "color", label: "Color" },
    ],
    [
      { key: "orderNumber", label: "Order Number" },
      { key: "total", label: "Total" },
      { key: "shipping", label: "Shipping" },
      { key: "paymentMethod", label: "Payment Method" },
      { key: "shipmentId", label: "Shipment ID" },
    ],
    [
      { key: "name", label: "Product Name" },
      { key: "sku", label: "Product SKU" },
      { key: "price", label: "Product Price" },
      { key: "priceAfterDiscount", label: "Discount Price" },
    ],
  ];

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        {/* First section */}
        <PageTitle title={"Orders"} />
        <div className="flex justify-between">
          <PageBreadCrumb
            mainText="Dashboard"
            subText="Order List"
            otherText="Order Details"
            isActiveIndex={2}
          />

          <div className="flex gap-4">
            <CustomButton
              label="Back"
              onClick={() => navigate(-1)}
              className={`border border-[${GENERAL_GRAY_HEX}] text-[${GENERAL_GRAY_HEX}]`}
            />
            <CustomButton
              label="Save changes"
              onClick={() =>
                handleUpdateOrderStatus({
                  id: order?.id,
                  status,
                })
              }
              className={`border bg-orange-400 border-[${GENERAL_GRAY_HEX}] text-[white]`}
            />
            <CustomExportButton
              pageTitles={pageTitles}
              tableHeaders={tableHeaders}
              tableData={tableData}
              dataObject={getOrderDetails(orderDetails)}
              title={`Order Details for ${orderDetails?.orderNumber}`}
              fileName={`Order_${orderDetails?.orderNumber}_details`}
            />
          </div>
        </div>

        {/* Second section */}
        <div className="mt-6 flex gap-4 w-full">
          {/* First Card */}
          <div className="p-4 shadow-sm rounded-md w-full bg-white">
            <CardTitle title={"Customer Details"} />
            <div className="mt-3">
              <CustomIconLabelValue
                icon={<AiOutlineUser className="text-[16px]" />}
                label="Name"
                value={getFullName(
                  customerDetails?.firstName,
                  customerDetails?.lastName
                )}
              />
              <CustomIconLabelValue
                icon={<MdOutlineEmail className="text-[16px]" />}
                label="Email"
                value={customerDetails?.email}
              />
              <CustomIconLabelValue
                icon={<GiSmartphone className="text-[16px]" />}
                label="Phone"
                value={customerDetails?.phoneNumber}
              />
            </div>
          </div>

          {/* Second Card */}
          <div className="p-4 shadow-sm rounded-md w-full bg-white">
            <CardTitle title={"Address"} />
            <div className="mt-3">
              <CustomIconLabelValue
                icon={<LocationIcon className="text-[16px]" />}
                label="Billing Address"
                value={getShippingAddress(order)}
              />
              <CustomIconLabelValue
                icon={<LocationIcon className="text-[16px]" />}
                label="Shipping Address"
                value={getShippingAddress(order)}
              />
            </div>
          </div>

          {/* Third Card */}
          <div className="p-4 shadow-sm rounded-md w-full bg-white">
            <CardTitle title={"Installation"} />
            <div className="mt-3">
              <RadioItem
                textStyle={"text-[12px] font-[400]"}
                padding={"p-3"}
                label="Customer requires installation"
              />
              <div className="mt-8">
                <CustomLabelValue label="Date" value={"12/12/2024"} />
              </div>
            </div>
          </div>

          {/* Fourth Card */}
          <div className="p-4 shadow-sm rounded-md w-full bg-white">
            <div className="flex justify-between items-center">
              <CardTitle title={"Order Status"} />
              <StyledSpan text={order?.trackingStatus} />
            </div>
            <div className="mt-3">
              <Formik
                enableReinitialize
                initialValues={{
                  id: order?.id,
                  status: order?.trackingStatus,
                }}
              >
                {({ values, setFieldValue, errors }) => {
                  return (
                    <Form>
                      <CustomSelectField
                        name="status"
                        list={[
                          "pending",
                          "picked",
                          "shipped",
                          "delivered",
                          "returned",
                        ]}
                        placeholder="Select status"
                        label="Status"
                        selectedValue={values?.status}
                        onChange={(value) => {
                          setFieldValue("status", value);
                          setStatus(value);
                        }}
                      />
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>

        {/* Third section */}
        <div className="mt-6 flex justify-between gap-4">
          <div className="shadow-sm rounded-md bg-white w-[65%]">
            <div className="p-4">
              <CardTitle title="Ordered Item" />
            </div>

            <div className="">
              <div class="relative overflow-x-auto mb-8">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="font-[400] text-[13px] ">
                    <tr className="bg-gray-100">
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Order No</span>
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Product</span>
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Quantity</span>
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Amount</span>
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Total</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="">
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>#{order?.orderNumber}</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>{product?.name}</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>{order?.quantity}</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay
                              price={product?.priceAfterDiscount}
                            />
                          </span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay price={getSubTotal(order)} />
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Installation Fee</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay
                              price={order?.installationFee || 0}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr className="">
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Shipping cost</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay price={order?.shipping || 0} />
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Total</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay price={order?.total} />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="p-4 shadow-sm rounded-md bg-white w-[35%]">
            <CardTitle title="Order Timeline" />
            <div className="mt-3"></div>
            {order?.statuses?.map((timeline, index) => {
              return (
                <TimelineItem
                  bg={GENERAL_ORANGE_HEX}
                  status={timeline?.status}
                  description={timeline?.description}
                  date={timeline?.updatedAt}
                  currentIndex={order?.statuses?.length}
                  showLine={order?.statuses?.length !== index + 1}
                >
                  <PackageIcon />
                </TimelineItem>
              );
            })}
          </div>
        </div>
      </div>

      {InfoDisplayComponent}

      {isSubmitting && (
        <CustomBackdrop open={isSubmitting} text="Please wait..." />
      )}
    </Layout>
  );
}

export default OrderDetails;
