import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reviewVendor } from "../services/vendors-services";
import { INSTALLERS_QUERY_KEY } from "../constants/queryKeys";
import { reviewInstaller } from "../services/installers-service";

function useReviewInstaller() {
  const queryClient = useQueryClient();
  const {
    mutateAsync: installerReview,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (details) => reviewInstaller(details),
    onSuccess: (data) => {
      queryClient.invalidateQueries([INSTALLERS_QUERY_KEY]);
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    installerReview,
    isLoading,
    error,
    data,
  };
}

export default useReviewInstaller;
