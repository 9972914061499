import React from "react";
import CustomNoRecordFound from "./../../commons/CustomNoRecordFound";
import { MdOutlineSearchOff } from "react-icons/md";
import CustomBackdrop from "./../../commons/CustomBackdrop";
import Pagination from "./../../commons/Pagination";

function CustomTable({
  HeaderComponent,
  user = null,
  sn,
  RowItemComponent,
  paginationData,
  data,
  valueKey = "id",
  totalRecords,
  onRowClick,
}) {
  const handleRowClick = (item, key) => {
    try {
      onRowClick(item[key]);
    } catch (err) {}
  };

  const handlePageChange = (e) => {
    if (paginationData) {
      paginationData?.setPageNumber(e);
    } else {
    }
  };

  return (
    <>
      {data?.length > 0 && (
        <div className="shadow-sm bg-white mt-6 rounded-t-md">
          <div class="relative overflow-x-auto my-8">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <HeaderComponent />
              <tbody>
                {data?.map((dataItem, index) => {
                  return (
                    <RowItemComponent
                      item={dataItem}
                      user={user}
                      sn={index + 1}
                      onClick={() => handleRowClick(dataItem, valueKey)}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {data?.length === 0 && (
        <div className="mt-10 opacity-50">
          <CustomNoRecordFound
            buttonText={""}
            label={"No records found"}
            moreLabel={""}
            onButtonClick={() => {}}
            showButton={false}
            children={<MdOutlineSearchOff className="text-[4em]" />}
          />
        </div>
      )}

      {data && totalRecords > 0 && paginationData && (
        <Pagination
          className="shadow-sm rounded-lg bg-white"
          onPageChange={(e) => handlePageChange(e)}
          pageSize={paginationData?.pageSize ?? 0}
          currentPage={paginationData?.pageNumber}
          totalCount={totalRecords}
        />
      )}
    </>
  );
}

export default CustomTable;
