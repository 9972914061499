import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { CART_ITEM_QUERY_KEY, PRODUCTS_QUERY_KEY, WISH_LIST_QUERY_KEY } from '../constants/queryKeys';
import { addProduct } from "../services/products-services";

function useAddVendorProduct() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { mutateAsync: addVendorProduct, isLoading, error, data } = useMutation({
        mutationFn: (updateData) => addProduct(updateData),
        onSuccess: (data) => {
            queryClient.invalidateQueries([PRODUCTS_QUERY_KEY]);
            // navigate("/my-account")
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        addVendorProduct, isLoading, error, data
    }
}

export default useAddVendorProduct