import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { RETURNED_ORDER_ITEMS_QUERY_KEY } from "../constants/queryKeys";
import { fetchAllReturnedOrders } from "../services/returned-order-services";

function useReturnedOrders(user, pageSize, pageNumber, filter, dateRange, searchTerm) {
  const navigate = useNavigate();
  const {
    data: returnedOrders,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      RETURNED_ORDER_ITEMS_QUERY_KEY,
      user,
      pageSize,
      pageNumber,
      filter,
      dateRange,
      searchTerm
    ],
    queryFn: async () => {
      const result = await fetchAllReturnedOrders(
        user,
        pageSize,
        pageNumber,
        filter,
        dateRange,
        searchTerm
      );
      return result?.data;
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    returnedOrders,
    isLoading,
    error,
  };
}

export default useReturnedOrders;
