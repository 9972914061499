import React from "react";
import CustomSortingArrows from '../../commons/CustomSortingArrows';

function TicketTableHeaderList() {
  return (
    <thead class="font-[400] text-[13px] bg-gray-100">
      <tr className="">
        {/* <th scope="col" class="px-5 py-3 font-[400] text-[13px]">
          <input type="checkbox" style={{}} />
        </th> */}
        <th scope="col" class="px-5 py-3 font-[400]">
          <div className="flex items-center gap-2">
            <span>Date</span>
            <CustomSortingArrows />
          </div>
        </th>
        <th scope="col" class="px-5 py-3 font-[400]">
          <div className="flex items-center gap-2">
            <span>Name</span>
            <CustomSortingArrows />
          </div>
        </th>
        <th scope="col" class="px-5 py-3 font-[400]">
          <div className="flex items-center gap-2">
            <span>Email Address</span>
            <CustomSortingArrows />
          </div>
        </th>
        <th scope="col" class="px-5 py-3 font-[400]">
          <div className="flex items-center gap-2">
            <span>Phone Number</span>
            <CustomSortingArrows />
          </div>
        </th>
        <th scope="col" class="px-5 py-3 font-[400]">
          <div className="flex items-center gap-2">
            <span>Subject</span>
            <CustomSortingArrows />
          </div>
        </th>
        <th scope="col" class="px-5 py-3 font-[400]">
          <div className="flex items-center gap-2">
            <span>Status</span>
            <CustomSortingArrows />
          </div>
        </th>
      </tr>
    </thead>
  );
}

export default TicketTableHeaderList;
