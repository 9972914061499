import { addPaginationUrlQuery } from "../helpers/extractors";
import axiosInstance from "../utils/axiosInstance";


export const getCustomerList = async (data, pageSize, pageNumber, status="", searchTerm="") => {
    let url = "/users"

    url = addPaginationUrlQuery(url, pageSize, pageNumber);
    url += `&status=${status}&searchTerm=${searchTerm}`

    return axiosInstance(`${url}`, "GET", data).then(response => {
        return response;
    })
}

export const getSingleCustomer = async (data) => {
    return axiosInstance(`/users/${data?.id}`, "GET", data).then(response => {
        return response;
    })
}