import React, { useState } from "react";
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { PiCalendarThin } from "react-icons/pi";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { GENERAL_ORANGE_HEX, GENERAL_GRAY_HEX } from './../constants/texts';

function CustomDatePicker({ text = "Custom Date", onChange, callback, state, setState, showCalendar, setShowCalendar }) {

  return (
    <div onClick={() => setShowCalendar(!showCalendar)} className="relative bg-white cursor-pointer py-2 px-3 shadow-sm rounded-md flex items-center gap-2 justify-between">
      <PiCalendarThin className="text-lg" />
      <span>{text}</span>
      {showCalendar && <div className="absolute right-0 top-[2.5em] bg-gray-200 p-1" style={{ zIndex: "8000" }} onClick={(e) => e.stopPropagation()}>
        <DateRangePicker
          className="bg-red-100"
          onChange={item => { setState([item.selection]); callback() }}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
          preventSnapRefocus={true}
          calendarFocus="backwards"
        />
        <div className="flex gap-4 border shadow-sm">
          <div className="p-2 text-center w-full" onClick={() => setShowCalendar(false)} style={{ color: "black" }}>Cancel</div>
          <div className="p-2 text-center w-full" onClick={() => setShowCalendar(false)} style={{ backgroundColor: GENERAL_ORANGE_HEX, color: "white" }}>OK</div>
        </div>
      </div>}
    </div>
  );
}

export default CustomDatePicker;
