import React, { useState } from "react";
import CardTitle from "../../commons/CardTitle";
import { Formik, Form } from "formik";
import CustomInputField from "../../commons/CustomInputField";
import FullRoundedButton from "../../commons/FullRoundedButton";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import useUser from "../../hooks/useUser";
import useUpdateUserPassword from "../../hooks/useUpdateUserPassword";
import CustomSnackBar from "../../commons/CustomSnackBar";
import { useNavigate } from "react-router-dom";

function AccountChangePassword({}) {
  const { user } = useUser();
  const { updateUserPassword } = useUpdateUserPassword();

  const navigate = useNavigate();

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

 

  return (
    <div className="shadow-md rounded-lg w-full bg-white p-6 text-left">
      <CardTitle title="Change Password" />

      {/* Form */}
      <div>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            updateUserPassword({
              ...values,
              userId: user?.id,
              accessToken: user?.token,
            })
              .then((res) => {
                setSnackBarMessage(res?.data?.message);
                setIsError(false);
                setOpenSnackBar(true);
                resetForm();
              })
              .catch((err) => {
                setSnackBarMessage(err?.message);
                setIsError(true);
                setOpenSnackBar(true);
                ;
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
          validate={(values) => {
            const errors = {};
            if (!values.newPassword) {
              errors.newPassword = "New password is required";
            } else if (!/\^*[A-Z]/i.test(values.newPassword)) {
              errors.newPassword = "New password should contain an uppercase";
            } else if (!/\^*[a-z]/.test(values.newPassword)) {
              errors.newPassword = "New password should contain a lowercase";
            } else if (!/\^*[0-9]/i.test(values.newPassword)) {
              errors.newPassword = "New password should contain a number";
            } else if (!/\^*[!@#$%^&*]/i.test(values.newPassword)) {
              errors.newPassword =
                "New password should contain at least one special character";
            } else if (values.newPassword.length < 8) {
              errors.newPassword =
                "New password should be at least 8 characters";
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = "Confirm password is required";
            }

            if (values.confirmPassword !== values.newPassword) {
              errors.confirmPassword = "Passwords must match";
            }

            if (!values.oldPassword) {
              errors.oldPassword = "Current password is required";
            }

            return errors;
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <CustomInputField
                label={"Current Password"}
                type="password"
                placeholder="Please enter your current password"
                name="oldPassword"
              />
              <CustomInputField
                label={"New Password"}
                type="password"
                placeholder="Please enter your new password"
                name="newPassword"
              />
              <CustomInputField
                label={"Confirm Password"}
                type="password"
                placeholder="Please re-enter your new password"
                name="confirmPassword"
              />

              {/* Submit button */}
              <FullRoundedButton
                label={isSubmitting ? "Please wait" : "Change password"}
                className={"text-white bg-[#FF9D21] hover:bg-[#FF9D21] mt-3"}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <CustomThreeDotsSpinner
                    height="20"
                    width="20"
                    color="white"
                  />
                )}
              </FullRoundedButton>
            </Form>
          )}
        </Formik>
      </div>
      <div className="mb-10"></div>
      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default AccountChangePassword;
