export const redirectTo = (url) => {
  window.location.href = url;
};

export const delayAction = (action, delay = 3000) => {
  if (action) {
    setTimeout(() => {
      if (action) {
        action();
      }
    }, delay);
  }
};
