import React from "react";
import MakerTableHeaderList from "./MakerTableHeaderList";
import MakerRowItem from "./MakerRowItem";
import CustomTable from "../admin_commons/CustomTable";

function MakersListing({
  makers,
  onRowClick,
  isLoading = false,
  totalRecords = 0,
}) {
  return (
    <CustomTable
      onRowClick={onRowClick}
      totalRecords={totalRecords}
      isLoading={isLoading}
      HeaderComponent={MakerTableHeaderList}
      data={makers}
      RowItemComponent={MakerRowItem}
    />
  );
}

export default MakersListing;
