import React from "react";
import { TfiDownload } from "react-icons/tfi";

function CustomButton({
  label = "Button",
  children,
  onClick,
  type = "button",
  className = "bg-[#FFF2DB] text-orange-400",
  disabled = false
}) {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={`${className} cursor-pointer font-[400] text-[14px] py-2 px-3 rounded-md flex gap-1.5 items-center`}
    >
      {children}
      <span>{label}</span>
    </button>
  );
}

export default CustomButton;
