import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { registerLogisticsPartner } from '../services/logistics-partners-service';
import { LOGISTICS_PARTNERS_QUERY_KEY } from '../constants/queryKeys';

function useAddLogisticsPartner() {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: addLogisticsPartner, isLoading, error, data } = useMutation({
        mutationFn: (updateData) => registerLogisticsPartner(updateData),
        onSuccess: (data) => {
            queryClient.invalidateQueries([LOGISTICS_PARTNERS_QUERY_KEY]);
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        addLogisticsPartner, isLoading, error, data
    }
}

export default useAddLogisticsPartner