import moment from "moment"

export const getDateTime = (date, format = "Do MMMM YYYY, hh:mm A") => {
    return moment(date).format(format)
}


// Week number = 0 means current week
// Week number = 1 means last week
export const getCurrentWeek = (weekNumber = 0, format = "DD-MM-YYYY") => {
    let startDate = moment().subtract(weekNumber, 'weeks').startOf('week').format(format)
    let endDate = moment().subtract(weekNumber, 'weeks').endOf('week').format(format)
    return [startDate, endDate]
}

// Month number = 0 means current month
// Month number = 1 means last month
export const getCurrentMonth = (monthNumber = 0, format = "DD-MM-YYYY") => {
    let startDate = moment().subtract(monthNumber, 'months').startOf('month').format(format)
    let endDate = moment().subtract(monthNumber, 'months').endOf('month').format(format)
    return [startDate, endDate]
}

// Year number = 0 means current year
// Year number = 1 means last year
export const getCurrentYear = (yearNumber = 0, format = "DD-MM-YYYY") => {
    let startDate = moment().subtract(yearNumber, 'years').startOf('year').format(format)
    let endDate = moment().subtract(yearNumber, 'years').endOf('year').format(format)
    return [startDate, endDate]
}
