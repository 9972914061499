import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import { GENERAL_GRAY_HEX, GENERAL_ORANGE_HEX } from "../../constants/texts";
import CustomExportButton from "../../commons/CustomExportButton";
import CustomButton from "../../commons/CustomButton";
import CardTitle from "../admin_commons/CardTitle";
import CustomIconLabelValue from "../../commons/CustomIconLabelValue";
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineEmail } from "react-icons/md";
import { GiSmartphone } from "react-icons/gi";
import LocationIcon from "../../commons/LocationIcon";
import { Form, Formik } from "formik";
import CustomSelectField from "../../commons/CustomSelectField";
import StyledSpan from "../../commons/StyledSpan";
import CustomPriceDisplay from "../../commons/CustomPriceDisplay";
import CustomTextField from "../../commons/CustomTextField";
import DocumentIcon from "../../commons/DocumentIcon";
import GeneralModal from "../../commons/GeneralModal";
import { getFileUrl, getFullName } from "../../helpers/extractors";
import useUser from "../../hooks/useUser";
import useReturnedOrder from "../../hooks/useReturnedOrder";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";
import { getShippingAddress, getSubTotal } from "../../helpers/orders";
import CustomIconWithRoundedBg from "../../commons/CustomIconWithRoundedBg";
import CartIcon from "../../commons/CartIcon";
import ClockIcon from "../../commons/ClockIcon";
import TruckIcon from "../../commons/TruckIcon";
import PackageIcon from "../../commons/PackageIcon";
import CustomBackdrop from "../../commons/CustomBackdrop";
import useUpdateReturnedOrderStatus from "../../hooks/useUpdateReturnedOrderStatus";
import TimelineItem from "../../commons/TimelineItem";

const tabItems = ["All", "Pending", "Picked", "Shipped", "Delivered"];

function ReturnedOrderDetails() {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const params = useParams();
  const id = params.slug;
  const { user } = useUser();
  const { returnedOrder, isLoading } = useReturnedOrder(user, id);
  const { updateStatusOfReturnedOrder } = useUpdateReturnedOrderStatus();
  const [status, setStatus] = useState("");

  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();

  const [showFileViewer, setShowFileViewer] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const customerDetails = returnedOrder?.order?.User;
  const product = returnedOrder?.order?.Product;

  const handleFileView = (url) => {
    setSelectedFile(url);
    setShowFileViewer(true);
  };

  const handleUpdateOrderStatus = (values) => {
    if (!values?.status) {
      setInfoDetails({ message: "Status should not be empty", isError: true });
      return;
    }
    setIsSubmitting(true);
    updateStatusOfReturnedOrder({ ...values, token: user?.token })
      .then((res) => {
        setInfoDetails({ message: res?.data?.message, isError: false });
      })
      .catch((err) => {
        console.error("err updating status", err);
        setInfoDetails({ message: err?.message, isError: true });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        {/* First section */}
        <PageTitle title={"Returned Orders"} />
        <div className="flex justify-between">
          <PageBreadCrumb
            mainText="Dashboard"
            subText="Returned Order List"
            otherText="Returned Order Details"
            isActiveIndex={2}
          />

          <div className="flex gap-4">
            <CustomButton
              label="Back"
              onClick={() => navigate(-1)}
              className={`border border-[${GENERAL_GRAY_HEX}] text-[${GENERAL_GRAY_HEX}]`}
            />
            <CustomButton
              label="Save changes"
              onClick={() =>
                handleUpdateOrderStatus({
                  id: returnedOrder?.id,
                  status,
                })
              }
              className={`border bg-orange-400 border-[${GENERAL_GRAY_HEX}] text-[white]`}
            />
            {/* <CustomExportButton /> */}
          </div>
        </div>

        {/* Second section */}
        <div className="mt-6 flex gap-4 w-full">
          {/* First Card */}
          <div className="p-4 shadow-sm rounded-md w-full bg-white">
            <CardTitle title={"Customer Details"} />
            <div className="mt-3">
              <CustomIconLabelValue
                icon={<AiOutlineUser className="text-[16px]" />}
                label="Name"
                value={getFullName(
                  customerDetails?.firstName,
                  customerDetails?.lastName
                )}
              />
              <CustomIconLabelValue
                icon={<MdOutlineEmail className="text-[16px]" />}
                label="Email"
                value={customerDetails?.email}
              />
              <CustomIconLabelValue
                icon={<GiSmartphone className="text-[16px]" />}
                label="Phone"
                value={customerDetails?.phoneNumber}
              />
            </div>
          </div>

          {/* Second Card */}
          <div className="p-4 shadow-sm rounded-md w-full bg-white">
            <CardTitle title={"Address"} />
            <div className="mt-3">
              <CustomIconLabelValue
                icon={<LocationIcon className="text-[16px]" />}
                label="Billing Address"
                value={getShippingAddress(returnedOrder?.order)}
              />
              <CustomIconLabelValue
                icon={<LocationIcon className="text-[16px]" />}
                label="Shipping Address"
                value={getShippingAddress(returnedOrder?.order)}
              />
            </div>
          </div>

          {/* Third Card */}
          {/* <div className="p-4 shadow-sm rounded-md w-full bg-white">
            <CardTitle title={"Installation"} />
            <div className="mt-3">
              <RadioItem
                textStyle={"text-[12px] font-[400]"}
                padding={"p-3"}
                label="Customer requires installation"
              />
              <div className="mt-8">
                <CustomLabelValue label="Date" value={"12/12/2024"} />
              </div>
            </div>
          </div> */}

          {/* Fourth Card */}
          <div className="p-4 shadow-sm rounded-md w-full bg-white">
            <div className="flex justify-between items-center">
              <CardTitle title={"Order Status"} />
              <StyledSpan text={returnedOrder?.status} />
            </div>
            <div className="mt-3">
              <Formik
                enableReinitialize
                initialValues={{
                  id: returnedOrder?.id,
                  status: returnedOrder?.status,
                }}
              >
                {({ values, errors, setFieldValue }) => {
                  return (
                    <Form>
                      <CustomSelectField
                        name="status"
                        list={["pending", "in-review", "accepted", "declined"]}
                        placeholder="Select status"
                        selectedValue={values?.status}
                        label="Status"
                        onChange={(value) => {
                          setFieldValue("status", value);
                          setStatus(value);
                        }}
                      />
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>

        {/* Third section */}
        <div className="mt-6 flex justify-between gap-4">
          <div className="shadow-sm rounded-md bg-white w-[65%]">
            <div className="p-4">
              <CardTitle title="Returned Item" />
            </div>

            <div className="">
              <div class="relative overflow-x-auto mb-8">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="font-[400] text-[13px] ">
                    <tr className="bg-gray-100">
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Order No</span>
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Product</span>
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Quantity</span>
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Amount</span>
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Total</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="">
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>#{returnedOrder?.order?.orderNumber}</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>{product?.name}</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>{returnedOrder?.order?.quantity}</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay
                              price={product?.priceAfterDiscount}
                            />
                          </span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay
                              price={getSubTotal({
                                ...returnedOrder,
                                ...returnedOrder?.order,
                              })}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Installation Fee</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay
                              price={returnedOrder?.order?.installationFee || 0}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr className="">
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Shipping cost</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay
                              price={returnedOrder?.order?.shipping || 0}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span></span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>Total</span>
                        </div>
                      </td>
                      <td class="px-4 py-3 font-[400] text-[13px]">
                        <div className="flex items-center gap-2">
                          <span>
                            <CustomPriceDisplay
                              price={returnedOrder?.order?.total}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="p-4 shadow-sm rounded-md bg-white w-[35%]">
            <div className="mb-5">
              <CardTitle title="Reason for return" />
              <div className="mt-3"></div>
              <div className="gap-3">
                <Formik
                  enableReinitialize
                  initialValues={{
                    reason: returnedOrder?.reason,
                  }}
                >
                  <Form>
                    <div className="w-full">
                      <CustomTextField
                        required={false}
                        showOptional={false}
                        readOnly
                        name="reason"
                        rows={10}
                      />
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
            <CardTitle title="Return Timeline" />
            <div className="mt-3"></div>
            {returnedOrder?.returnStatus?.map((timeline, index) => {
              return (
                <TimelineItem
                  bg={GENERAL_ORANGE_HEX}
                  status={timeline?.status}
                  description={timeline?.description}
                  date={timeline?.updatedAt}
                  currentIndex={returnedOrder?.returnStatus?.length}
                  showLine={returnedOrder?.returnStatus?.length !== index + 1}
                >
                  <PackageIcon />
                </TimelineItem>
              );
            })}
            {/* <TimelineItem
              bg={GENERAL_ORANGE_HEX}
              status={"Pending"}
              description={"A return has been initiated."}
              date={"12/12/2024 08:30 AM"}
            /> */}
            {/* <div className="flex items- gap-3">
              <div>
                <CustomIconWithRoundedBg
                  bg={GENERAL_ORANGE_HEX}
                  children={<CartIcon />}
                />
                <div className="w-[1.5px] h-[3em] mx-auto bg-blue-900"></div>
              </div>
              <div className={`text-[${GENERAL_GRAY_HEX}]`}>
                <div className="font-[500] text-[14px] text-black">Pending</div>
                <div>A return has been initiated.</div>
                <div>12/12/2024 08:30 AM</div>
              </div>
            </div>
            <div className="flex gap-3">
              <div>
                <CustomIconWithRoundedBg
                  bg={GENERAL_ORANGE_HEX}
                  children={<ClockIcon />}
                />
                <div className="w-[1.5px] h-[3em] mx-auto bg-blue-900"></div>
              </div>
              <div className={`text-[${GENERAL_GRAY_HEX}]`}>
                <div className="font-[500] text-[14px] text-black">Picked</div>
                <div>Seller has proccessed your order.</div>
                <div>12/12/2024 09:30 AM</div>
              </div>
            </div>
            <div className="flex gap-3">
              <div>
                <CustomIconWithRoundedBg
                  bg={GENERAL_ORANGE_HEX}
                  children={<TruckIcon />}
                />
                <div className="w-[1.5px] h-[3em] mx-auto bg-blue-900"></div>
              </div>
              <div className={`text-[${GENERAL_GRAY_HEX}]`}>
                <div className="font-[500] text-[14px] text-black">Shipped</div>
                <div>Order has been assigned to DHL for delivery</div>
                <div>12/12/2024 10:30 AM</div>
              </div>
            </div>
            <div className="flex gap-3">
              <div>
                <CustomIconWithRoundedBg
                  className="text-white"
                  bg={GENERAL_ORANGE_HEX}
                  children={<PackageIcon />}
                />
              </div>
              <div className={`text-[${GENERAL_GRAY_HEX}]`}>
                <div className="font-[500] text-[14px] text-black">
                  Delivered
                </div>
                <div>Customer order has been delivered</div>
                <div>12/12/2024 11:30 AM</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* Modal to show file */}
      <GeneralModal
        showCloseButton={true}
        isOpen={showFileViewer}
        onClose={() => setShowFileViewer(false)}
      >
        <div className="h-[40em] text-center">
          <iframe
            width="100%"
            height="100%"
            src={getFileUrl(selectedFile)}
            title="description"
          ></iframe>
        </div>
      </GeneralModal>

      {InfoDisplayComponent}

      {(isLoading || isSubmitting) && (
        <CustomBackdrop open={isLoading || isSubmitting} />
      )}
    </Layout>
  );
}

export default ReturnedOrderDetails;
