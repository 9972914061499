import ProductTableHeaderList from "./ProductTableHeaderList";
import ProductRowItem from "./ProductRowItem";
import CustomTable from "./../admin_commons/CustomTable";

function ProductsListing({
  products,
  paginationData,
  onRowClick,
  valueKey,
  isLoading = false,
  totalRecords = 0,
}) {
  return (
    <CustomTable
      paginationData={paginationData}
      onRowClick={onRowClick}
      valueKey={valueKey}
      totalRecords={totalRecords}
      isLoading={isLoading}
      HeaderComponent={ProductTableHeaderList}
      data={products}
      RowItemComponent={ProductRowItem}
    />
  );
}

export default ProductsListing;
