import React from "react";
import { TfiDownload } from "react-icons/tfi";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
// import './../fonts/font';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function CustomExportButton({
  onClick,
  text = "Export",
  pageTitles = [],
  dataObject,
  title = "PDF Document",
  fileName,
  tableHeaders,
  tableData,
}) {
  const handleExport = () => {
    if (onClick) {
      onClick();
    }
    if (!dataObject) {
      return;
    }

    const content = Object.values(dataObject).map((data) => {
      if (data) {
        return { text: `${data[0]}: ${data[1]}`, margin: [0, 20, 0, 0] };
      }
      return {};
    });

    const getTables = () => {
      const tables = [];
      if (
        pageTitles.length === tableHeaders.length &&
        tableHeaders.length === tableData.length
      ) {
        tableHeaders.map((header, index) => {
          tables.push({ text: pageTitles[index], margin: [0, 20, 0, 0] });
          tables.push({
            table: {
              headerRows: 1,
              widths: [...header.map((h) => "*")],
              body: [
                [...header.map((h) => h.label)],
                [...header.map((h) => tableData[index][h.key] ?? "")],
              ],
            },
            layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return rowIndex === 0 ? "#f0f0f0" : null; // Fill color for header row
              },
              hLineWidth: (i, node) => {
                return i === 0 || i === node.table.body.length ? 2 : 1; // Thicker lines for top and bottom
              },
              vLineWidth: () => 0, // No vertical lines
              paddingLeft: () => 4,
              paddingRight: () => 4,
            },
          });
        });
        return tables;
      } else {
        return [];
      }
    };

    const docDefinition = {
      styles: {
        paragraph: {
          fontSize: 10,
        },
        table: {
          fontSize: 10,
        },
      },
      content: [
        { text: `${title}`, fontSize: 10, alignment: "center", bold: true },
        ...content,
        ...getTables(),
        // {
        //   table: {
        //     headerRows: 1,
        //     widths: [100, '*', '*'],
        //     body: [
        //       ['ID', 'Name', 'Age'],
        //       ['1', 'Alice', '30'],
        //       ['2', 'Bob', '25'],
        //       ['3', 'Charlie', '35']
        //     ]
        //   },
        //   layout: {
        //     fillColor: (rowIndex, node, columnIndex) => {
        //       return rowIndex === 0 ? '#f0f0f0' : null; // Fill color for header row
        //     },
        //     hLineWidth: (i, node) => {
        //       return i === 0 || i === node.table.body.length ? 2 : 1; // Thicker lines for top and bottom
        //     },
        //     vLineWidth: () => 0, // No vertical lines
        //     paddingLeft: () => 4,
        //     paddingRight: () => 4
        //   }
        // }
      ],
      defaultStyle: {
        fontSize: 8, // Set the default font size here
      },
    };

    pdfMake.createPdf(docDefinition).download(`${fileName}.pdf`);
  };

  return (
    <div
      onClick={handleExport}
      className="bg-[#FFF2DB] text-orange-400 cursor-pointer py-2 px-3 shadow-sm rounded-md flex gap-2 items-center"
    >
      <TfiDownload />
      <span>{text}</span>
    </div>
  );
}

export default CustomExportButton;
