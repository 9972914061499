import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const login = async (data) => {
  return axiosInstance(`/admin/login`, "POST", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const logout = async (data) => {
  return axiosInstance(`/admin/logout`, "POST", data, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const deleteAccount = async (data) => {
  return axiosInstance(`/auth/delete-account`, "POST", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const googleLogin = async (data) => {
  return axiosInstance(`/auth/google-login`, "POST", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const getUser = async (data) => {
  return axiosInstance(`/admin/get`, "POST", { id: data?.guid }, data.token)
    .then((response) => {
      return response;
    })
    
};

export const register = async (data) => {
  return axiosInstance(`/auth/register`, "POST", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const registerVendor = async (data) => {
  return axiosInstance(`/vendors/auth/register`, "POST", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const update = async (data) => {
  return axiosInstance(`/admin/self`, "PUT", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const updateVendor = async (data) => {
  return axiosInstance(`/vendors/${data.id}`, "PUT", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const updatePassword = async (data) => {
  return axiosInstance(`/admin/change-password`, "PUT", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const verifyEmail = async (data) => {
  return axiosInstance(`/vendors/verify/${data.token}`, "PUT", data, data.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};
