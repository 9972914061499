import React from 'react'
import { useNavigate } from 'react-router-dom';
import OrderTableHeaderList from '../admin_commons/OrderTableHeaderList';
import CustomTable from '../admin_commons/CustomTable';
import ReturnedOrderRowItem from './ReturnedOrderRowItem';

function ReturnedOrdersListing({ orders, user=null, isLoading, onRowClick, totalRecords, tableMinWidth = "" }) {
    const navigate = useNavigate()
    return (
        <CustomTable user={user} onRowClick={onRowClick} totalRecords={totalRecords} isLoading={isLoading} HeaderComponent={OrderTableHeaderList} data={orders} RowItemComponent={ReturnedOrderRowItem} />
    )
}

export default ReturnedOrdersListing