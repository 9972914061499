import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";


export const registerLogisticsPartner = async (data) => {
    return axiosInstance(`/logistics-partners`, "POST", data, data?.accessToken).then(response => {
        return response;
    }).catch(err => {
        getNetworkErrorMessage(err)
    })
}

export const registerServiceLocation = async (data) => {
    return axiosInstance(`/logistics-partners/service-location`, "POST", data).then(response => {
        return response;
    }).catch(err => {
        getNetworkErrorMessage(err)
    })
}

export const getLogisticsPartner = async (data) => {
    return axiosInstance(`/logistics-partners?status=${data?.status}&searchTerm=${data?.searchTerm}`, "GET", data, data?.accessToken)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            getNetworkErrorMessage(err);
        });
};

export const getLogisticsPartnerById = async (data, id) => {
    return axiosInstance(`/logistics-partners/${id}`, "GET", null, data?.token)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            // return err
            getNetworkErrorMessage(err);
        });
};

export const reviewLogisticsPartner = async (data) => {
    return axiosInstance(
        `/logistics-partners/${data.id}/review`,
        "PUT",
        data,
        data?.accessToken
    )
        .then((response) => {
            return response;
        })
        .catch((err) => {
            getNetworkErrorMessage(err);
        });
};