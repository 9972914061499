import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { SINGLE_LOGISTICS_PARTNER_QUERY_KEY } from "../constants/queryKeys";
import { getLogisticsPartnerById } from "../services/logistics-partners-service";

function useSingleLogisticsPartner(user, id) {
  const navigate = useNavigate();
  const {
    data: logisticsPartner,
    isLoading,
    error,
  } = useQuery({
    queryKey: [SINGLE_LOGISTICS_PARTNER_QUERY_KEY, id],
    queryFn: async () => {
      const result = await getLogisticsPartnerById(user, id);
      return result?.data?.data;
    },
    enabled: id != null,
    onError: (error) => {
      // return error
    },
  });
  return {
    logisticsPartner,
    isLoading,
    error,
  };
}

export default useSingleLogisticsPartner;
