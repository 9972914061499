import React from "react";
import { useNavigate } from "react-router-dom";
import VendorTableHeaderList from "./VendorTableHeaderList";
import VendorRowItem from "./VendorRowItem";
import CustomTable from "../admin_commons/CustomTable";

function VendorsListing({
  vendors,
  user = null,
  onRowClick,
  isLoading,
  totalRecords,
  tableMinWidth = "",
}) {
  const navigate = useNavigate();
  return (
    <CustomTable
      user={user}
      onRowClick={onRowClick}
      totalRecords={totalRecords}
      isLoading={isLoading}
      HeaderComponent={VendorTableHeaderList}
      data={vendors}
      RowItemComponent={VendorRowItem}
    />
  );
}

export default VendorsListing;
