import { addPaginationUrlQuery } from "../helpers/extractors";
import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const getAdminUsersList = async (
  data,
  pageSize,
  pageNumber,
  status = "",
  searchTerm = ""
) => {
  let url = "/admin";

  url = addPaginationUrlQuery(url, pageSize, pageNumber);
  url += `&status=${status}&searchTerm=${searchTerm}`;

  return axiosInstance(`${url}`, "GET", data, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const addAdmin = async (data) => {
  return axiosInstance(`/admin`, "POST", data, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const modifyAdminUser = async (data) => {
  return axiosInstance(`/admin/${data?.id}`, "PUT", data, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const reviewAdminUser = async (data) => {
  return axiosInstance(
    `/admin/${data.id}/review`,
    "PUT",
    data,
    data?.accessToken
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};
