import React, { useState } from "react";
import Layout from "../../commons/Layout";
import PageTitle from "../../commons/PageTitle";
import InfoCard from "./InfoCard";
import CustomPriceDisplay from "../../commons/CustomPriceDisplay";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import CustomExportButton from "../../commons/CustomExportButton";
import CustomButton from "./../../commons/CustomButton";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import ProductsListing from "../admin_products/ProductsListing";
import AccountInfo from "../admin_commons/AccountInfo";
import VendorDocuments from "./VendorDocuments";
import VendorPaymentStructure from "./VendorPaymentStructure";
import BankDetails from "../admin_commons/BankDetails";
import useSingleVendor from "../../hooks/useSingleVendor";
import useUser from "../../hooks/useUser";
import useVendorPaymentSplit from "../../hooks/useVendorPaymentSplit";
import useAddPaymentSplit from "../../hooks/useAddPaymentSplit";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";
import CustomBackdrop from "../../commons/CustomBackdrop";
import useUpdatePaymentSplit from "../../hooks/useUpdatePaymentSplit";
import {
  getFileUrl,
  getFullAddress,
  getFullName,
} from "../../helpers/extractors";
import useReviewVendor from "../../hooks/useApproveRejectVendor";
import ConfirmationModal from "../../commons/modals/ConfirmationModal";
import RoundedMdButton from "../../commons/RoundedMdButton";
import CustomTextField from "../../commons/CustomTextField";
import { Form, Formik } from "formik";
import useVendorPayouts from "../../hooks/useVendorPayouts";
import PayoutsListing from "../admin_payouts/PayoutsListing";
import useFilterProducts from "../../hooks/useFilterProducts";

const pageTitles = ["Account Manager", "Bank details", "Transaction details"];
const tableHeaders = [
  [
    { key: "firstName", label: "First Name" },
    { key: "lastName", label: "Last Name" },
    { key: "email", label: "Email" },
    { key: "phoneNumber", label: "Phone Number" },
  ],
  [
    { key: "bankName", label: "Bank Name" },
    { key: "accountName", label: "Account Name" },
    { key: "accountNumber", label: "Account Number" },
  ],
  [
    { key: "totalSales", label: "Total Sales" },
    { key: "totalPayouts", label: "Total Payouts" },
    { key: "totalProducts", label: "Total Products" },
  ],
];

function VendorDetails() {
  const navigate = useNavigate();

  const params = useParams();
  const { user } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showReasonInput, setShowReasonInput] = useState(false);
  const [comment, setComment] = useState("");
  const [productPageSize, setProductPageSize] = useState(10);
  const [productPageNumber, setProductPageNumber] = useState(1);

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const { vendorReview } = useReviewVendor();

  const { InfoDisplayComponent, setInfoDetails } = useCustomInfoDisplay();

  const { slug } = params;

  const { singleVendor } = useSingleVendor({
    vendorId: slug,
    token: user?.token,
    otherDetails: true,
  });

  const { products } = useFilterProducts(
    productPageSize,
    productPageNumber,
    [],
    [],
    [],
    [singleVendor?.id],
    [],
    [],
    "",
    "",
    ""
  );

  const { paymentSplit } = useVendorPaymentSplit({
    vendorId: slug,
    token: user?.token,
  });

  const { addPaymentSplit } = useAddPaymentSplit();
  const { updatePaymentSplit } = useUpdatePaymentSplit();
  const { vendorPayouts } = useVendorPayouts(
    { token: user?.token, vendorId: singleVendor?.id },
    pageSize,
    pageNumber
  );

  //extract vendor details
  const accountManagerInfo = singleVendor?.VendorAccountManagerInformation;
  const bankDetails = singleVendor?.VendorBankDetail;
  const businessDetails = singleVendor?.VendorBusinessDetail;
  const logoImagePath = singleVendor?.logoImagePath;
  const businessName = singleVendor?.businessName;
  const contactDetails = singleVendor?.VendorContactDetail;
  const ownerDetails = singleVendor?.VendorOwnerDetail;

  const getVendorDetails = (vendor) => {
    return {
      businessName: ["Business Name", vendor?.businessName],
    };
  };

  const tableData = [
    accountManagerInfo,
    bankDetails,
    { ...singleVendor, totalProducts: singleVendor?.Products?.length },
  ];

  const handleAddPaymentSplit = (data) => {
    let action = null;
    let text = "created";
    setIsSubmitting(true);

    if (data?.guid) {
      action = updatePaymentSplit;
      text = "updated";
    } else {
      action = addPaymentSplit;
    }

    if (!action) {
      setInfoDetails({
        message: "An error occurred. Please refresh page and try again",
        isError: true,
      });
      setIsSubmitting(false);
      return;
    }
    action({ ...data, user, vendor: singleVendor, accessToken: user?.token })
      .then((res) => {
        setInfoDetails({
          message: `Payment structure ${text} successful`,
          isError: false,
        });
      })
      .catch((err) => {
        console.log("error from creating new payment split", err);
        setInfoDetails({ message: err?.message, isError: true });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleReview = (approveOrReject, data) => {
    let action = approveOrReject;

    if (action == false && !data?.comment) {
      setInfoDetails({
        message: "Please enter reason for rejection",
        isError: true,
      });
      setIsSubmitting(false);
      return;
    }

    //continue with submission
    setIsSubmitting(true);

    vendorReview({ ...data, action, accessToken: user?.token })
      .then((res) => {
        setInfoDetails({
          message: res?.data?.message,
          isError: false,
        });
      })
      .catch((err) => {
        console.log("error from reviewing vendor", err);
        setInfoDetails({ message: err?.message, isError: true });
      })
      .finally(() => {
        setIsSubmitting(false);
        setOpenModal(false);
      });
  };

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left py-6 px-3">
        {/* Top */}
        <PageTitle title={"Vendors"} />
        <div className="flex justify-between">
          <PageBreadCrumb
            mainText="Dashboard"
            subText="Vendors List"
            otherText="Vendor Details"
            isActiveIndex={2}
          />

          <div className="flex gap-4">
            <CustomButton
              label="Back"
              onClick={() => navigate(-1)}
              className={`border border-[${GENERAL_GRAY_HEX}] text-[${GENERAL_GRAY_HEX}]`}
            />
            <CustomButton
              label="Review vendor"
              onClick={() => setOpenModal(true)}
              className={`border bg-orange-400 border-[${GENERAL_GRAY_HEX}] text-[white]`}
            />
            <CustomExportButton
              pageTitles={pageTitles}
              tableHeaders={tableHeaders}
              tableData={tableData}
              dataObject={getVendorDetails(singleVendor)}
              title={`${singleVendor?.businessName} Details`}
              fileName={`${singleVendor?.businessName}_details`}
            />
          </div>
        </div>

        {/* Cards */}
        <div className="flex gap-4 mt-6">
          <InfoCard
            icon={""}
            text={"Total Products"}
            value={singleVendor?.Products?.length || 0}
            // otherText="12"
          />
          <InfoCard
            icon={""}
            text={"Total Orders"}
            value={singleVendor?.totalOrders || 0}
            // otherText="12"
          />
          <InfoCard
            icon={""}
            text={"Total Sales"}
            value={<CustomPriceDisplay price={singleVendor?.totalSales || 0} />}
            // otherText="12"
          />
          <InfoCard
            icon={""}
            text={"Total Payouts"}
            value={
              <CustomPriceDisplay price={singleVendor?.totalPayouts || 0} />
            }
          />
          <InfoCard
            icon={""}
            text={"Pending Payout"}
            value={
              <CustomPriceDisplay price={singleVendor?.pendingPayouts || 0} />
            }
          />
        </div>

        {/* Third section (Statistics and Traffic Source) */}
        <div className="flex mt-6 justify-between w-full">
          <div className="w-[68%]">
            <div className="shadow-sm p-4 bg-white rounded-md text-left">
              <div className="font-[500] text-[18px]">Products</div>
              <ProductsListing
                paginationData={{
                  setPageNumber: setProductPageNumber,
                  pageNumber: productPageNumber,
                  pageSize: productPageSize,
                }}
                totalRecords={products?.totalRecord ?? 0}
                products={products?.records}
              />
            </div>
            <div className="p-4 shadow-sm bg-white mt-6 rounded-md">
              <div className="flex justify-between">
                <div className="font-[500] text-[18px]">Payout History</div>
                {/* <div className="flex gap-2 items-center text-[14px] font-[400]">
                  <span>View more</span>
                  <span>
                    <IoIosArrowForward />
                  </span>
                </div> */}
              </div>

              {/* Table */}
              <PayoutsListing
                paginationData={{ setPageNumber, pageNumber, pageSize }}
                onRowClick={(id) => {
                  navigate(`/payouts/${id}`);
                }}
                payouts={vendorPayouts?.payouts}
                totalRecords={vendorPayouts?.totalRecord ?? 0}
              />
              {/* <div class="relative overflow-x-auto my-8">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <OrderTableHeaderList />
                  <tbody>
                    {orders_mock_data?.map((orderItem) => {
                      return <OrderRowItem order={orderItem} />;
                    })}
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
          <div className="w-[30%] ">
            <div className="shadow-sm p-4 bg-white rounded-md text-left">
              <AccountInfo
                {...{
                  imagePath: getFileUrl(logoImagePath),
                  ownerLabel: "Account Manager",
                  name: getFullName(
                    accountManagerInfo?.firstName,
                    accountManagerInfo?.lastName
                  ),
                  topText: businessName,
                  email: accountManagerInfo?.email,
                  phoneNumber: accountManagerInfo?.phoneNumber,
                  status: singleVendor?.verificationStatus,
                  address: getFullAddress(
                    businessDetails?.registeredAddress,
                    businessDetails?.region,
                    businessDetails?.state
                  ),
                }}
              />
            </div>
            <div className="shadow-sm p-4 bg-white rounded-md text-left mt-6">
              <VendorDocuments
                cacDocumentPath={businessDetails?.cacCertificateDocPath}
                taxDocumentPath={ownerDetails?.idFilePath}
              />
            </div>
            <div className="shadow-sm p-4 bg-white rounded-md text-left mt-6">
              <BankDetails {...bankDetails} />
            </div>
            <div className="shadow-sm p-4 bg-white rounded-md text-left mt-6">
              <VendorPaymentStructure
                onAdd={handleAddPaymentSplit}
                vendor={singleVendor}
                paymentSplit={paymentSplit}
              />
            </div>
            {/* <div className="shadow-sm p-4 bg-white rounded-md text-left mt-6">
              <VendorCommission />
            </div> */}
          </div>
        </div>
      </div>

      <ConfirmationModal
        title={"Review Vendor"}
        description={"You can approve or reject a vendor"}
        subText={
          "Approve a vendor if all details are correct. Otherwise, reject the vendor and provide some reason."
        }
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        {showReasonInput && (
          <>
            <Formik
              initialValues={{
                comment: "",
              }}
              validate={(values) => {
                const errors = {};
                setComment(values.comment);
                if (!values.comment) {
                  errors.comment = "Please enter reason(s) for rejection";
                }
                return errors;
              }}
            >
              <Form className="mt-6">
                <CustomTextField
                  name={"comment"}
                  label={"Rejection reason"}
                  placeholder="Please enter reason for rejection"
                  required
                />
              </Form>
            </Formik>
          </>
        )}
        {!showReasonInput && (
          <div className="my-5 flex justify-between gap-6">
            <RoundedMdButton
              label={"Reject"}
              onClick={() => {
                setShowReasonInput(true);
              }}
              className="bg-gray-200"
            />
            <RoundedMdButton
              label={"Approve"}
              onClick={() => {
                handleReview(true, { id: singleVendor?.id });
              }}
              className="bg-orange-400 text-white"
            />
          </div>
        )}
        {showReasonInput && (
          <div className="my-5 flex justify-between gap-6">
            <RoundedMdButton
              label={"Cancel"}
              onClick={() => {
                setShowReasonInput(false);
              }}
              className="bg-gray-200"
            />
            <RoundedMdButton
              label={"Reject"}
              onClick={() => {
                handleReview(true, { id: singleVendor?.id, comment });
              }}
              className="bg-orange-400 text-white"
            />
          </div>
        )}
      </ConfirmationModal>

      {InfoDisplayComponent}

      {isSubmitting && (
        <CustomBackdrop open={isSubmitting} text="Please wait..." />
      )}
    </Layout>
  );
}

export default VendorDetails;
