import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Layout from "../../commons/Layout";
import { useNavigate } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import useUser from "../../hooks/useUser";
import CustomBackdrop from "../../commons/CustomBackdrop";
import { removeIntendedRoute } from "../../local_store/intendedRouteLocalStore";
import * as userLocalStore from "../../local_store/userLocalStore";

function Logout() {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();

  const { user } = useUser();
  const { logUserOut } = useLogout();

  const logUserOutLocal = () => {
    const oldUser = user;
    userLocalStore.removeUser();

    logUserOut({
      id: oldUser?.id,
      email: oldUser?.email,
      guid: oldUser?.guid,
      accessToken: oldUser?.token,
    })
      .then((res) => {
        removeIntendedRoute();
      })
      .catch((err) => {})
      .finally(() => {
        navigate("/auth/login", { state: { user: -1 } });
      });
  };

  useEffect(() => {
    logUserOutLocal();
  }, []);

  return (
    <Layout>
      <CustomBackdrop open={open} text={"You will be logged out shortly..."} />
    </Layout>
  );
}

export default Logout;
