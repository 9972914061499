import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import {
  CURRENCY_SYMBOL,
  GENERAL_GRAY_HEX,
  INACTIVE,
} from "../../constants/texts";
import CustomExportButton from "../../commons/CustomExportButton";
import CustomButton from "../../commons/CustomButton";
import CardTitle from "../admin_commons/CardTitle";
import { Form, Formik } from "formik";
import StyledSpan from "../../commons/StyledSpan";
import CustomInputField from "../../commons/CustomInputField";
import { ACTIVE } from "./../../constants/texts";
import BankDetails from "./../admin_commons/BankDetails";
import AccountInfo from "../admin_commons/AccountInfo";
import CustomTextField from "../../commons/CustomTextField";
import useSinglePayout from "../../hooks/useSinglePayout";
import useUser from "../../hooks/useUser";
import { getFullName } from "../../helpers/extractors";
import { getDateTime } from "../../helpers/dateTime";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";
import CustomSelectField from "../../commons/CustomSelectField";
import { useState } from "react";
import CustomBackdrop from "../../commons/CustomBackdrop";
import useUpdatePayout from "../../hooks/useUpdatePayout";

function PayoutDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const { slug } = params;

  const { user } = useUser();
  const { singlePayout } = useSinglePayout({
    token: user?.token,
    payoutId: slug,
  });

  const { updateStatusUpdate } = useUpdatePayout();

  const [status, setStatus] = useState("");

  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdatePayoutStatus = (values) => {
    if (!values?.status) {
      setInfoDetails({ message: "Status should not be empty", isError: true });
      return;
    }
    setIsSubmitting(true);
    updateStatusUpdate({ ...values, token: user?.token })
      .then((res) => {
        setInfoDetails({ message: res?.data?.message, isError: false });
      })
      .catch((err) => {
        console.error("err updating status", err);
        setInfoDetails({ message: err?.message, isError: true });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const pageTitles = ["Payout Details", "Order Details", "Product Information"];

  const tableHeaders = [
    [
      { key: "id", label: "Transaction ID" },
      { key: "date", label: "Date" },
      { key: "status", label: "Status" },
      { key: "amount", label: "Amount" },
    ],
    [
      { key: "reference", label: "Reference" },
      { key: "quantity", label: "Quantity" },
      { key: "status", label: "Status" },
      { key: "subTotal", label: "Sub Total" },
      { key: "color", label: "Color" },
    ],
    [
      { key: "orderNumber", label: "Order Number" },
      { key: "paymentMethod", label: "Payment Method" },
      { key: "name", label: "Product Name" },
      { key: "price", label: "Product Price" },
      { key: "priceAfterDiscount", label: "Discounted Price" },
    ],
  ];

  const tableData = [
    {
      id: singlePayout?.id,
      date: getDateTime(singlePayout?.createdAt, "DD-MM-YYYY HH:mm A"),
      status: singlePayout?.status,
      amount: singlePayout?.amount,
    },
    {
      reference: singlePayout?.order?.reference,
      quantity: singlePayout?.order?.quantity,
      status: singlePayout?.order?.trackingStatus,
      subTotal: singlePayout?.order?.subTotalPrice,
      color: singlePayout?.order?.color,
    },
    {
      orderNumber: singlePayout?.order?.orderNumber,
      paymentMethod: singlePayout?.order?.paymentMethod,
      name: singlePayout?.order?.Product?.name,
      price: singlePayout?.order?.Product?.price,
      priceAfterDiscount: singlePayout?.order?.Product?.priceAfterDiscount,
    },
  ];

  const getPayoutDetails = (singlePayout) => {
    return {
      id: ["Payout Transaction", `#${singlePayout?.id}`],
      status: ["Payout Status", singlePayout?.status],
      amount: ["Payout Amount", singlePayout?.amount],
    };
  };

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        {/* First section */}
        <PageTitle title={"Payouts"} />
        <div className="flex justify-between">
          <PageBreadCrumb
            mainText="Dashboard"
            subText="Payout List"
            otherText="Payout Details"
            isActiveIndex={2}
          />

          <div className="flex gap-4">
            <CustomButton
              label="Back"
              onClick={() => navigate(-1)}
              className={`border border-[${GENERAL_GRAY_HEX}] text-[${GENERAL_GRAY_HEX}]`}
            />
            <CustomButton
              label="Save changes"
              onClick={() =>
                handleUpdatePayoutStatus({
                  id: singlePayout?.id,
                  status,
                })
              }
              className={`border bg-orange-400 border-[${GENERAL_GRAY_HEX}] text-[white]`}
            />
            <CustomExportButton
              pageTitles={pageTitles}
              tableHeaders={tableHeaders}
              tableData={tableData}
              dataObject={getPayoutDetails(singlePayout)}
              title={`Summary of ${singlePayout?.vendor?.businessName} Payout Transaction #${singlePayout?.id}`}
              fileName={`Summary of ${singlePayout?.vendor?.businessName} Payout Transaction #${singlePayout?.id}`}
            />
          </div>
        </div>

        {/* Second section */}
        <div className="mt-6 flex gap-4 w-full">
          {/* Left part */}
          <div className="w-full">
            <Formik
              enableReinitialize
              initialValues={{
                amount: `${CURRENCY_SYMBOL}${singlePayout?.amount}`,
                date: singlePayout?.createdAt
                  ? getDateTime(singlePayout?.createdAt, "DD-MM-YYYY HH:mm A")
                  : "",
                transactionNo: `#${singlePayout?.id}`,
                orderReference: `#${singlePayout?.order?.reference}`,
                payoutNote: "",
              }}
            >
              <Form>
                <div className="p-4 shadow-sm rounded-md w-full bg-white">
                  <div className="mt-3">
                    <CardTitle title={"Payout Details"} />
                    <CustomInputField
                      label={<span className="text-[12px]">{`Amount`}</span>}
                      readOnly={true}
                      disabled={true}
                      name="amount"
                    />
                    <CustomInputField
                      label={<span className="text-[12px]">{`Date`}</span>}
                      readOnly={true}
                      disabled={true}
                      name="date"
                    />
                    <CustomInputField
                      label={
                        <span className="text-[12px]">{`Transaction No.`}</span>
                      }
                      readOnly={true}
                      disabled={true}
                      name="transactionNo"
                    />
                    <CustomInputField
                      label={
                        <span className="text-[12px]">{`Order Reference`}</span>
                      }
                      readOnly={true}
                      disabled={true}
                      name="orderReference"
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>

          {/* Right part */}
          <div className="w-[45%]">
            <div className="p-4 shadow-sm rounded-md bg-white mb-5">
              <div className="flex justify-between items-center">
                <CardTitle title={"Payout Status"} />
                <StyledSpan text={singlePayout?.status} />
              </div>
              <div className="mt-3">
                <Formik
                  enableReinitialize
                  initialValues={{
                    id: singlePayout?.id,
                    status: singlePayout?.status,
                  }}
                >
                  {({ values, setFieldValue, errors }) => {
                    return (
                      <Form>
                        <CustomSelectField
                          name="status"
                          list={["Pending", "Paid"]}
                          placeholder="Select status"
                          label="Status"
                          selectedValue={values?.status}
                          onChange={(value) => {
                            setFieldValue("status", value);
                            setStatus(value);
                          }}
                        />
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>

            <div className="p-4 shadow-sm rounded-md bg-white mb-5">
              <BankDetails {...singlePayout?.vendor?.VendorBankDetail} />
            </div>
            <div className="p-4 shadow-sm rounded-md bg-white">
              <AccountInfo
                ownerLabel="Account Manager"
                status={
                  singlePayout?.vendor?.status == true ? ACTIVE : INACTIVE
                }
                topText={singlePayout?.vendor?.businessName}
                name={getFullName(
                  singlePayout?.vendor?.firstName,
                  singlePayout?.vendor?.lastName
                )}
                email={singlePayout?.vendor?.email}
                address={singlePayout?.vendor?.address}
                phoneNumber={singlePayout?.vendor?.phoneNumber}
              />
            </div>
          </div>
        </div>
      </div>
      {InfoDisplayComponent}

      {isSubmitting && (
        <CustomBackdrop open={isSubmitting} text="Please wait..." />
      )}
    </Layout>
  );
}

export default PayoutDetails;
