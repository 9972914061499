import React from "react";
import "./css/login.css";
import { useLocation } from "react-router-dom";
import useUser from "../hooks/useUser";
import LoginUserComponent from "./LoginUserComponent";

function TopHeader() {

  const location = useLocation();

  const { user } = useUser();


  const isHomePage = () => {
    if (location.pathname === "/") {
      return true;
    }

    return false;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div
        className="flex justify-between shadow-sm border px-10 items-center py-4 fixed  top-0 w-full bg-white"
        style={{ zIndex: "10000" }}
      >
        {/* Left */}
        <div className="flex gap-10 items-center w-1/3">
          {isHomePage() && (
            <div>
              <img
                alt="Wudify-logo"
                src="/assets/wudify_logo_footer.png"
                loading="lazy"
                className="cursor-pointer w-[8em] h-[3.5em]"
                onClick={scrollToTop}
                style={{ objectFit: "contain" }}
              />
            </div>
          )}
          {!isHomePage() && (
            <a href="/">
              <div>
                <img
                  alt="Wudify-logo"
                  src="/assets/wudify_logo_footer.png"
                  loading="lazy"
                  style={{ objectFit: "contain" }}
                  className="w-[8em] h-[3.5em]"
                />
              </div>
            </a>
          )}
        </div>

        {/* Right */}
        <div className="w-1/3 flex gap-4 justify-end text-[15px] font-[500]">
          <LoginUserComponent user={user} />
        </div>
      </div>
    </>
  );
}

export default TopHeader;
