import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import CustomButton from "../../commons/CustomButton";
import CardTitle from "../admin_commons/CardTitle";
import CustomIconLabelValue from "../../commons/CustomIconLabelValue";
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineEmail } from "react-icons/md";
import { GiSmartphone } from "react-icons/gi";
import { Form, Formik } from "formik";
import CustomSelectField from "./../../commons/CustomSelectField";
import StyledSpan from "../../commons/StyledSpan";
import CustomInputField from "../../commons/CustomInputField";
import CustomTextField from "./../../commons/CustomTextField";
import useUser from "../../hooks/useUser";
import useSingleTicket from "../../hooks/useSingleTicket";
import { getTicketStatusName } from "./helpers";
import useUpdateTicketStatus from "../../hooks/useUpdateTicketStatus";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";
import CustomBackdrop from "../../commons/CustomBackdrop";
import { delayAction } from "../../helpers/events";
import { getDateTime } from "../../helpers/dateTime";
import DownloadIcon from "../../commons/DownloadIcon";
import { getFileUrl } from "../../helpers/extractors";

function SupportTicketDetails() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState("");

  const params = useParams();
  const { slug } = params;
  const { user } = useUser();

  const { singleTicket } = useSingleTicket({
    accessToken: user?.token,
    ticketId: slug,
  });

  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();

  const { updateStatusOfTicket } = useUpdateTicketStatus();

  const handleDownload = (filePath) => {

    const link = document.createElement('a');
    link.href = getFileUrl("1723370722757-1717708192938-bedroom_furniture.png");
    link.setAttribute(
      'download',
      `FileName.`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);

  }

  const handleUpdateTicketStatus = (values) => {
    if (values?.status == "") {
      setInfoDetails({ message: "Status should not be empty", isError: true });
      return;
    }
    setIsSubmitting(true);
    updateStatusOfTicket({ ...values, token: user?.token })
      .then((res) => {
        setInfoDetails({ message: res?.data?.message, isError: false });
        delayAction(() => {
          navigate("/support-tickets");
        });
      })
      .catch((err) => {
        console.error("err updating status", err);
        setInfoDetails({ message: err?.message, isError: true });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    setStatus(singleTicket?.status);
  }, [singleTicket]);

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        {/* First section */}
        <PageTitle title={"Support Ticket"} />
        <div className="flex justify-between">
          <PageBreadCrumb
            mainText="Dashboard"
            subText="Support Tickets List"
            otherText="Support Ticket Details"
            isActiveIndex={2}
          />

          <div className="flex gap-4">
            <CustomButton
              label="Back"
              onClick={() => navigate(-1)}
              className={`border border-[${GENERAL_GRAY_HEX}] text-[${GENERAL_GRAY_HEX}]`}
            />
            <CustomButton
              label="Save changes"
              className={`border bg-orange-400 border-[${GENERAL_GRAY_HEX}] text-[white]`}
              onClick={() =>
                handleUpdateTicketStatus({
                  status,
                  token: user?.token,
                  id: singleTicket?.id,
                })
              }
            />
          </div>
        </div>

        {/* First section */}

        <div className="mt-6 flex gap-4 w-full">
          <div className="w-[67%] p-4 shadow-sm rounded-md w-full bg-white">
            <CardTitle title={"Message"} />
            <div className="mt-3"></div>
            <Formik
              enableReinitialize
              initialValues={{
                message: singleTicket?.description ?? "",
                subject: singleTicket?.subject ?? "",
              }}
            >
              <Form>
                <CustomInputField name="subject" label="Subject" readOnly />
                <CustomTextField
                  rows={10}
                  name="message"
                  label="Message"
                  readOnly
                />
              </Form>
            </Formik>
            {singleTicket?.filePath && <div className="flex items-center gap-5">
              <div>
                <img src="/assets/document_download_icon.png" style={{ objectFit: "contain" }} />
              </div>
              <div>
                <div className="font-[600] text-[14px]">{"Document"}</div>
                <div className="font-[400] text-[14px] text-[#828282]">{getDateTime(singleTicket?.createdAt, "DD-mm-YYYY HH:mm A")}</div>
              </div>
              <div className="ml-5">
                {/* <div className="" onClick={() => handleDownload(singleTicket?.filePath)}>
                  <DownloadIcon className={"text-[30px] text-[#828282] cursor-pointer"} />
                </div> */}
                <a target="_blank" href={getFileUrl(singleTicket?.filePath)} download className="" onClick={() => handleDownload(singleTicket?.filePath)}>
                  <DownloadIcon className={"text-[30px] text-[#828282] cursor-pointer"} />
                </a>
              </div>
            </div>}
          </div>
          <div className="w-[33%]">
            <div className="p-4 shadow-sm rounded-md w-full bg-white">
              <div className="flex justify-between items-center">
                <CardTitle title={"Priority"} />
                <StyledSpan text={"Pending"} />
              </div>
              <div className="mt-3">
                <Formik
                  initialValues={{
                    priority: "",
                  }}
                >
                  <Form>
                    <CustomSelectField
                      name="priority"
                      list={["High", "Medium", "Low"]}
                      placeholder="Select priority"
                      label="Select priority"
                      onChange={() => { }}
                    />
                  </Form>
                </Formik>
              </div>
            </div>
            <div className="p-4 mt-4 shadow-sm rounded-md w-full bg-white">
              <div className="flex justify-between items-center">
                <CardTitle title={"Status"} />
                <StyledSpan text={getTicketStatusName(singleTicket?.status)} />
              </div>
              <div className="mt-3">
                <Formik
                  enableReinitialize
                  initialValues={{
                    status: singleTicket?.status,
                  }}
                >
                  {({ values, setFieldValue }) => {
                    return (
                      <Form>
                        <CustomSelectField
                          name="status"
                          list={[
                            { label: "Open", value: true },
                            { label: "Closed", value: false },
                          ]}
                          placeholder="Select status"
                          label="Change to:"
                          valueKey="value"
                          keyValue="label"
                          selectedValue={values?.status}
                          onChange={(value) => {
                            setFieldValue("status", value);
                            setStatus(value);
                          }}
                        />
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
            <div className="p-4 mt-4 shadow-sm rounded-md w-full bg-white">
              <CardTitle title={"Customer Details"} />
              <div className="mt-3">
                <CustomIconLabelValue
                  icon={<AiOutlineUser className="text-[16px]" />}
                  label="Name"
                  value={singleTicket?.fullName}
                />
                <CustomIconLabelValue
                  icon={<MdOutlineEmail className="text-[16px]" />}
                  label="Email"
                  value={singleTicket?.email}
                />
                <CustomIconLabelValue
                  icon={<GiSmartphone className="text-[16px]" />}
                  label="Phone"
                  value={singleTicket?.phoneNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {InfoDisplayComponent}
      {isSubmitting && <CustomBackdrop open={isSubmitting} />}
    </Layout>
  );
}

export default SupportTicketDetails;
