import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { ORDER_QUERY_KEY } from "../constants/queryKeys";
import { fetchOrderById } from "../services/order-services";

function useOrder(user, id) {
  const navigate = useNavigate();
  const {
    data: order,
    isLoading,
    error,
  } = useQuery({
    queryKey: [ORDER_QUERY_KEY, id],
    queryFn: async () => {
      const result = await fetchOrderById(user, id);
      return result?.data?.data;
    },
    enabled: id != null,
    onError: (error) => {
      // return error
    },
  });
  return {
    order,
    isLoading,
    error,
  };
}

export default useOrder;
