import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  SUPPORT_TICKETS_QUERY_KEY,
  SINGLE_TICKET,
} from "../constants/queryKeys";
import { updateTicketStatus } from "../services/contact-requests-services";

function useUpdateTicketStatus() {
  const queryClient = useQueryClient();
  const {
    mutateAsync: updateStatusOfTicket,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (ticketDetails) => updateTicketStatus(ticketDetails),
    onSuccess: (data) => {
      queryClient.invalidateQueries([SINGLE_TICKET, SUPPORT_TICKETS_QUERY_KEY]);
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    updateStatusOfTicket,
    isLoading,
    error,
    data,
  };
}

export default useUpdateTicketStatus;
