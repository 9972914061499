import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import useRouting from "./hooks/useRouting";
import { decryptData } from "./helpers/encryptor";

function App() {
  // Create a client
  const queryClient = new QueryClient();

  const { routeList } = useRouting();

  const router = createBrowserRouter(routeList);

  const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

  return (
    <div className="App">
      <GoogleOAuthProvider clientId={clientId}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router}></RouterProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
