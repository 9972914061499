import React from "react";
import SmallHeadingTitle from "./SmallHeadingTitle";

function HeadingAndDetails({
  title,
  description = "Kindly complete the form below",
}) {
  return (
    <div>
      <SmallHeadingTitle title={title} />
      <p className="text-left text-[#828282] text-[14px] ">
        {description}
      </p>
    </div>
  );
}

export default HeadingAndDetails;
