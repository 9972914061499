import * as React from "react";
import { Gauge } from "@mui/x-charts/Gauge";
import gaugeClasses from "./../../node_modules/@mui/x-charts/esm/Gauge/gaugeClasses";

export default function CustomProgressArc({ value = "15.5", color = "blue" }) {
  return (
    <>
      <Gauge
        value={value}
        startAngle={-90}
        endAngle={90}
        innerRadius="89%"
        outerRadius="100%"
        cornerRadius="50%"
        className={"bg-red-100"}
        classes="bg-yellow-200"
        color={"success"}
        sx={(theme) => ({
          [`& .${gaugeClasses.valueText}`]: {
            fontSize: 20,
            color: "red",
          },
          [`& .${gaugeClasses.valueArc}`]: {
            fill: color,
            // fill: '#52b202',
          },
          [`& .${gaugeClasses.referenceArc}`]: {
            fill: theme.palette.text.disabled,
          },
        })}
      />
    </>
  );
}
