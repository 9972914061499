import React from "react";
import { useNavigate } from "react-router-dom";
import UserTableHeaderList from "./UserTableHeaderList";
import UserRowItem from "./UserRowItem";
import CustomTable from "../admin_commons/CustomTable";

function UsersListing({
  users,
  user = null,
  onRowClick,
  isLoading,
  totalRecords,
  tableMinWidth = "",
}) {
  const navigate = useNavigate();
  return (
    <CustomTable
      user={user}
      onRowClick={onRowClick}
      totalRecords={totalRecords}
      isLoading={isLoading}
      HeaderComponent={UserTableHeaderList}
      data={users}
      valueKey="guid"
      RowItemComponent={UserRowItem}
    />
  );
}

export default UsersListing;
