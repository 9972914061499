import React, { useEffect, useState } from "react";
import Layout from "../../commons/Layout";
import PageTitle from "./../../commons/PageTitle";
import InfoCard from "./InfoCard";
import CustomPriceDisplay from "../../commons/CustomPriceDisplay";
import { LineChart } from "@mui/x-charts/LineChart";
import { IoIosArrowForward } from "react-icons/io";
import { orders_mock_data } from "../../mock_data/orders";
import CustomProgressLine from "../../commons/CustomProgressLine";
import { getMaxValue } from "../../helpers/extractors";
import CustomCardWithProgressArc from "../../commons/CustomCardWithProgressArc";
import CustomCardWithPieChart from "../../commons/CustomCardWithPieChart";
import { dashboardDropdownFilters, trafficSourceData } from "./data";
import CustomTopInfoFilterCard from "../../commons/CustomTopInfoFilterCard";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import CustomDateFilterTabs from "./../../commons/CustomDateFilterTabs";
import CustomExportButton from "./../../commons/CustomExportButton";
import OrdersListing from "../admin_orders/OrdersListing";
import useDatePicker from "./../../hooks/useDatePicker";
import {
  getCurrentMonth,
  getCurrentWeek,
  getCurrentYear,
  getDateTime,
} from "./../../helpers/dateTime";
import useUser from "../../hooks/useUser";
import useDashboardData from "../../hooks/useDashboardData";
import CustomBackdrop from "../../commons/CustomBackdrop";
import useAllOrders from "../../hooks/useAllOrders";
import { useNavigate } from "react-router-dom";

const tabItems = ["All", "Today", "This Week", "This Month", "This Year"];

function Dashboard() {
  const { user } = useUser();
  const navigate = useNavigate()

  const [selectedTab, setSelectedTab] = useState(tabItems[0]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [filterValue, setFilterValue] = useState(1);
  const { dashboardData, isLoading } = useDashboardData(
    {
      accessToken: user?.token,
      selectedDate,
    },
    selectedDate
  );
  const { orderItems, isLoading: ordersLoading } = useAllOrders(
    user,
    4,
    1,
    "",
    ["", ""]
  );

  const dateFormat = "YYYY-MM-DD";

  const { DatePickerComponent, startDate, endDate, DateDisplay } =
    useDatePicker(() => setSelectedTab(""), dateFormat, getDateRangeText());

  function getDateRangeText() {
    if (selectedTab == tabItems[0]) {
      return "all time";
    }

    if (selectedDate?.length == 2) {
      return selectedDate[0].toString() + " to " + selectedDate[1].toString();
    }

    return "all time";
  }

  const getDateRange = (text) => {
    if (text.includes(tabItems[0])) {
      return getCurrentYear(null, dateFormat);
    }
    if (text.includes(tabItems[1])) {
      const d = getDateTime(new Date(), dateFormat);
      return [d, d];
    }
    if (text.includes(tabItems[2])) {
      return getCurrentWeek(null, dateFormat);
    }
    if (text.includes(tabItems[3])) {
      return getCurrentMonth(null, dateFormat);
    }
    if (text.includes(tabItems[4])) {
      return getCurrentYear(null, dateFormat);
    }
  };

  const getDataDetails = (chartData) => {
    const payoutsData = [];
    const salesData = [];
    (chartData || []).forEach((item) => {
      payoutsData.push(item?.payouts);
      salesData.push(item?.sales);
    });

    return [payoutsData, salesData];
  };

  const getTopDataFromFilter = (data, filterValue) => {
    if (filterValue == 1) {
      return data?.topProducts?.map((d) => {
        return {
          imagePath: d?.imageOne,
          name: d?.name,
          amount: d?.priceAfterDiscount,
        };
      });
    }
    if (filterValue == 2) {
      return data?.topVendors?.map((d) => {
        return {
          imagePath: d?.logoImagePath,
          name: d?.businessName,
          amount: d?.priceAfterDiscount,
        };
      });
    }
    if (filterValue == 3) {
      return [];
    }
  };

  useEffect(() => {
    setSelectedDate([startDate, endDate]);
  }, [startDate, endDate]);

  useEffect(() => {
    setSelectedDate(getDateRange(selectedTab));
  }, [selectedTab]);

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="p-6">
        {/* Top */}
        <div className="flex justify-between  items-start">
          <PageTitle title={`Welcome, ${user?.firstName}`} />
          <div className={`flex gap-4 text-[${GENERAL_GRAY_HEX}] text-[14px]`}>
            <CustomDateFilterTabs
              tabs={tabItems}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            {DatePickerComponent}
            <CustomExportButton />
          </div>
        </div>

        {/* Cards */}
        <div className="flex gap-4 mt-6">
          <InfoCard
            icon={""}
            text={"Total Products"}
            value={dashboardData?.products?.count}
            otherText={dashboardData?.products?.changePercentage}
          />

          <InfoCard
            icon={""}
            text={"Total Vendors"}
            value={dashboardData?.vendors?.count}
            otherText={dashboardData?.vendors?.changePercentage}
          />

          <InfoCard
            icon={""}
            text={"Total Orders"}
            value={dashboardData?.orders?.count}
            otherText={dashboardData?.orders?.changePercentage}
          />

          <InfoCard
            icon={""}
            text={"Total Sales"}
            value={
              <CustomPriceDisplay
                price={dashboardData?.sales?.total}
              />
            }
            otherText={dashboardData?.sales?.changePercentage}
          />
        </div>

        {DateDisplay}

        {/* Third section (Statistics and Traffic Source) */}
        <div className="flex gap-4 mt-2">
          <div className="w-[68%] shadow-sm p-4 bg-white rounded-md text-left">
            <div className="font-[500] text-[18px]">Statistics</div>
            <div
              className={`font-[400] text-[16px] text-[${GENERAL_GRAY_HEX}]`}
            >
              Payouts and Sales
            </div>
            <div>
              <LineChart
                xAxis={[
                  {
                    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    label: "Months",
                  },
                ]}
                series={[
                  {
                    data: getDataDetails(dashboardData?.chartData)[0],
                    label: "Sales",
                  },
                  {
                    data: getDataDetails(dashboardData?.chartData)[1],
                    label: "Payouts",
                  },
                ]}
                // width={700}
                height={300}
              />
            </div>
          </div>
          <div className="w-[32%] shadow-sm p-4 bg-white rounded-md text-left">
            <div className="font-[500] text-[18px]">Traffic Source</div>
            <div className="mt-8 flex flex-col gap-6">
              {trafficSourceData.data.map((source) => {
                return (
                  <CustomProgressLine
                    max={getMaxValue(trafficSourceData.data, "value")}
                    type={trafficSourceData.type}
                    label={source.label}
                    value={source.value}
                  />
                );
              })}
            </div>
          </div>
        </div>

        {/* Fourth section (Cart abandonment rate, e.t.c) */}
        <div className="flex gap-4 mt-6">
          {/* Card one */}
          <CustomCardWithProgressArc
            title="Cart Abandonment Rate"
            description="Your cart abandonment rate is reducing, keep up the good work."
            arcValue={"15.5"}
            arcColor="#883DCF"
            infoText1={"Total Purchase"}
            infoText2={"Total Cart Created"}
            infoValue1={"270"}
            infoValue2={"250"}
          />

          {/* Card two */}
          <CustomCardWithProgressArc
            title="Conversion Rate"
            description="Your conversion rate is increasing, keep up the good work."
            arcValue={"75.55"}
            arcColor="#40F530"
            infoText1={"Total Visitors"}
            infoText2={"Total Sales"}
            infoValue1={"10,270"}
            infoValue2={"125"}
          />

          {/* Card three */}
          <CustomCardWithPieChart title="Retention/Churn Rate" />

          {/* Card four */}
          <CustomTopInfoFilterCard
            onFilterChange={setFilterValue}
            filters={dashboardDropdownFilters}
            data={getTopDataFromFilter(dashboardData, filterValue)}
          />
        </div>

        {/* Fifth section (Recent Orders) */}
        <div className="p-4 shadow-sm bg-white mt-6 rounded-md">
          <div className="flex justify-between">
            <div className="font-[500] text-[18px]">Recent Orders</div>
            <div className="flex gap-2 items-center text-[14px] font-[400] cursor-pointer">
              <span onClick={() => navigate("/orders")}>View more</span>
              <span>
                <IoIosArrowForward />
              </span>
            </div>
          </div>

          <OrdersListing onRowClick={() => { }}
            totalRecords={orderItems?.totalRecords}
            isLoading={ordersLoading}
            orders={orderItems?.orders} />
        </div>
      </div>

      {isLoading && <CustomBackdrop open={isLoading} text="Please wait..." />}
    </Layout>
  );
}

export default Dashboard;
