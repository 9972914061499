import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { LOGISTICS_PARTNERS_QUERY_KEY } from "../constants/queryKeys";
import { getLogisticsPartner } from "../services/logistics-partners-service";

function useLogisticsPartners(data) {
  const navigate = useNavigate();
  const {
    data: logisticsPartnersDetails,
    isLoading,
    error,
  } = useQuery({
    queryKey: [LOGISTICS_PARTNERS_QUERY_KEY, data],
    queryFn: async () => {
      const result = await getLogisticsPartner(data);
      return result?.data?.data;
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    logisticsPartnersDetails,
    isLoading,
    error,
  };
}

export default useLogisticsPartners;
