import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { SINGLE_TICKET } from "../constants/queryKeys";
import { getSupportTicket } from "../services/contact-requests-services";

function useSingleTicket({ ticketId, token }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    data: singleTicket,
    isLoading,
    error,
  } = useQuery({
    queryKey: [SINGLE_TICKET, ticketId],
    queryFn: async () => {
      const result = await getSupportTicket({
        id: ticketId,
        accessToken: token,
      });
      return result?.data?.data;
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    singleTicket,
    isLoading,
    error,
  };
}

export default useSingleTicket;
