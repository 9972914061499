import { useQuery } from "@tanstack/react-query";
import { CUSTOMER_ORDER_ITEMS_QUERY_KEY } from "../constants/queryKeys";
import { fetchCustomerOrderItems } from "../services/order-services";

function useCustomerOrders(id, pageSize, pageNumber, token) {
  const {
    data: userOrderItems,
    isLoading,
    error,
  } = useQuery({
    queryKey: [CUSTOMER_ORDER_ITEMS_QUERY_KEY, pageSize, pageNumber],
    queryFn: async () => {
      const result = await fetchCustomerOrderItems(
        id,
        pageSize,
        pageNumber,
        token
      );
      return result?.data?.data;
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    userOrderItems,
    isLoading,
    error,
  };
}

export default useCustomerOrders;
