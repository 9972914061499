import React from "react";
import Layout from "../../commons/Layout";
import CardTitleBig from "../../commons/PageTitleBig";
import CustomInputField from "../../commons/CustomInputField";
import RoundedMdButton from "../../commons/RoundedMdButton";
import { Form, Formik } from "formik";

function ResetPassword() {
  return (
    <Layout bgClassName="bg-gray-100" showSideBar={false}>
      <div className="flex items-center py-20">
        <div className="shadow-md rounded-lg  bg-white p-6 w-[32%] mx-auto">
          <CardTitleBig title="Reset your password" />
          <p className="text-[#667185] text-[14px] font-[500] mb-5">
            Enter your login email and we’ll send you a link to reset your
            password.
          </p>

          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {

              

             }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email is required";
              }
              return errors;
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <CustomInputField
                  label={"Email Address"}
                  type="email"
                  placeholder="Please enter your email address"
                  name="email"
                />
                <RoundedMdButton
                  label="Reset password"
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-[#FF9D21] text-white hover:bg-orange-600"
                ></RoundedMdButton>
                <a href="/auth/login">
                  <RoundedMdButton
                    label="Cancel"
                    disabled={isSubmitting}
                    className="bg-white text-black border hover:text-[#FF9D21]"
                  ></RoundedMdButton>
                </a>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
}

export default ResetPassword;
