import React from "react";
import CardTitle from "../CardTitle";
import GeneralModal from "../GeneralModal";

function ConfirmationModal({
  isOpen,
  type = "",
  onClose,
  showCloseButton = true,
  widthClass = "w-[40%]",
  children,
  title,
  description,
  subText,
}) {
  return (
    <GeneralModal
      isOpen={isOpen}
      widthClass={widthClass}
      showCloseButton={showCloseButton}
      onClose={onClose}
    >
      {type === "warning" && (
        <div className="text-left w-[48px] h-[48px] mb-4">
          <img src="/assets/error-info-icon.png" alt="" />
        </div>
      )}
      <CardTitle title={title} className="text-left mb-4" />
      <div className="text-left">
        <p className="text-[16px] font-[500] my-4">{description}</p>
        <p className="text-[#828282] text-[16px] font-[500] text-left">
          {subText}
        </p>
      </div>
      {children}
    </GeneralModal>
  );
}

export default ConfirmationModal;
