import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { SINGLE_PAYOUT } from "../constants/queryKeys";
import { getSupportTicket } from "../services/contact-requests-services";
import { getPayout } from "../services/payouts-services";

function useSinglePayout({ payoutId, token }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    data: singlePayout,
    isLoading,
    error,
  } = useQuery({
    queryKey: [SINGLE_PAYOUT, payoutId],
    queryFn: async () => {
      const result = await getPayout({
        id: payoutId,
        accessToken: token,
      });
      return result?.data;
    },
    enabled: payoutId != null,
    onError: (error) => {
      // return error
    },
  });
  return {
    singlePayout,
    isLoading,
    error,
  };
}

export default useSinglePayout;
