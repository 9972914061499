import React from 'react'
import { useNavigate } from 'react-router-dom';
import CustomTable from '../admin_commons/CustomTable';
import LogisticsPartnerTableHeaderList from './LogisticsPartnerTableHeaderList';
import LogisticsPartnerRowItem from './LogisticsPartnerRowItem';

function LogisticsPartnersListing({ partners, user = null, onRowClick, isLoading, totalRecords, tableMinWidth = "" }) {
    const navigate = useNavigate()
    return (
        <CustomTable user={user} onRowClick={onRowClick} totalRecords={totalRecords} isLoading={isLoading} HeaderComponent={LogisticsPartnerTableHeaderList} data={partners} RowItemComponent={LogisticsPartnerRowItem} />
    )
}

export default LogisticsPartnersListing