import React, { useState } from "react";
import Layout from "../../commons/Layout";
import CardTitleBig from "../../commons/PageTitleBig";
import CustomInputField from "../../commons/CustomInputField";
import RoundedMdButton from "../../commons/RoundedMdButton";
import { Formik, Form } from "formik";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import useLogin from "./../../hooks/useLogin";
import useCustomInfoDisplay from "./../../hooks/useCustomInfoDisplay";
import { useNavigate } from "react-router-dom";
import CustomBackdrop from "../../commons/CustomBackdrop";
import { delayAction } from "../../helpers/events";

function Login() {
  const navigate = useNavigate();

  const { logAdminIn } = useLogin();
  const [isLoading, setIsLoading] = useState(false);
  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();

  return (
    <Layout bgClassName="bg-gray-100" showSideBar={false}>
      <div className="flex items-center py-10 w-full mt-12">
        <div className="shadow-md rounded-lg  bg-white p-6 w-[35%] mx-auto">
          <CardTitleBig title="Sign in to your account" />{" "}
          <Formik
            initialValues={{
              login: "",
              password: "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setIsLoading(true);
              logAdminIn({
                login: values.login,
                password: values.password,
              })
                .then((res) => {
                  setInfoDetails({
                    message: "Login successful",
                    isError: false,
                  });
                  resetForm();
                  delayAction(() => {
                    window.location.reload();
                  }, 10)
                  
                })
                .catch((err) => {
                  setInfoDetails({ message: err?.message, isError: true });
                })
                .finally(() => {
                  setSubmitting(false);
                  setIsLoading(false);
                });
            }}
            validate={(values) => {
              const errors = {};
              if (!values.login) {
                errors.login = "Email or phone is required";
              }
              if (!values.password) {
                errors.password = "Password is required";
              }
              return errors;
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <CustomInputField
                  label={"Email Address or Phone"}
                  type="text"
                  name="login"
                  placeholder="Please enter your email address or phone"
                  disabled={isSubmitting}
                />
                <CustomInputField
                  label={"Password"}
                  type="password"
                  name="password"
                  placeholder="Please enter your password"
                  disabled={isSubmitting}
                />
                <RoundedMdButton
                  label={isSubmitting ? "Please wait" : "Sign in"}
                  className="bg-[#FF9D21] text-white hover:bg-orange-600"
                  disabled={isSubmitting}
                  type="submit"
                  left={false}
                >
                  {isSubmitting && (
                    <CustomThreeDotsSpinner
                      height="20"
                      width="20"
                      color="white"
                    />
                  )}
                </RoundedMdButton>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {InfoDisplayComponent}
      {isLoading && <CustomBackdrop open={isLoading} text="Please wait..." />}
    </Layout>
  );
}

export default Login;
