import React, { useEffect, useState } from "react";

function CheckboxItem({
  label,
  value,
  onClick,
  selected,
  padding = "p-4",
  boxLeft = false,
  disabled = false,
}) {
  return (
    <div
      onClick={disabled ? () => { } : () => onClick(value)}
      className={`${padding} rounded-lg border w-full flex ${boxLeft ? "flex-row-reverse" : ""
        } items-center ${boxLeft ? "justify-end" : "justify-start"
        } gap-4 border-[1.5px] ${disabled ? "" : "cursor-pointer hover:border-[#318EC9]"} ${selected ? "border-[#318EC9]" : ""
        }`}
    >
      <div className="font-[500] text-[17px] text-[#344054]" style={{ lineHeight: "14px" }}>{label}</div>
      <div className="flex items-center">
        <input
          type="checkbox"
          checked={selected}
          style={{ height: "1.5em", width: "1.5em" }}
        />
      </div>
    </div>
  );
}

export default CheckboxItem;
