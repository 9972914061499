import React from "react";
import { VscArrowSmallUp } from "react-icons/vsc";
import { VscArrowSmallDown } from "react-icons/vsc";
import { GENERAL_GRAY_HEX } from "../../constants/texts";


function InfoCard({
  text,
  value,
  otherText = "",
  icon,
  className = "bg-white shadow-sm",
}) {
  return (
    <div
      className={`${className} flex items-center justify-between p-4 rounded-md w-full border`}
    >
      <div className="flex flex-col justify-between gap-3 items-start w-full">
        <p className={`font-[400] text-[13px] text-[${GENERAL_GRAY_HEX}]`}>{text}</p>
        <div className="flex justify-between items-center w-full">
          <p className="font-[600] text-[20px]">{value}</p>
          {otherText && (
            <p className="text-[#008000] font-[500] text-[12px] flex items-center gap-0.5">
              <span>+</span>
              <span>{otherText}%</span>
              <span>
                <VscArrowSmallUp />
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default InfoCard;
