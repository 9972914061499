import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const getDashboardData = async (data, filter) => {
  let url = "/dashboard";
  if (filter) {
    url += `?startDate=${filter[0]}&endDate=${filter[1]}`;
  }
  return axiosInstance(url, "GET", data, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};
