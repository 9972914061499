import React, { useState } from 'react'
import { MdOutlineStarPurple500 } from "react-icons/md";

function ReviewStar({ value, adjustable = false, small = false,  onChange }) {
    
    const [hoveringValue, setHoveringValue] = useState(value);
    return (
        <>
            {!adjustable && <div className="flex gap-1 item-center mb-2 ">
                <MdOutlineStarPurple500 className={`${value >= 1 ? 'text-[#FF9D21]' : ''}`} style={{ fontSize: small ? "14px" : "24px" }} />
                <MdOutlineStarPurple500 className={`${value >= 2 ? 'text-[#FF9D21]' : ''}`} style={{ fontSize: small ? "14px" : "24px" }} />
                <MdOutlineStarPurple500 className={`${value >= 3 ? 'text-[#FF9D21]' : ''}`} style={{ fontSize: small ? "14px" : "24px" }} />
                <MdOutlineStarPurple500 className={`${value >= 4 ? 'text-[#FF9D21]' : ''}`} style={{ fontSize: small ? "14px" : "24px" }} />
                <MdOutlineStarPurple500 className={`${value >= 5 ? 'text-[#FF9D21]' : ''}`} style={{ fontSize: small ? "14px" : "24px" }} />
            </div>}
            {adjustable && <div className="flex gap-1 item-center mb-2 ">
                <MdOutlineStarPurple500 onMouseEnter={() => setHoveringValue(1)} onMouseLeave={() => setHoveringValue(0)} onClick={() => onChange(1)} className={`cursor-pointer ${(value >= 1) || (hoveringValue >= 1) ? 'text-[#FF9D21]' : ''}`} style={{ fontSize: small ? "14px" : "24px" }} />
                <MdOutlineStarPurple500 onMouseEnter={() => setHoveringValue(2)} onMouseLeave={() => setHoveringValue(0)} onClick={() => onChange(2)} className={`cursor-pointer ${(value >= 2) || (hoveringValue >= 2) ? 'text-[#FF9D21]' : ''}`} style={{ fontSize: small ? "14px" : "24px" }} />
                <MdOutlineStarPurple500 onMouseEnter={() => setHoveringValue(3)} onMouseLeave={() => setHoveringValue(0)} onClick={() => onChange(3)} className={`cursor-pointer ${(value >= 3) || (hoveringValue >= 3) ? 'text-[#FF9D21]' : ''}`} style={{ fontSize: small ? "14px" : "24px" }} />
                <MdOutlineStarPurple500 onMouseEnter={() => setHoveringValue(4)} onMouseLeave={() => setHoveringValue(0)} onClick={() => onChange(4)} className={`cursor-pointer ${(value >= 4) || (hoveringValue >= 4) ? 'text-[#FF9D21]' : ''}`} style={{ fontSize: small ? "14px" : "24px" }} />
                <MdOutlineStarPurple500 onMouseEnter={() => setHoveringValue(5)} onMouseLeave={() => setHoveringValue(0)} onClick={() => onChange(5)} className={`cursor-pointer ${(value >= 5) || (hoveringValue >= 5) ? 'text-[#FF9D21]' : ''}`} style={{ fontSize: small ? "14px" : "24px" }} />
            </div>}
        </>
    )
}

export default ReviewStar