import React from "react";
import CustomTable from "../admin_commons/CustomTable";
import CustomerRowItem from "./CustomerRowItem";
import CustomerTableHeaderList from "./CustomerTableHeaderList";

function CustomersListing({
  customers,
  onRowClick,
  isLoading = false,
  totalRecords = 0,
  paginationData,
}) {
  return (
    <>
      <CustomTable
        paginationData={paginationData}
        onRowClick={onRowClick}
        totalRecords={totalRecords}
        isLoading={isLoading}
        HeaderComponent={CustomerTableHeaderList}
        data={customers}
        RowItemComponent={CustomerRowItem}
      />
    </>
  );
}

export default CustomersListing;
