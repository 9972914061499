import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomNavTabs from "../../commons/CustomNavTabs";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import PayoutsListing from "./PayoutsListing";
import usePayouts from "../../hooks/usePayouts";
import useUser from "../../hooks/useUser";
import useDatePicker from "../../hooks/useDatePicker";

const tabItems = ["All", "Paid", "Pending"];
const dateFormat = "YYYY-MM-DD";

function Payouts() {
  const navigate = useNavigate();
  const { user } = useUser();
  const pageSize = 10;
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);
  const { DatePickerComponent, startDate, endDate, DateDisplay } =
    useDatePicker(() => {}, dateFormat);

  const { payouts, isLoading } = usePayouts({
    accessToken: user?.token,
    status: selectedTab == tabItems[0] ? "" : selectedTab?.toLowerCase(),
    pageSize,
    pageNumber,
    startDate: selectedDate[0],
    endDate: selectedDate[1],
  });

  const handleRowClick = (id) => {
    navigate(`/payouts/${id}`);
  };

  useEffect(() => {
    setSelectedDate([startDate, endDate]);
  }, [startDate, endDate]);

  useEffect(() => {
    setPageNumber(1);
  }, [selectedTab]);

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        <PageTitle title={"Payouts"} />
        <PageBreadCrumb
          mainText="Dashboard"
          subText="Payout List"
          otherText=""
          isActiveIndex={1}
        />
        <div className={`flex justify-between mt-6 text-[${GENERAL_GRAY_HEX}]`}>
          <div>
            <CustomNavTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabItems}
            />
          </div>
          <div className={`flex gap-4`}>
            {/* <SearchInput
              className="bg-white shadow-sm py-3"
              onChange={() => {}}
              placeholder={"Search payouts..."}
            /> */}
            {DatePickerComponent}
          </div>
        </div>

        {DateDisplay}

        <PayoutsListing
          paginationData={{ setPageNumber, pageNumber, pageSize }}
          payouts={payouts?.payouts}
          totalRecords={payouts?.totalRecord ?? 0}
          onRowClick={handleRowClick}
          isLoading={isLoading}
        />
      </div>
    </Layout>
  );
}

export default Payouts;
