import React from "react";

function RadioItem({
  onClick,
  selectedValue,
  value,
  label,
  groupName,
  padding = "p-4",
  textStyle = "text-[14px] font-[500]",
  disabled = false,
  readOnly = false
}) {
  const selected = selectedValue == value;
  return (
    <div
      onClick={disabled || readOnly ? () => { } : onClick}
      className={`${selected ? "border-[#318EC9]" : ""
        } ${padding} ${textStyle} border border-[2px] flex gap-4 items-center w-full rounded-lg ${(readOnly || disabled) ? '' : 'hover:border-[#318EC9] cursor-pointer'}  `}
    >
      <input
        disabled={disabled || readOnly}
        readOnly={readOnly}
        checked={selected}
        type="radio"
        name={groupName}
        className="w-[24px] h-[24px]"
      />
      {label}
    </div>
  );
}

export default RadioItem;
