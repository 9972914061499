import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const registerMaker = async (data) => {
  return axiosInstance(`/makers`, "POST", data, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const getMakers = async (data) => {
  return axiosInstance(`/makers?status=${data?.status}&searchTerm=${data?.searchTerm}`, "GET", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const reviewMaker = async (data) => {
  return axiosInstance(
    `/makers/${data.id}/review`,
    "PUT",
    data,
    data?.accessToken
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};
