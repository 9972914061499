import React from "react";
import { FaRegUser } from "react-icons/fa";
import { PiCaretDownBold } from "react-icons/pi";
import { RxTriangleUp } from "react-icons/rx";
import { TfiLock } from "react-icons/tfi";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MY_BUSINESS_PROFILE,
  MY_PROFILE,
} from "../constants/data";
import AccountLinkItemMini from "./AccountLinkItemMini";

function LoginUserComponent({ user }) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      {!user && (
        <div className="relative flex gap-4 items-center login-container">
          <img
            src="/assets/user_logo.png"
            alt="Wudify user logo"
            loading="lazy"
          />
          <div  onClick={() => (window.location.href = "/auth/login")} className="flex gap-1 cursor-pointer hover:text-[#FF9D21] items-center">
            <span className="hover:text-[#FF9D21]">Login</span>
            {/* <PiCaretDownBold className="text-lg font-bold" /> */}
          </div>
        </div>
      )}
      {user &&  (
        <div
          className="relative flex gap-1 items-center login-container"
          style={{ zIndex: "100" }}
        >
          <img
            src="/assets/user_logo.png"
            alt="Wudify user logo"
            loading="lazy"
          />
          <div className="flex gap-1 cursor-pointer hover:text-[#FF9D21] items-center">
            <span className="hover:text-[#FF9D21]">{user?.firstName}</span>
            <PiCaretDownBold className="text-lg font-bold" />
          </div>
          <div
            className="bg-white w-[13em] right-0 absolute top-10 border p-3 rounded-md login-option"
            style={{ zIndex: "100" }}
          >
            <div
              className="w-[10em] absolute"
              style={{
                fontSize: "40px",
                fontWeight: "normal",
                top: "-18px",
                right: "0px",
              }}
            >
              <RxTriangleUp
                className="text-gray-100 relative"
                style={{
                  fontSize: "40px",
                  fontWeight: "normal",

                  top: "-6px",
                  right: "-8em",
                }}
              />
            </div>
            <div className="flex gap-2 flex-col items-start ">
              <div className="p-3 bg-white">
                <AccountLinkItemMini
                  label="My Profile"
                  link={`/profile`}
                >
                  <FaRegUser />
                </AccountLinkItemMini>
                <AccountLinkItemMini
                  label="Log out"
                  className="text-red-400"
                  link={`/auth/logout`}
                  onClick={() => navigate("/auth/logout")}
                >
                  <TfiLock />
                </AccountLinkItemMini>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginUserComponent;
