import React, { useState, useRef, useReducer } from "react";
import { Formik } from "formik";
import { Form } from "formik";
import CustomInputField from "../../commons/CustomInputField";
import CustomTextField from "../../commons/CustomTextField";
import RadioItem from "../../commons/RadioItem";
import {
  GENERAL_ORANGE_HEX,
  REGISTERED_BUSINESS,
  UN_REGISTERED_BUSINESS,
} from "../../constants/texts";
import { imageAndDocFileTypes, MEANS_OF_ID } from "../../constants/data";
import ConfirmationModal from "../../commons/modals/ConfirmationModal";
import RoundedMdButton from "../../commons/RoundedMdButton";
import { getFileTypesFriendly } from "../../helpers/fileUpload";
import { validEmail } from "../../helpers/validator";
import useStates from "../../hooks/useStates";
import useTowns from "../../hooks/useTowns";
import useAddServiceLocation from "../../hooks/useAddServiceLocation";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";
import { useNavigate } from "react-router-dom";
import HeadingAndDetails from '../admin_commons/HeadingAndDetails';
import { radioGroupHandler } from "../../reducers/radioGroupHandler";
import PageTitle from "../../commons/PageTitle";
import { toggleRadioSelection } from '../../reducers/radioGroupHandler/actions';
import CustomSelectField from '../../commons/CustomSelectField';
import PlusIcon from "../../commons/PlusIcon";
import ProductImageUploaderUI from '../vendor_dashboard/add_products/ProductImageUploaderUI';

function NewServiceLocation({ onClose, }) {
  const navigate = useNavigate();

  const { addServiceLocation } = useAddServiceLocation();
  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();
  const [fileInput, setFileInput] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const idFileRef = useRef();

  const [businessRegistrationState, dispatch] = useReducer(radioGroupHandler, { radioValue: "" })

  const handleRadioChange = (value) => {
    dispatch(toggleRadioSelection(value));
  };

  const { states: stateList } = useStates();
  const [state, setState] = useState("");
  const [region, setRegion] = useState("");
  const { towns } = useTowns(state);

  return (
    <div className="m">
      <PageTitle title="Add Service Location" />
      <hr className="my-4" />
      <div className="mt-2"></div>
      <Formik
        initialValues={{
          serviceLocation: "",
          shippingRate: "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          addServiceLocation({
            ...values,
          })
            .then((res) => {
              setInfoDetails({ message: res?.data?.message, isError: false });
              if (idFileRef?.current) {
                idFileRef.current.value = null;
              }
              values.meansOfIdentification = null;
              values.meansOfIdentificationFile = "";
              values.state = null;
              values.region = null;
              setState(null);
              setRegion(null);
              handleRadioChange("");
              resetForm();
              navigate("/maker/complete");
            })
            .catch((err) => {
              setInfoDetails({ message: err?.message, isError: true });
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};


          if (!values.serviceLocation) {
            errors.serviceLocation = "Service Location is required";
          }

          if (!values.shippingRate) {
            errors.shippingRate = "Shipping rate is required";
          }

          console.log("errors", errors);

          return errors;
        }}
      >
        {({ isSubmitting, errors, setErrors, setFieldValue, setFieldError, values }) => (
          <Form>
            <div className="flex items-start gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"serviceLocation"}
                  required={true}
                  placeholder="Enter service location"
                  label={"Service Location"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"serviceRate"}
                  required={true}
                  placeholder="Enter service rate"
                  label={"Service Rate"}
                />
              </div>
            </div>

            <div className="mb-4 text-[14px] cursor-pointer flex items-center gap-1 w-full" style={{ color: GENERAL_ORANGE_HEX }}>
              <div><PlusIcon /></div>
              <div>Add new service location and rate</div>
            </div>

            <div className="flex justify-between gap-5 mt-4">
              <div className="w-full">
                <RoundedMdButton
                  label={"Close"}
                  disabled={isSubmitting}
                  onClick={onClose}
                  className="border bg-gray-100"
                />
              </div>
              <div className="w-full">
                <RoundedMdButton
                  label={isSubmitting ? "Please wait..." : "Submit"}
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-[#FF9D21] font-[500] text-[16px] text-white"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {InfoDisplayComponent}

      <ConfirmationModal
        isOpen={openModal && fileInput != ""}
        type={"warning"}
        title={"File upload error"}
        description={""}
        subText={
          "Invalid file type. Acceptable types (" +
          getFileTypesFriendly(imageAndDocFileTypes) +
          ")"
        }
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className="my-5">
          <RoundedMdButton
            label={"OK"}
            onClick={() => {
              setOpenModal(false);
            }}
          />
        </div>
      </ConfirmationModal>
    </div>
  );
}

export default NewServiceLocation;
