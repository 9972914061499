import React from "react";
import Header from "./Header";
import ScrollToTop from "../commons/ScrollToTop";
import SideBar from "./SideBar";
import { RxDashboard } from "react-icons/rx";
import { IoCartOutline } from "react-icons/io5";
import { RiArrowGoBackFill } from "react-icons/ri";
import { LuUser2 } from "react-icons/lu";
import { GoPeople } from "react-icons/go";
import { PiMoneyWavyThin } from "react-icons/pi";
import { PiChatsThin } from "react-icons/pi";
import { PiTruckThin } from "react-icons/pi";
import { PiHammerThin } from "react-icons/pi";
import { PiPackageThin } from "react-icons/pi";
import { PiSuitcaseSimpleLight } from "react-icons/pi";
import { LuUserCog2 } from "react-icons/lu";
import UserIcon from "./UserIcon";
import useRouting from "../hooks/useRouting";
import useUser from "../hooks/useUser";

const sidebarItems = [
  {
    label: "Dashboard",
    children: <RxDashboard />,
    url: "/dashboard",
  },
  {
    label: "Orders",
    children: <IoCartOutline />,
    url: "/orders",
  },
  {
    label: "Returned Orders",
    children: <RiArrowGoBackFill />,
    url: "/returned-orders",
  },
  {
    label: "Products",
    children: <PiPackageThin />,
    url: "/products",
  },
  {
    label: "Customers",
    children: <LuUser2 />,
    url: "/customers",
  },
  {
    label: "Vendors",
    children: <GoPeople />,
    url: "/vendors",
  },
  {
    label: "Payouts",
    children: <PiMoneyWavyThin />,
    url: "/payouts",
  },
  {
    label: "Support Tickets",
    children: <PiChatsThin />,
    url: "/support-tickets",
  },
  {
    label: "Logistics Partners",
    children: <PiTruckThin />,
    url: "/logistics-partners",
  },
  {
    label: "Makers",
    children: <PiHammerThin />,
    url: "/makers",
  },
  {
    label: "Installers",
    children: <PiSuitcaseSimpleLight />,
    url: "/installers",
  },
  {
    label: "User Management",
    children: <LuUserCog2 />,
    url: "/user-management",
  },
  {
    label: "Profile",
    children: <UserIcon />,
    url: "/profile",
  },
];

function Layout({
  children,
  bgClassName = "bg-transparent",
  showSideBar = true,
}) {
  const { user } = useUser();
  const { routeList } = useRouting(user);

  const getSidebarItems = () => {
    const items = sidebarItems.map((item) => {
      const x = routeList.find((route) => route.path == item.url);
      if (x) {
        return item;
      }
    });

    return items;
  };

  return (
    <div className="relative">
      <ScrollToTop />
      <Header />
      <div className="flex mt-[6.35em]">
        {showSideBar && (
          <div className="w-[20%]">
            <SideBar sidebarItems={getSidebarItems()} />
          </div>
        )}

        <div
          className={`${bgClassName} ${showSideBar ? "w-[80%]" : "w-full"}`}
          style={{ minHeight: "85vh" }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default Layout;
