import React from "react";
import { getFileUrl } from "./../../helpers/extractors";
import StyledSpan from "../../commons/StyledSpan";

function VendorRowItem({ item, user, onClick = () => {} }) {
  return (
    <>
      <tr class="hover:bg-gray-100 cursor-pointer" onClick={onClick}>
        <td class="px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
          #{item?.id}
        </td>
        <td class="px-6 py-4 text-[13px]">
          <img
            className="w-[3em] h-[3em] border rounded-full"
            style={{ objectFit: "contain" }}
            src={getFileUrl(item?.logoImagePath)}
            alt=""
          />
        </td>
        <td class="px-6 py-4 text-[14px] font-[400]">{item?.businessName}</td>
        <td class="px-6 py-4 text-[14px] font-[400]">
          {item?.VendorAccountManagerInformation?.firstName}{" "}
          {item?.VendorAccountManagerInformation?.lastName}
        </td>
        <td class="px-6 py-4 text-[14px] font-[400]">
          {item?.VendorAccountManagerInformation?.phoneNumber ||
            item?.phoneNumber}
        </td>
        <td class="px-6 py-4 flex gap-3">
          <StyledSpan text={item?.verified ? "Active" : "Inactive"} />
        </td>
        {/* <td class="px-6 py-4">
          <div className="flex gap-3">
            <div className="text-[#318EC9] cursor-pointer">View</div>
            <div className="text-[#008000] cursor-pointer">Edit</div>
          </div>
        </td> */}
      </tr>
    </>
  );
}

export default VendorRowItem;
