import React from "react";
import CustomSortingArrows from "../../commons/CustomSortingArrows";

function UserTableHeaderList() {
  return (
    <thead class="font-[500] text-[12px] bg-gray-100">
      <tr className="">
        <th scope="col" class="px-5 py-3 font-[400]">
          <div className="">
            <span>User ID.</span>
            <CustomSortingArrows />
          </div>
        </th>
        <th scope="col" class="px-5 py-3 font-[400]">
          <div className="flex items-center gap-2">
            <span>First Name</span>
            <CustomSortingArrows />
          </div>
        </th>
        <th scope="col" class="px-5 py-3 font-[400]">
          <div className="flex items-center gap-2">
            <span>Last Name</span>
            <CustomSortingArrows />
          </div>
        </th>
        <th scope="col" class="px-5 py-3 font-[400]">
          <div className="flex items-center gap-2">
            <span>Email Address</span>
            <CustomSortingArrows />
          </div>
        </th>
        <th scope="col" class="px-5 py-3 font-[400]">
          <div className="flex items-center gap-2">
            <span>Phone Number</span>
            <CustomSortingArrows />
          </div>
        </th>
        <th scope="col" class="px-5 py-3 font-[400]">
          <div className="flex items-center gap-2">
            <span>Role</span>
            <CustomSortingArrows />
          </div>
        </th>
        <th scope="col" class="px-5 py-3 font-[400]">
          <div className="flex items-center gap-2">
            <span>Status</span>
            <CustomSortingArrows />
          </div>
        </th>
      </tr>
    </thead>
  );
}

export default UserTableHeaderList;
