import React, { useState } from "react";
import CustomSidebarLinkItem from "./CustomSidebarLinkItem";

function SideBar({ sidebarItems = [] }) {
  return (
    <div
      className="shadow-sm border py-6 px-4 bg-white w-full"
      style={{ height: "90vh", overflowY: "scroll" }}
    >
      {sidebarItems.map((sidebarItem, index) => {
        if (sidebarItem) {
          return (
            <CustomSidebarLinkItem
              label={sidebarItem?.label}
              index={index}
              url={sidebarItem?.url}
            >
              {sidebarItem?.children}
            </CustomSidebarLinkItem>
          );
        }
        return <></>;
      })}
    </div>
  );
}

export default SideBar;
