import React from "react";
import { RiArrowDropRightFill } from "react-icons/ri";
import { GENERAL_GRAY_HEX } from "../constants/texts";

function PageBreadCrumb({ mainText, subText, otherText, isActiveIndex }) {
  return (
    <div className="flex gap-3 items-center text-[16px] font-[500] text-[#FF9D21]">
      <div
        className={`${
          isActiveIndex === 0 ? `text-[${GENERAL_GRAY_HEX}]` : ""
        } flex`}
      >
        {mainText}
      </div>
      {subText && (
        <div
          className={`${
            isActiveIndex === 1 ? `text-[${GENERAL_GRAY_HEX}]` : ""
          } flex items-center`}
        >
          <div className="">
            <RiArrowDropRightFill className="text-[24px]" />
          </div>
          <div>{subText}</div>
        </div>
      )}
      {otherText && (
        <div
          className={`${
            isActiveIndex === 2 ? `text-[${GENERAL_GRAY_HEX}]` : ""
          } flex`}
        >
          <div>
            <RiArrowDropRightFill className="text-[24px]" />
          </div>
          <div>{otherText}</div>
        </div>
      )}
    </div>
  );
}

export default PageBreadCrumb;
