import React, { useState, useRef, useReducer, useEffect } from "react";
import { Formik } from "formik";
import { Form } from "formik";
import CustomInputField from "../../commons/CustomInputField";
import CustomTextField from "../../commons/CustomTextField";
import RadioItem from "./../../commons/RadioItem";
import {
  GENERAL_ORANGE_HEX,
  REGISTERED_BUSINESS,
  UN_REGISTERED_BUSINESS,
} from "./../../constants/texts";
import CustomSelectField from "./../../commons/CustomSelectField";
import { imageAndDocFileTypes, MEANS_OF_ID } from "../../constants/data";
import ConfirmationModal from "../../commons/modals/ConfirmationModal";
import RoundedMdButton from "./../../commons/RoundedMdButton";
import { getFileTypesFriendly } from "../../helpers/fileUpload";
import { validEmail, validPhoneNumber } from "../../helpers/validator";
import useStates from "./../../hooks/useStates";
import useTowns from "./../../hooks/useTowns";
import useAddMaker from "./../../hooks/useAddMaker";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";
import { useNavigate } from "react-router-dom";
import HeadingAndDetails from "./../admin_commons/HeadingAndDetails";
import { radioGroupHandler } from "../../reducers/radioGroupHandler";
import PageTitle from "../../commons/PageTitle";
import { toggleRadioSelection } from "./../../reducers/radioGroupHandler/actions";
import { delayAction } from "../../helpers/events";
import CustomButton from "../../commons/CustomButton";
import useReviewMaker from "../../hooks/useReviewMaker";
import CustomBackdrop from "../../commons/CustomBackdrop";

function MakerContactDetails({ onClose, maker, user, isEditing, callback }) {
  const navigate = useNavigate();

  const { addMaker } = useAddMaker();
  const { makerReview } = useReviewMaker();
  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();
  const [fileInput, setFileInput] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedMaker, setSelectedMaker] = useState(maker || {});
  const idFileRef = useRef();

  const [businessRegistrationState, dispatch] = useReducer(radioGroupHandler, {
    radioValue: selectedMaker?.registered
      ? REGISTERED_BUSINESS
      : UN_REGISTERED_BUSINESS,
  });

  const handleRadioChange = (value) => {
    dispatch(toggleRadioSelection(value));
  };

  const { states: stateList } = useStates();
  const [state, setState] = useState("");
  const [region, setRegion] = useState("");
  const { towns } = useTowns(state);

  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showReasonInput, setShowReasonInput] = useState(false);
  const [comment, setComment] = useState("");

  const handleReview = (approveOrReject, data) => {
    let action = approveOrReject;

    if (action == false && !data?.comment) {
      setInfoDetails({
        message: "Please enter reason for deactivation",
        isError: true,
      });
      setIsSubmitting(false);
      return;
    }

    //continue with submission
    setIsSubmitting(true);

    makerReview({ ...data, action, accessToken: user?.token })
      .then((res) => {
        setInfoDetails({
          message: res?.data?.message,
          isError: false,
        });
        delayAction(callback, 2000)
      })
      .catch((err) => {
        console.log("error from reviewing installer", err);
        setInfoDetails({ message: err?.message, isError: true });
      })
      .finally(() => {
        setIsSubmitting(false);
        setOpenModal(false)
        setOpenReviewModal(false)
        setShowReasonInput(false)
        setComment("")
      });
  };

  useEffect(() => {
    setState(selectedMaker?.state);
    setRegion(selectedMaker?.region);
  }, [selectedMaker]);

  useEffect(() => {
    setSelectedMaker(maker);
    handleRadioChange(maker?.registered
      ? REGISTERED_BUSINESS
      : UN_REGISTERED_BUSINESS)
  }, [maker]);


  return (
    <div className="m">
      <div className="flex items-center justify-between">
        <PageTitle title={isEditing ? "View / Edit Maker" : "Add Maker"} />
        {isEditing && <CustomButton
          label="Review"
          onClick={() => setOpenReviewModal(true)}
          className={`border text-white border-[${GENERAL_ORANGE_HEX}] bg-[${GENERAL_ORANGE_HEX}]`}
        />}
      </div>
      <hr className="my-4" />
      <HeadingAndDetails
        title={"Contact Details"}
        description={"Kindly complete the form below"}
      />
      <div className="mt-2"></div>
      <Formik
        enableReinitialize
        initialValues={{
          containsFile: true,
          id: selectedMaker?.id ?? null,
          description: selectedMaker?.jobDescription ?? "",
          email: selectedMaker?.email ?? "",
          phoneNumber: selectedMaker?.phoneNumber ?? "",
          firstName: selectedMaker?.firstName ?? "",
          lastName: selectedMaker?.lastName ?? "",
          address: selectedMaker?.address ?? "",
          meansOfIdentification: selectedMaker?.meansOfIdentification ?? "",
          registered: selectedMaker?.registered ?? false,
          cacCertificateFile: selectedMaker?.cacCertificateFilePath ?? "",
          meansOfIdentificationFile:
            selectedMaker?.meansOfIdentificationFilePath ?? "",
          state: selectedMaker?.state ?? "",
          region: selectedMaker?.region ?? "",
          accessToken: user?.token,
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          addMaker({
            ...values,
            registered:
              businessRegistrationState.radioValue == REGISTERED_BUSINESS,
          })
            .then((res) => {
              setInfoDetails({ message: res?.data?.message, isError: false });
              if (idFileRef?.current) {
                idFileRef.current.value = null;
              }
              values.meansOfIdentification = null;
              values.meansOfIdentificationFile = "";
              values.state = null;
              values.region = null;
              setState(null);
              setRegion(null);
              handleRadioChange("");
              resetForm();

              delayAction(() => {
                if (callback) {
                  callback();
                }
              }, 2000);

              navigate("/makers");
            })
            .catch((err) => {
              setInfoDetails({ message: err?.message, isError: true });
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};

          if (businessRegistrationState.radioValue == REGISTERED_BUSINESS) {
            if (values.cacCertificateFile === null) {
              setFileInput("");
              setOpenModal(true);
            }
          } else {
            if (values.meansOfIdentificationFile === null) {
              setFileInput("");
              setOpenModal(true);
            }
          }

          if (!values.email) {
            errors.email = "Email is required";
          } else if (!validEmail(values.email)) {
            errors.email = "Invalid email address";
          }

          if (!values.phoneNumber) {
            errors.phoneNumber = "Phone number is required";
          } else if (!validPhoneNumber(values.phoneNumber)) {
            errors.phoneNumber = "Invalid phone number";
          }
          if (!values.firstName) {
            errors.firstName = "First name is required";
          }

          if (!values.lastName) {
            errors.lastName = "Last name is required";
          }
          if (!values.address) {
            errors.address = "Address is required";
          }
          if (!values.state || values.state == "") {
            setState("");
            errors.state = "State is required";
          } else {
            setState(values.state);
          }
          if (!values.region) {
            errors.region = "Region is required";
          } else {
            setRegion(values.region);
          }

          if (businessRegistrationState.radioValue == REGISTERED_BUSINESS) {
            if (!values.cacCertificateFile) {
              errors.cacCertificateFile = "CAC certificate file is required";
            }
          } else {
            if (!values.meansOfIdentification) {
              errors.meansOfIdentification =
                "Means of identification is required";
            }

            if (!values.meansOfIdentificationFile) {
              errors.meansOfIdentificationFile =
                "Means of identification file is required";
            }
          }

          if (!values.description) {
            errors.description = "Description is required";
          }

          console.log("errors", errors);

          return errors;
        }}
      >
        {({ isSubmitting, setFieldValue, setFieldError, values }) => (
          <Form>
            <div className="flex items-start gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"firstName"}
                  required={true}
                  placeholder="Enter first name"
                  label={"First Name"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"lastName"}
                  required={true}
                  placeholder="Enter last name"
                  label={"Last Name"}
                />
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"phoneNumber"}
                  required={true}
                  placeholder="Enter phone number"
                  label={"Phone Number"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"email"}
                  required={true}
                  placeholder="Enter your email"
                  label={"Email"}
                />
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div className="w-full">
                <CustomTextField
                  name={"address"}
                  required={true}
                  placeholder="Enter your address"
                  label={"Address"}
                />
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div className="w-full">
                <CustomSelectField
                  name={"state"}
                  required={true}
                  placeholder="Select state"
                  type="text"
                  label={"State"}
                  valueKey={"state_code"}
                  keyValue={"name"}
                  selectedValue={state}
                  list={stateList?.data ?? []}
                  onChange={(value) => setFieldValue("state", value)}
                  setFieldError={setFieldError}
                />
              </div>
              <div className="w-full">
                {!state && (
                  <CustomInputField
                    readOnly={true}
                    placeholder="Select region"
                    name="region"
                    type="text"
                    label={"Region"}
                  />
                )}
                {state && (
                  <CustomSelectField
                    name={"region"}
                    required={true}
                    placeholder="Select region"
                    type="text"
                    label={"Region"}
                    valueKey={"name"}
                    keyValue={"name"}
                    selectedValue={region}
                    list={!towns?.data?.message ? towns?.data : []}
                    onChange={(value) => setFieldValue("region", value)}
                    setFieldError={setFieldError}
                    disabled={state == ""}
                  />
                )}
              </div>
            </div>

            <HeadingAndDetails
              title={"Is your business a registered business?"}
              description=""
            />
            <div className="flex items-start gap-6 mt-3">
              <RadioItem
                value={REGISTERED_BUSINESS}
                selectedValue={businessRegistrationState.radioValue}
                onClick={() => handleRadioChange(REGISTERED_BUSINESS)}
                label="Yes - I have registered with the CAC"
              />
              <RadioItem
                value={UN_REGISTERED_BUSINESS}
                selectedValue={businessRegistrationState.radioValue}
                onClick={() => handleRadioChange(UN_REGISTERED_BUSINESS)}
                label="No - I have not registered with the CAC"
              />
            </div>

            {businessRegistrationState.radioValue == REGISTERED_BUSINESS && (
              <div className="flex items-start gap-6 mt-3">
                <div className="w-full">
                  <CustomInputField
                    name={"cacCertificateFile"}
                    required={true}
                    label={"CAC Certificate"}
                    type="file"
                    fileTypes={imageAndDocFileTypes}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    inputRef={idFileRef}
                    selectedFile={values.cacCertificateFile}
                    fileCallback={setFileInput}
                  />
                </div>
                <div className="w-full"></div>
              </div>
            )}
            {businessRegistrationState.radioValue == UN_REGISTERED_BUSINESS && (
              <div className="flex items-start gap-6 mt-6">
                <div className="w-full">
                  <CustomSelectField
                    name={"meansOfIdentification"}
                    required={true}
                    placeholder="Select means of identification"
                    label={"Means of Identification"}
                    list={MEANS_OF_ID}
                    selectedValue={values.meansOfIdentification}
                    onChange={(value) =>
                      setFieldValue("meansOfIdentification", value)
                    }
                  />
                </div>
                <div className="w-full">
                  <CustomInputField
                    name={"meansOfIdentificationFile"}
                    required={true}
                    label={"Attach Means of Identification"}
                    type="file"
                    fileTypes={imageAndDocFileTypes}
                    setFieldValue={setFieldValue}
                    selectedFile={values.meansOfIdentificationFile}
                    setFieldError={setFieldError}
                    inputRef={idFileRef}
                    fileCallback={setFileInput}
                  />
                </div>
              </div>
            )}

            <div className="flex items-start gap-6 mt-1">
              <div className="w-full">
                <CustomTextField
                  name={"description"}
                  required={true}
                  placeholder="I have 3 years experience making bedroom furniture, office furniture e.t.c"
                  label={"Describe your specialization"}
                />
              </div>
            </div>

            <div className="flex justify-between mb-8">
              <div className="w-[20%]">
                <RoundedMdButton
                  label={"Close"}
                  disabled={isSubmitting}
                  onClick={onClose}
                />
              </div>
              <div className="w-[20%]">
                <RoundedMdButton
                  label={
                    isSubmitting
                      ? "Please wait..."
                      : `${isEditing ? "Save Changes" : "Submit"}`
                  }
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-[#FF9D21] font-[500] text-[16px] text-white"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {InfoDisplayComponent}
      
      {isSubmitting && <CustomBackdrop open={isSubmitting} text="Please wait..." />}

      <ConfirmationModal
        isOpen={openModal && fileInput != ""}
        type={"warning"}
        title={"File upload error"}
        description={""}
        subText={
          "Invalid file type. Acceptable types (" +
          getFileTypesFriendly(imageAndDocFileTypes) +
          ")"
        }
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className="my-5">
          <RoundedMdButton
            label={"OK"}
            onClick={() => {
              setOpenModal(false);
            }}
          />
        </div>
      </ConfirmationModal>


      <ConfirmationModal
        title={"Review Maker"}
        description={"You can activate or deactivate a maker"}
        subText={
          "Activate a maker if all details are correct. Otherwise, deactivate the maker and provide some reason."
        }
        isOpen={openReviewModal}
        onClose={() => {
          setOpenReviewModal(false);
        }}
      >
        {showReasonInput && (
          <>
            <Formik
              initialValues={{
                comment: "",
              }}
              validate={(values) => {
                const errors = {};
                setComment(values.comment);
                if (!values.comment) {
                  errors.comment = "Please enter reason(s) for rejection";
                }
                return errors;
              }}
            >
              <Form className="mt-6">
                <CustomTextField
                  name={"comment"}
                  label={"Deactivation reason"}
                  placeholder="Please enter reason for deactivating"
                  required
                />
              </Form>
            </Formik>
          </>
        )}
        {!showReasonInput && (
          <div className="my-5 flex justify-between gap-6">
            <RoundedMdButton
              label={"Deactivate"}
              onClick={() => {
                setShowReasonInput(true);
              }}
              className="bg-gray-200"
            />
            <RoundedMdButton
              label={"Activate"}
              onClick={() => {
                handleReview(true, { id: selectedMaker?.id });
              }}
              className="bg-orange-400 text-white"
            />
          </div>
        )}
        {showReasonInput && (
          <div className="my-5 flex justify-between gap-6">
            <RoundedMdButton
              label={"Cancel"}
              onClick={() => {
                setShowReasonInput(false);
              }}
              className="bg-gray-200"
            />
            <RoundedMdButton
              label={"Deactivate"}
              onClick={() => {
                handleReview(false, { id: selectedMaker?.id, comment });
              }}
              className="bg-orange-400 text-white"
            />
          </div>
        )}
      </ConfirmationModal>


    </div>
  );
}

export default MakerContactDetails;
