import CustomIconLabelValue from "../../commons/CustomIconLabelValue";
import UserIcon from "../../commons/UserIcon";
import BankIcon from "../../commons/BankIcon";
import PoundIcon from "../../commons/PoundIcon";
import CardTitle from "./CardTitle";

function BankDetails({ bankName, accountName, accountNumber }) {
  return (
    <>
      <div>
        <CardTitle title="Bank Details" />
        <div className="mb-6"></div>
        <CustomIconLabelValue
          label={"Bank Name"}
          value={bankName}
          icon={<BankIcon />}
        />
        <div className="mb-4"></div>
        <CustomIconLabelValue
          label={"Account Name"}
          value={accountName}
          icon={<UserIcon />}
        />
        <div className="mb-4"></div>
        <CustomIconLabelValue
          label={"Account Number"}
          value={accountNumber}
          icon={<PoundIcon />}
        />
      </div>
    </>
  );
}

export default BankDetails;
