import React from "react";
import StyledSpan from "../../commons/StyledSpan";

function LogisticsPartnerRowItem({
  item,
  onClick = () => { },
}) {

  return (
    <>
      <tr class="hover:bg-gray-100 cursor-pointer" onClick={onClick}>
        {/* <td
          scope="row"
          class="px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
        >
          <input type="checkbox" />
        </td> */}
        <td
          scope="row"
          class="px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
        >
          #{item?.id}
        </td>
        <td class="px-6 py-4 text-[14px] font-[400]">{item?.name}</td>
        <td class="px-6 py-4 text-[14px] font-[400]">{item?.email}</td>
        <td class="px-6 py-4 text-[14px] font-[400]">
          {item?.phoneNumber}
        </td>
        <td class="px-6 py-4 flex gap-3">
          <StyledSpan text={item?.status}>
          </StyledSpan>
        </td>
        {/* <td class="px-6 py-4">
          <div className="flex gap-3">
            <div className="text-[#318EC9] cursor-pointer">View</div>
            <div className="text-[#008000] cursor-pointer">Edit</div>
          </div>
        </td> */}
      </tr>
    </>
  );
}

export default LogisticsPartnerRowItem;
