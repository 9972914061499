import React, { useState, useRef, useReducer } from "react";
import { Formik } from "formik";
import { Form } from "formik";
import CustomInputField from "../../commons/CustomInputField";
import {
  GENERAL_ORANGE_HEX,
  REGISTERED_BUSINESS,
} from "../../constants/texts";
import { imageAndDocFileTypes } from "../../constants/data";
import ConfirmationModal from "../../commons/modals/ConfirmationModal";
import RoundedMdButton from "../../commons/RoundedMdButton";
import { getFileTypesFriendly } from "../../helpers/fileUpload";
import { validEmail, validPhoneNumber } from "../../helpers/validator";
import useStates from "../../hooks/useStates";
import useTowns from "../../hooks/useTowns";
import useAddLogisticsPartner from "../../hooks/useAddLogisticsPartner";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../commons/PageTitle";
import PlusIcon from "../../commons/PlusIcon";
import ProductImageUploaderUI from '../vendor_dashboard/add_products/ProductImageUploaderUI';
import { delayAction } from "../../helpers/redirect";

function NewLogisticsPartner({ onClose, user }) {
  const navigate = useNavigate();

  const { addLogisticsPartner } = useAddLogisticsPartner();
  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();
  const [fileInput, setFileInput] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const idFileRef = useRef();

  return (
    <div className="m">
      <PageTitle title="Add Logistics Partner" />
      <hr className="my-4" />
      <div className="mt-2"></div>
      <Formik
        initialValues={{
          companyName: "",
          accountManagerName: "",
          phoneNumber: "",
          emailAddress: "",
          companyLogo: "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          addLogisticsPartner({
            name: values.companyName,
            accountManager: values.accountManagerName,
            phoneNumber: values.phoneNumber,
            email: values.emailAddress,
            logo: values?.companyLogo,
            containsFile: true,
            accessToken: user?.token
          })
            .then((res) => {
              setInfoDetails({ message: res?.data?.message, isError: false });
              resetForm();
              delayAction(() => {
                onClose();
                navigate("/logistics-partners");
              }, 2000)
            })
            .catch((err) => {
              setInfoDetails({ message: err?.message, isError: true });
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};

          if (!values.emailAddress) {
            errors.emailAddress = "Email is required";
          } else if (!validEmail(values.emailAddress)) {
            errors.emailAddress = "Invalid email address";
          }

          if (!values.phoneNumber) {
            errors.phoneNumber = "Phone number is required";
          } else if (!validPhoneNumber(values.phoneNumber)) {
            errors.phoneNumber = "Invalid phone number";
          }
          if (!values.companyName) {
            errors.companyName = "Company name is required";
          }

          if (!values.accountManagerName) {
            errors.accountManagerName = "Account manager name is required";
          }

          console.log("errors", errors);

          return errors;
        }}
      >
        {({ isSubmitting, errors, setErrors, setFieldValue, setFieldError, values }) => (
          <Form>
            <div className="flex items-start gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"companyName"}
                  required={true}
                  placeholder="Enter name of company"
                  label={"Company Name"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"accountManagerName"}
                  required={true}
                  placeholder="Enter name of account manager"
                  label={"Account Manager Name"}
                />
              </div>

            </div>
            <div className="flex items-start gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"phoneNumber"}
                  required={true}
                  placeholder="Enter phone number"
                  label={"Phone Number"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"emailAddress"}
                  required={true}
                  placeholder="Enter email address"
                  label={"Email Address"}
                />
              </div>
            </div>
            {/* <div className="flex items-start gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"serviceLocation"}
                  required={true}
                  placeholder="Enter service location"
                  label={"Service Location"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"serviceRate"}
                  required={true}
                  placeholder="Enter service rate"
                  label={"Service Rate"}
                />
              </div>
            </div> */}

            {/* <div className="mb-4 text-[14px] cursor-pointer flex items-center gap-1 w-full" style={{ color: GENERAL_ORANGE_HEX }}>
              <div><PlusIcon /></div>
              <div>Add new service location and rate</div>
            </div> */}

            <div className="flex items-center gap-4">
              <ProductImageUploaderUI file={values.companyLogo} name="companyLogo" errors={errors} setFieldError={setFieldError} setErrors={setErrors} label="Company Logo" required={true} onChange={(value) => setFieldValue("companyLogo", value)} disabled={isSubmitting} />
              <div className="w-full"></div>
            </div>

            <div className="flex justify-between gap-5 mt-4">
              <div className="w-full">
                <RoundedMdButton
                  label={"Close"}
                  disabled={isSubmitting}
                  onClick={onClose}
                  className="border bg-gray-100"
                />
              </div>
              <div className="w-full">
                <RoundedMdButton
                  label={isSubmitting ? "Please wait..." : "Submit"}
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-[#FF9D21] font-[500] text-[16px] text-white"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {InfoDisplayComponent}

      <ConfirmationModal
        isOpen={openModal && fileInput != ""}
        type={"warning"}
        title={"File upload error"}
        description={""}
        subText={
          "Invalid file type. Acceptable types (" +
          getFileTypesFriendly(imageAndDocFileTypes) +
          ")"
        }
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className="my-5">
          <RoundedMdButton
            label={"OK"}
            onClick={() => {
              setOpenModal(false);
            }}
          />
        </div>
      </ConfirmationModal>
    </div>
  );
}

export default NewLogisticsPartner;
