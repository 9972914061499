import React from "react";
import { GENERAL_GRAY_HEX } from "../constants/texts";
import CustomIconWithRoundedBg from "./CustomIconWithRoundedBg";

function CustomIconLabelValue({ icon, label, value, children }) {
  return (
    <div className="cursor-pointer mt-2 flex gap-4 items-center w-full text-ellipsis overflow-hidden ">
      <CustomIconWithRoundedBg>{icon}</CustomIconWithRoundedBg>
      <div className="font-[400] text-[14px]">
        <p className={`text-[${GENERAL_GRAY_HEX}]`}>{label}</p>
        <p className="text-[12px]">{value}</p>
      </div>
    </div>
  );
}

export default CustomIconLabelValue;
