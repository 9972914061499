import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { PAYOUTS_QUERY_KEY } from "../constants/queryKeys";
import { getPayouts } from "../services/payouts-services";

function usePayouts(data) {
  const navigate = useNavigate();
  const {
    data: payouts,
    isLoading,
    error,
  } = useQuery({
    queryKey: [PAYOUTS_QUERY_KEY, data],
    queryFn: async () => {
      const result = await getPayouts(data);
      return result?.data;
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    payouts,
    isLoading,
    error,
  };
}

export default usePayouts;
