import React from 'react'
import TicketTableHeaderList from './TicketTableHeaderList';
import TicketRowItem from './TicketRowItem';
import CustomTable from '../admin_commons/CustomTable';

function TicketsListing({ tickets, onRowClick, isLoading = false, totalRecords = 0 }) {
    return (
     <CustomTable onRowClick={onRowClick} totalRecords={totalRecords} isLoading={isLoading} HeaderComponent={TicketTableHeaderList} data={tickets} RowItemComponent={TicketRowItem} />
  )
}

export default TicketsListing