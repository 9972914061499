import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomNavTabs from "../../commons/CustomNavTabs";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import SearchInput from "./../../commons/SearchInput";
import OrdersListing from "./OrdersListing";
import useAllOrders from "./../../hooks/useAllOrders";
import useDatePicker from "../../hooks/useDatePicker";
import { GENERAL_BLUE_HEX } from "./../../constants/texts";
import useUser from "./../../hooks/useUser";
import { getSelectedTab } from "../../helpers/extractors";
import useDebounce from "../../hooks/useDebounce";
import CustomExportButton from "../../commons/CustomExportButton";
import useExportOrders from "../../hooks/useExportOrders";
import { handleDownload } from "../../helpers/downloads";

const tabItems = ["All", "Pending", "Picked", "Shipped", "Delivered"];

function Orders() {
  const navigate = useNavigate();

  const { user } = useUser();
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);

  const debouncedSearchTerm = useDebounce(searchTerm)




  const { DatePickerComponent, startDate, endDate, DateDisplay } =
    useDatePicker(null, "YYYY-MM-DD");
  const { orderItems, isLoading } = useAllOrders(
    user,
    10,
    1,
    getSelectedTab(selectedTab),
    [startDate, endDate],
    debouncedSearchTerm
  );

  const { orderDataExport } = useExportOrders()

  const handleRowClick = (id) => {
    navigate(`/orders/${id}`);
  };

  const handleOrdersExport = () => {
    orderDataExport({
      token: user.token,
      pageSize: 10,
      pageNumber: 1,
      filter: getSelectedTab(selectedTab),
      dateRange: [startDate, endDate],
      searchTerm: debouncedSearchTerm
    }).then(res => {
      handleDownload(res)
    })
  }

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        <PageTitle title={"Orders"} />
        <PageBreadCrumb
          mainText="Dashboard"
          subText="Order List"
          otherText=""
          isActiveIndex={1}
        />
        <div className={`flex justify-between mt-6 text-[${GENERAL_GRAY_HEX}]`}>
          <div>
            <CustomNavTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabItems}
            />
          </div>
          <div className={`flex gap-4`}>
            <SearchInput
              className="bg-white shadow-sm"
              onChange={setSearchTerm}
              value={searchTerm}
              placeholder={"Search orders..."}
            />
            {DatePickerComponent}
            <CustomExportButton onClick={handleOrdersExport} />
          </div>
        </div>

        {DateDisplay}

        <OrdersListing
          onRowClick={handleRowClick}
          totalRecords={orderItems?.totalRecords}
          isLoading={isLoading}
          orders={orderItems?.orders}
        />
      </div>
    </Layout>
  );
}

export default Orders;
