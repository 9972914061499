import React, { useState } from 'react'
import CustomNavTabs from '../../commons/CustomNavTabs';
import useUser from '../../hooks/useUser';
import Layout from './../../commons/Layout';
import AccountChangePassword from './AccountChangePassword';
import AccountProfileInformation from './AccountProfileInformation';

const tabItems = ["Profile Information", "Change Password"];

function AccountProfile() {
    const { user } = useUser()

    const [selectedTab, setSelectedTab] = useState(tabItems[0])

    return (
        <Layout bgClassName="bg-gray-100">
            <div className='w-[80%] mx-auto text-left p-6 mb-5'>
                <div className="w-auto flex justify-between mb-5">
                    <CustomNavTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={tabItems} />
                    <div>
                    </div>
                </div>
                {selectedTab == tabItems[0] && <AccountProfileInformation />}
                {selectedTab == tabItems[1] && <AccountChangePassword />}
            </div>

        </Layout>
    )
}

export default AccountProfile