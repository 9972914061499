import React from "react";
import { useNavigate } from "react-router-dom";
import OrderTableHeaderList from "../admin_commons/OrderTableHeaderList";
import OrderRowItem from "../admin_commons/OrderRowItem";
import CustomTable from "../admin_commons/CustomTable";

function OrdersListing({
  orders,
  user = null,
  onRowClick,
  isLoading,
  totalRecords,
  tableMinWidth = "",
  paginationData,
}) {
  const navigate = useNavigate();
  return (
    <CustomTable
      user={user}
      onRowClick={onRowClick}
      totalRecords={totalRecords}
      isLoading={isLoading}
      HeaderComponent={OrderTableHeaderList}
      data={orders}
      RowItemComponent={OrderRowItem}
      paginationData={paginationData}
    />
  );
}

export default OrdersListing;
