import { ErrorMessage, Field } from "formik";
import React from "react";

function CustomTextField({
  label,
  type = "text",
  value = "",
  disabled = false,
  name,
  placeholder = "",
  required = true,
  rows = 3,
  extraText,
  showProgress = false,
  min = 0,
  max = 5000,
  readOnly = false,
  showOptional = true,
}) {
  return (
    <div className="mb-5 text-left">
      <div className="mb-2 text-[12px] font-[500]">
        {label}
        {required && <span className="text-red-300 ml-1">*</span>}
        {extraText && (
          <span className="text-[#FF9D21] ml-2">({extraText})</span>
        )}
        {!required && showOptional && (
          <span className="text-[#FF9D21] ml-2">(Optional)</span>
        )}
      </div>
      <div>
        <Field
          as="textarea"
          readOnly={readOnly}
          rows={rows}
          name={name}
          disabled={disabled || readOnly}
          className="w-full text-[14px] font-[400] py-3 px-3 border rounded-md outline-none"
          type={type}
          placeholder={placeholder}
          style={{ resize: "none" }}
        />
        <div
          className={`flex ${
            showProgress ? "justify-between" : "justify-between"
          } items-center`}
        >
          <ErrorMessage
            className="text-xs text-red-400 w-full"
            name={name}
            component="div"
          />
          {showProgress && (
            <div className="flex w-full justify-end">
              <div></div>
              <div
                className={`font-[500] text-[14px] ${
                  value?.length <= max ? "" : "text-red-500"
                }`}
              >
                {value?.length}/{max}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomTextField;
