import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updatePayoutStatus } from "../services/payouts-services";
import { SINGLE_PAYOUT } from "../constants/queryKeys";

function useUpdatePayout() {
  const queryClient = useQueryClient();
  const {
    mutateAsync: updateStatusUpdate,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (data) => updatePayoutStatus(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries([SINGLE_PAYOUT]);
    },
    onError: (error) => {},
  });
  return {
    updateStatusUpdate,
    isLoading,
    error,
    data,
  };
}

export default useUpdatePayout;
