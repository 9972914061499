import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomNavTabs from "../../commons/CustomNavTabs";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import SearchInput from "../../commons/SearchInput";
import CustomButton from "../../commons/CustomButton";
import PlusIcon from "../../commons/PlusIcon";
import ProductsListing from "./ProductsListing";
import CustomBackdrop from "../../commons/CustomBackdrop";
import useFilterProducts from "./../../hooks/useFilterProducts";
import useDebounce from "./../../hooks/useDebounce";
import useDatePicker from "../../hooks/useDatePicker";

const tabItems = ["All", "Pending", "Approved", "Rejected", "Low Stock"];
const dateFormat = "YYYY-MM-DD";

function Products() {
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);

  const [selectedTab, setSelectedTab] = useState(tabItems[0]);
  const debouncedSearchTerm = useDebounce(searchTerm);

  const { isLoading, products } = useFilterProducts(
    pageSize,
    pageNumber,
    [],
    [],
    [],
    [],
    [],
    [],
    selectedTab,
    debouncedSearchTerm,
    selectedDate
  );
  const { DatePickerComponent, startDate, endDate, DateDisplay } =
    useDatePicker(() => {}, dateFormat);

  const handleRowClick = (id) => {
    navigate(`/products/${id}`);
  };

  const getSelectedProducts = (products, selectedTab) => {
    return products?.records;
  };

  useEffect(() => {
    setSelectedProducts(getSelectedProducts(products, selectedTab));
  }, [products]);

  useEffect(() => {
    setSelectedDate([startDate, endDate]);
  }, [startDate, endDate]);

  useEffect(() => {
    setPageNumber(1);
  }, [selectedTab]);

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        <PageTitle title={"Products"} />
        <PageBreadCrumb
          mainText="Dashboard"
          subText="Product List"
          otherText=""
          isActiveIndex={1}
        />
        <div className={`flex justify-between mt-6 text-[${GENERAL_GRAY_HEX}]`}>
          <div>
            <CustomNavTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabItems}
            />
          </div>
          <div className={`flex gap-4`}>
            <SearchInput
              className="bg-white shadow-sm py-3"
              onChange={setSearchTerm}
              value={searchTerm}
              placeholder={"Search products..."}
            />
            {DatePickerComponent}
            <CustomButton
              className="bg-orange-400 text-white"
              label="Add Product"
              onClick={() => navigate("/products/add")}
            >
              <PlusIcon />
            </CustomButton>
          </div>
        </div>

        {DateDisplay}

        {isLoading && <CustomBackdrop open={true} text="Please wait..." />}

        {!isLoading && products && (
          <ProductsListing
            paginationData={{ setPageNumber, pageNumber, pageSize }}
            valueKey="slug"
            totalRecords={products?.totalRecord ?? 0}
            onRowClick={handleRowClick}
            products={selectedProducts ?? []}
            isLoading={isLoading}
          />
        )}
      </div>
    </Layout>
  );
}

export default Products;
