import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PRODUCT_QUERY_KEY } from '../constants/queryKeys';
import { publishProduct } from '../services/products-services';

function usePublishProduct() {
    const queryClient = useQueryClient();
    const { mutateAsync: publishVendorProduct, isLoading, error, data } = useMutation({
        mutationFn: (productDetails) => publishProduct(productDetails),
        onSuccess: (data) => {
            queryClient.invalidateQueries([PRODUCT_QUERY_KEY])
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        publishVendorProduct, isLoading, error, data
    }
}

export default usePublishProduct