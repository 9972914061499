import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { registerInstaller } from "./../services/installers-service";
import { INSTALLERS_QUERY_KEY } from "../constants/queryKeys";

function useAddInstaller() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    mutateAsync: addInstaller,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (updateData) => registerInstaller(updateData),
    onSuccess: (data) => {
      queryClient.invalidateQueries([INSTALLERS_QUERY_KEY]);
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    addInstaller,
    isLoading,
    error,
    data,
  };
}

export default useAddInstaller;
