import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../../commons/CustomDatePicker";
import CustomNavTabs from "../../commons/CustomNavTabs";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import SearchInput from "../../commons/SearchInput";
import GeneralModal from "../../commons/GeneralModal";
import MakerContactDetails from "./MakerContactDetails";
import CustomButton from "../../commons/CustomButton";
import PlusIcon from "../../commons/PlusIcon";
import MakersListing from "./MakersListing";
import useUser from "../../hooks/useUser";
import useMakers from "../../hooks/useMakers";
import useDebounce from "../../hooks/useDebounce";

const tabItems = ["All", "Active", "Inactive"];

function Makers() {
  const navigate = useNavigate();
  const [selectedMaker, setSelectedMaker] = useState(null);
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);
  const [searchTerm, setSearchTerm] = useState("")

  const { user } = useUser();
  const debouncedSearchTerm = useDebounce(searchTerm)

  const { makers, isLoading } = useMakers({ accessToken: user?.token, searchTerm: debouncedSearchTerm, status: selectedTab?.toLowerCase() });
  const [isEditing, setIsEditing] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleRowClick = (id) => {
    const maker = makers?.find((maker) => maker.id == id)
    handleModalOpen(
      maker,
      true
    );
  };

  const handleModalOpen = (data, isEditing) => {
    setOpenModal(true);
    setSelectedMaker(data);
    setIsEditing(isEditing);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedMaker(null);
    setIsEditing(false);
  };

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        <PageTitle title={"Makers"} />
        <PageBreadCrumb
          mainText="Dashboard"
          subText="Maker List"
          otherText=""
          isActiveIndex={1}
        />
        <div className={`flex justify-between mt-6 text-[${GENERAL_GRAY_HEX}]`}>
          <div>
            <CustomNavTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabItems}
            />
          </div>
          <div className={`flex gap-4`}>
            <SearchInput
              className="bg-white shadow-sm py-3"
              onChange={setSearchTerm}
              value={searchTerm}
              placeholder={"Search makers..."}
            />

            <CustomButton
              onClick={() => {
                handleModalOpen(null, false);
              }}
              label="Maker"
              className="bg-orange-400 text-white"
            >
              <PlusIcon />
            </CustomButton>
          </div>
        </div>

        <MakersListing
          onRowClick={handleRowClick}
          makers={makers}
          isLoading={isLoading}
          totalRecords={makers?.totalRecords ?? 10}
        />
      </div>

      {/* MOdal */}
      <GeneralModal
        widthClass="w-[60%]"
        showCloseButton
        isOpen={openModal}
        onClose={handleCloseModal}
      >
        <div className="h-[80vh] text-left pr-3" style={{ overflowY: "auto" }}>
          <MakerContactDetails
            maker={selectedMaker}
            isEditing={isEditing}
            onClose={handleCloseModal}
            callback={handleCloseModal}
            user={user}
          />
        </div>
      </GeneralModal>
    </Layout>
  );
}

export default Makers;
