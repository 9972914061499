import React from "react";

function RoundedMdButton({
  label,
  onClick,
  children,
  disabled,
  type = "button",
  className = "hover:border-white hover:bg-gray-300 bg-gray-100 border-2",
  left = true,
}) {
  return (
    <button
      type={type}
      className={`flex items-center gap-4 justify-center ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } text-center mt-2 w-full rounded-md py-3 font-[500] text-[14px] ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children && left && <div>{children}</div>}
      <div>{label}</div>
      {children && !left && <div>{children}</div>}
    </button>
  );
}

export default RoundedMdButton;
