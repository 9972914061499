import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const exportOrders = async (data) => {
    let url = `/admin/export/orders`;

    return axiosInstance(`${url}?status=${data?.filter}&searchTerm=${data?.searchTerm}`, "GET", data, data.token, "blob")
        .then((response) => {
            return response;
        })
        .catch((err) => {
            getNetworkErrorMessage(err);
        });
};