import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../helpers/strings";

function CustomMultiSelectField({
  label,
  type = "text",
  placeholder = "",
  valueKey = "",
  keyValue = "",
  disabled = false,
  list = [],
  onChange = () => {},
  required = true,
  selectedValueList = [],
}) {
  const [displayList, setDisplayList] = useState(false);

  const getValue = (data) => {
    return typeof data == "object" ? data[valueKey] : data;
  };

  const displayListItem = (list) => {
    return list.map((l, index) => {
      return (
        <span className="px-2 py-1 mr-1 mb-1 bg-gray-200 opacity-100 text-[11px] rounded-md">
          <span className="font-[500] text-black">{index + 1}</span>
          {". "}
          {capitalizeFirstLetter(l)}
        </span>
      );
    });
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setDisplayList(false);
    });

    return () => {
      document.removeEventListener("click", setDisplayList);
    };
  }, []);

  return (
    <div className="mb-5 text-left">
      <div className="mb-2 text-[14px] font-[500]">
        {label}
        {!required && <span className="text-[#FF9D21] ml-2">(Optional)</span>}
        {required && <span className="text-red-300 ml-1">*</span>}
      </div>
      <div className="">
        <div
          className="w-full relative text-[14px] font-[400] py-3 pl-3 border rounded-md outline-none cursor-pointer"
          type={type}
          onClick={(e) => {
            e.stopPropagation();
            setDisplayList(true);
          }}
        >
          <span className="opacity-50 grid grid-cols-1">
            {selectedValueList.length > 0
              ? displayListItem(selectedValueList)
              : placeholder}
          </span>
        </div>
        {displayList && (
          <div
            className="border p-3 mt-2 rounded-md h-[21em]"
            style={{ overflowY: "auto" }}
            onClick={(e) => {
              e.stopPropagation();
              setDisplayList(true);
            }}
          >
            {list?.map((l) => {
              return (
                <div
                  className="p-2 flex items-center justify-between cursor-pointer hover:bg-gray-100"
                  value={getValue(l)}
                  onClick={() => onChange(getValue(l))}
                >
                  <span>{capitalizeFirstLetter(getValue(l))}</span>
                  <span>
                    <input
                      type="checkbox"
                      checked={selectedValueList.includes(getValue(l))}
                    />
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="text-xs text-red-400">{""}</div>
    </div>
  );
}

export default CustomMultiSelectField;
