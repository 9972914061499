import React from "react";
import { GoDotFill } from "react-icons/go";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

function StyledSpanMini({
  bgColor,
  textColor,
  text,
  editable = false,
  onClick,
}) {
  return (
    <span
      className={`${bgColor} ${
        editable ? "cursor-pointer" : ""
      } flex items-center justify-between gap-1 bg-[#EFF8FF] px-1 rounded-lg`}
      onClick={editable ? onClick : () => {}}
    >
      <div className="flex items-center gap-2">
        <GoDotFill className={textColor} />
        <span className={`${textColor} text-[0.75em]`}>{text}</span>
      </div>
      {editable && (
        <div>
          <MdOutlineKeyboardArrowDown className={textColor} />
        </div>
      )}
    </span>
  );
}

export default StyledSpanMini;
