import { addPaginationUrlQuery } from "../helpers/extractors";
import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const getPayouts = async (data) => {
  let newUrl = addPaginationUrlQuery(
    `/payouts`,
    data?.pageSize,
    data?.pageNumber
  );
  return axiosInstance(
    `${newUrl}&status=${data?.status}&startDate=${data?.startDate}&endDate=${data?.endDate}`,
    "GET",
    data,
    data.accessToken
  ).then((response) => {
    return response;
  });
};

export const getVendorPayouts = async (data, pageSize, pageNumber) => {
  let url = `/payouts/vendor/${data?.vendorId}`;

  url = addPaginationUrlQuery(url, pageSize, pageNumber);
  return axiosInstance(url, "GET", data, data.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const getPayout = async (data) => {
  return axiosInstance(`/payouts/${data.id}`, "GET", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const updatePayoutStatus = async (data) => {
  let url = `/payouts/${data.id}/status`;

  return axiosInstance(url, "PATCH", data, data.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};
