export const trafficSourceData = {
  data: [
    {
      label: "Organic search",
      value: "1578",
    },
    {
      label: "Direct",
      value: "1147",
    },
    {
      label: "Referrals",
      value: "725",
    },
    {
      label: "Socials",
      value: "524",
    },
    {
      label: "Paid",
      value: "360",
    },
  ],
  type: "Visitor",
};

export const dashboardDropdownFilters = [
  { label: "Top Selling Products", value: 1 },
  { label: "Top Selling Vendors", value: 2 },
];
