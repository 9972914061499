import { addPaginationUrlQuery } from "../helpers/extractors";
import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const fetchUserOrderItems = async (data, pageSize, pageNumber) => {
  let url = "/orders";

  url = addPaginationUrlQuery(url, pageSize, pageNumber);
  return axiosInstance(url, "GET", data, data.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const fetchCustomerOrderItems = async (
  id,
  pageSize,
  pageNumber,
  token
) => {
  let url = `/orders/customer/${id}`;

  url = addPaginationUrlQuery(url, pageSize, pageNumber);
  return axiosInstance(url, "GET", id, token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const updateOrder = async (data) => {
  let url = `/orders/${data.orderId}`;

  return axiosInstance(url, "PUT", data, data.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const updateOrderStatus = async (data) => {
  let url = `/orders/${data.id}/status`;

  return axiosInstance(url, "PATCH", data, data.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const fetchVendorOrderItems = async (data, pageSize, pageNumber) => {
  let url = "/orders/vendor";

  url = addPaginationUrlQuery(url, pageSize, pageNumber);
  return axiosInstance(url, "GET", data, data.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const fetchAllOrderItems = async (
  data,
  pageSize,
  pageNumber,
  filter,
  dateRange,
  searchTerm
) => {
  let url = "/orders/all";

  url = addPaginationUrlQuery(url, pageSize, pageNumber);
  return axiosInstance(
    url +
      `&searchTerm=${searchTerm}&status=${filter}&dateFrom=${dateRange[0]}&dateTo=${dateRange[1]}`,
    "GET",
    data,
    data.token
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const fetchUserPendingReviews = async (data, pageSize, pageNumber) => {
  let url = "/orders/pending-reviews";

  url = addPaginationUrlQuery(url, pageSize, pageNumber);
  return axiosInstance(url, "GET", data, data.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const fetchUserOrderItem = async (data) => {
  let url = "/orders/" + data?.id;

  return axiosInstance(url, "GET", data, data.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const addReview = async (data) => {
  return axiosInstance(`/reviews`, "POST", data, data.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const fetchOrderById = async (data, id) => {
  return axiosInstance(`/orders/${id}`, "GET", null, data?.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};
