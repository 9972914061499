import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { modifyPaymentSplit } from "../services/vendors-services";

function useUpdatePaymentSplit() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    mutateAsync: updatePaymentSplit,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (updateData) => modifyPaymentSplit(updateData),
    onSuccess: (data) => {},
    onError: (error) => {
      // return error
    },
  });
  return {
    updatePaymentSplit,
    isLoading,
    error,
    data,
  };
}

export default useUpdatePaymentSplit;
